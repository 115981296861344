import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputSearch } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { getPerfil } from "shared/permisos";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import Menu from "./components/Menu";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Users = (router) => {
    const { navigate, location } = router;
    const { setTitle, media_movil } = useMedia();
    const [perfil, setPerfil] = React.useState({ plural: "usuarios", singular: "usuario" });
    const [create_permission, setCreatePermission] = React.useState("no permitido");
    const [permission, setPermission] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [currentQuery, setCurrentQuery] = React.useState(7);
    const funciones = React.useRef(new Funciones(navigate));
    const [showLazy, setShowLazy] = React.useState(true);
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);



    const armaPerfil = (_rol) => {
        let _perfil = getPerfil(_rol);

        setPerfil(_perfil);
        return _perfil;
    }

    const Row = ({ data, interline }) => {

        return (
            <Menu
                inRow={true}
                data={data}
                interline={interline}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery)}>

                {media_movil ?
                    <td>
                        {data.nombres}
                        <pre> {data.correo}</pre>
                    </td>
                    :
                    <>
                        <td>{data.nombres}</td>
                        <td>{data.correo}</td>
                        <td>{data.telefono}</td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _footer;
        let _info;

        dataList.map((item, i) => {
            _info = JSON.parse(item.info);
            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={{
                    ..._info,
                    nombres: item.nombres,
                    id: item.id,
                    nivel: item.nivel
                }} />);
        });

        if (_data.length < 1) {
            _footer = <>
                {currentQuery === "search" ?
                    <h4 className="msg_lazy_load _alone _left">No hay resultados con "{criterio}"</h4>
                    :
                    <h4 className="msg_lazy_load _alone _left">No hay {perfil.plural}</h4>
                }
            </>;
        } else {
            _footer = <>
                {showLoading ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="msg_lazy_load">¡No hay más {perfil.plural}!</h4>
                        }
                    </>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th><Iconos.SwapVertical onClick={() => sortBy("nombres")} />Lista de {perfil.plural}</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th><Iconos.SwapVertical onClick={() => sortBy("nombres")} />Nombre</th>
                            <th>Correo</th>
                            <th>Teléfono</th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
        </>
    }

    const sortBy = (_key) => {
        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: currentQuery,
                criterio: criterio,
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setCriterio(_val);

        setShowLoading(true);
        setCurrentQuery(_val === "" ? perfil.plural : "search");
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: _val === "" ? perfil.plural : "search",
                criterio: _val,
                nivel: location.state.rol,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async (_perfil) => {
        setShowLoading(true);
        setCurrentQuery(_perfil);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                task: _perfil,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {

        if (location.state && location.state.rol) {
            let data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10, permisos: {} };
            let _rol = location.state.rol;
            let _perfil = armaPerfil(_rol, data_user.permisos);
            let _permission = "permitido";

            setTitle("Lista de " + _perfil.plural, location.state);
            setDataList([]);

            switch (parseInt(_rol)) {
                case 2:
                    // 2 Administrador                    
                    _permission = "admin_ver";
                    setCreatePermission("admin_crear");
                    break;
                case 3:
                    // 3 Contador o asistente contable
                    _permission = "contadores_ver";
                    setCreatePermission("contadores_crear");
                    break;
                case 4:
                    // 4 Almacenista
                    _permission = "almacenistas_crear";
                    break;
                case 5:
                    //  5 vendedor o asesores
                    _permission = "acesor_ver";
                    setCreatePermission("acesor_crear");
                    break;
                case 6:
                    //  6 transportadoras
                    _permission = "carrier_ver";
                    setCreatePermission("carrier_crear");
                    break;
                case 7:
                    //  7 domiciliarios
                    _permission = "domics_ver";
                    setCreatePermission("domics_crear");
                    break;
            }

            funciones.current.validaSesion(_permission).then(response => {
                if ((response && [1, 2].includes(parseInt(data_user.nivel)))) {
                    getList(_perfil.plural);
                    setPermission(true);
                } else {
                    setPermission(false);
                    setShowLoading(false);
                }
            });

        } else {
            setShowLoading(false);
            setTitle("Lista de usuarios", location.state);
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }
    }, [location.state]);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2>Lista de {perfil.plural}</h2>

                <div className="search_bar">
                    <InputSearch type="text" placeholder="Buscar por nombre"
                        value={criterio} onChange={handleSearch} />
                </div>
            </div>

            <AllRow />

            {funciones.current.validaPermisos(create_permission) &&
                <NavLink
                    to="/crear-usuario"
                    state={{ rol: location.state.rol }}
                    className="btn btn_add_to_list _right">
                    Crear nuevo {perfil.singular}<Iconos.UserAdd />
                </NavLink>}

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(Users);