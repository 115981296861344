import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { Funciones } from 'services/funciones';
import { useNavigate } from "react-router";
import { useMedia } from "hooks";
import * as Iconos from "images";

/* eslint react-hooks/exhaustive-deps: 0 */

const Home = () => {
    const funciones = React.useRef(new Funciones());
    const { setTitle } = useMedia();
    const navigate = useNavigate();


    React.useEffect(() => {
        setTitle("Sistema de gestión de recursos - Hit Colombia");
    }, []);

    return (
        <RequirePermissions>

            <div className="head_bar">
                <h2 className="head_bar-title _left">Hit Colombia</h2>
                <p>Sistema de gestión de recursos</p>
            </div>


            <div className="home_cont_btns">

                {funciones.current.validaPermisos("pedidos_ver") && <div className="btn_home" onClick={() => navigate("/pedidos")}>
                    <Iconos.Pedidos />
                    <h4>Pedidos</h4>
                </div>}

                {funciones.current.validaPermisos("pedidos_imprimir") && <div className="btn_home" onClick={() => navigate("/cola-impresion")}>
                    <Iconos.PedidosPorImprimir />
                    <h4>Cola de impresión</h4>
                </div>}

                {funciones.current.validaPermisos("guias_crear") && <div className="btn_home" onClick={() => navigate("/guias-envia")}>
                    <Iconos.CheckBook />
                    <h4>Guías Envía</h4>
                </div>}

                {funciones.current.validaPermisos("despachos_ver") && <div className="btn_home" onClick={() => navigate("/despachos")}>
                    <Iconos.TruckDelivery />
                    <h4>Despachos</h4>
                </div>}

                {funciones.current.validaPermisos("comprobantes_ver") && <div className="btn_home" onClick={() => navigate("/comprobantes")}>
                    <Iconos.Voucher />
                    <h4>Comprobantes</h4>
                </div>}

                {funciones.current.validaPermisos("concilios_ver") && <div className="btn_home" onClick={() => navigate("/conciliaciones", { state: { rol: 6 } })}>
                    <Iconos.TruckCheck />
                    <h4>Conciliaciones</h4>
                </div>}

                {funciones.current.validaPermisos("contable_caja_ver") && <div className="btn_home" onClick={() => navigate("/caja")}>
                    <Iconos.CajaContable />
                    <h4>Caja</h4>
                </div>}

            </div>

            <hr />

            <div className="home_cont_btns">

                {funciones.current.validaPermisos("pedidos_crear") && <div className="btn_home" onClick={() => navigate("/nuevo-pedido")}>
                    <Iconos.PedidoAdd />
                    <h4>Crear <br />pedido</h4>
                </div>}

                {funciones.current.validaPermisos("despachos_crear") && <div className="btn_home" onClick={() => navigate("/nuevo-despacho")}>
                    <Iconos.TruckAdd />
                    <h4>Nuevo <br />despacho</h4>
                </div>}

                {funciones.current.validaPermisos("despachos_entregar") && <div className="btn_home" onClick={() => navigate("/entregar-despachos", { state: {} })}>
                    <Iconos.TruckFast />
                    <h4>Entregar <br />despachos</h4>
                </div>}

                {funciones.current.validaPermisos("comprobantes_crear") && <div className="btn_home" onClick={() => navigate("/nuevo-comprobante")}>
                    <Iconos.VoucherNew />
                    <h4>Nuevo <br />comprobante</h4>
                </div>}

            </div>

        </RequirePermissions>
    )
}

export default Home;