import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { DatePickerShort } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import ReactExport from "react-export-excel";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import * as Iconos from "images";
import { useMedia } from "hooks";
import Menu from "./RowTr";

const rowData = ReactExport.rowData;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */


const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const _border = {
    top: { style: "thin", color: { rgb: "202020" } },
    bottom: { style: "thin", color: { rgb: "202020" } },
    left: { style: "thin", color: { rgb: "202020" } },
    right: { style: "thin", color: { rgb: "202020" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_center = { horizontal: "center", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "ed4c05" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffff" } },
    alignment: _align_center,
    border: _border
};

const _date = {
    font: { sz: "8", bold: false, color: { rgb: "202020" } },
    alignment: _align_center,
    border: _border
};

const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "135aff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffff" } },
    border: _border
};

const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "5df300" } },
    font: { sz: "10.5", color: { rgb: "202020" } },
    border: _border
};

const _txt = { font: { sz: "10.5", color: { rgb: "202020" } }, border: _border };

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ConciliationDetails = (router) => {
    const { setTitle, media_movil } = useMedia();
    const { navigate, location, params } = router;
    const [titulo, setTitulo] = React.useState("Detalle conciliación con " + params.name.replace(/_/g, ' '));
    const [valorServicio, setValorServicio] = React.useState(0);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [valorRecaudo, setValorRecaudo] = React.useState(0);
    const funciones = React.useRef(new Funciones(navigate));
    const [valorTotal, setValorTotal] = React.useState(0);
    const [valorOtros, setValorOtros] = React.useState(0);
    const [fecha, setFecha] = React.useState(new Date());
    const [dataList, setDataList] = React.useState([]);
    const [otros, setOtros] = React.useState([]);

    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="btn btn_add_to_list _right unable"><Iconos.ExcelDownload />Descargar excel</button>;

        if (showLoading) {
            _btn = <button className="btn btn_add_to_list _right"><Iconos.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="btn btn_add_to_list _right"><Iconos.ExcelDownload />Descargar Excel</button>;
        }

        return _btn;
    }

    const HojaDeCalculo = () => {
        let _tabla_otros = otros.map(v => {
            return [
                { merge: "start", value: v.nota ?? "", style: _txt },
                { merge: "end", value: "" },
                { value: funciones.current.formatPrice(v.monto).format + "", style: { ..._txt, alignment: _align_rigth } },
                { value: v.tipo ?? "", style: _txt },
            ];
        });


        let _tabla_resumen = [
            [
                { merge: "start", value: meses[fecha.getMonth()] + " " + format(fecha.getDate()) + " del " + fecha.getFullYear(), style: _date },
                { merge: "end", value: "", style: _txt },
            ],
            [
                { value: "Valor a recauda", style: _txt },
                { value: funciones.current.formatPrice(valorRecaudo).format + "", style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Servicios", style: _txt },
                { value: valorServicio > 0 ? "-" + funciones.current.formatPrice(valorServicio).format : "" + funciones.current.formatPrice(valorServicio).format, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Otros", style: _txt },
                { value: funciones.current.formatPrice(valorOtros).format + "", style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Total", style: _txt_green },
                { value: funciones.current.formatPrice(valorTotal).format + "", style: { ..._txt_green, alignment: _align_rigth } },
            ]
        ]

        let _length = Math.max(_tabla_otros.length, _tabla_resumen.length);
        let _rows = [];

        for (let i = 0; i < _length; i++) {
            _rows.push(rowData(_tabla_otros[i] ?? [null, null, null, null])
                .next(_tabla_resumen[i], 1).insert);
        }

        const pedidos = [
            {
                columns: [
                    { value: "Estado", style: _heads, width: 8 },
                    { value: "Guía", style: _heads, width: 10 },
                    { value: "Pedido", style: _heads, width: 10 },
                    { value: "Código", style: _heads, width: 9 },
                    { value: "Valor Shopify", style: _heads, width: 10 },
                    { value: "Valor Excel", style: _heads, width: 10 },
                    { value: "Envío", style: _heads, width: 10 },

                ],
                data: dataList.map(item => {
                    let _estado_name;
                    switch (item.estado) {
                        case '4':
                            _estado_name = "Conciliado pagado"; // Pagado
                            break;
                        case '3':
                            _estado_name = "Devuelto"; // Rechazado
                            break;
                        case '2':
                            _estado_name = "Pendiente"; // Pendiente
                            break;
                        case '1':
                            _estado_name = "Conciliado"; // Aceptado
                            break;
                        default:
                            _estado_name = "Sin conciliar";
                            break;
                    }

                    return [
                        { value: _estado_name, style: _txt },
                        { value: item.guia ?? "", style: _txt },
                        { value: item.orden ?? "", style: { ..._txt, alignment: _align_center } },
                        { value: item.codigo_excel ?? "", style: { ..._txt, alignment: _align_center } },
                        { value: item.valor_shopify ?? "", style: { ..._txt, alignment: _align_rigth } },
                        { value: item.valor_excel ?? "", style: { ..._txt, alignment: _align_rigth } },
                        { value: item.valor_envio ?? "", style: { ..._txt, alignment: _align_rigth } },
                    ];
                })
            },
            {
                ySteps: 2,
                columns: [
                    { merge: "start", value: "Otros ingresos y gastos", style: { ..._resum, alignment: _align_center } },
                    { merge: "pas", value: "" },
                    { merge: "pas", value: "" },
                    { merge: "end", value: "", style: _resum },
                    null,
                    { merge: "start", value: "Resumen", style: { ..._resum, alignment: _align_center } },
                    { merge: "end", value: "", style: _resum },
                ],
                data: _rows
            }
        ];

        return <>
            {(dataList.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={titulo} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={pedidos} name={"Conciliación #" + location?.state?.data.id ?? ""} />
                </ExcelFile>
            }
        </>;
    }

    const Row = ({ data, interline }) => {
        const valorEnvio = data.valor_envio || "";
        const valorShopify = data.valor_shopify || "";
        const valorExcel = data.valor_excel || "";


        return (
            <Menu data={data} interline={interline}>

                {media_movil ?
                    <td className="td_with_forms">
                        <pre><span>Código:</span> {data.codigo_excel} <i>{data.estado_name}</i></pre>
                        <pre><span>Guía:</span> {data.guia} <span>Pedido:</span> {data.orden ?? ""}</pre>
                        <pre><span className="width_fixed">Valor Shopify</span>{valorShopify}</pre>
                        <pre><span className="width_fixed">Valor Excel</span>{valorExcel}</pre>
                        <pre><span className="width_fixed">Envío</span>{valorEnvio}</pre>
                        <pre className="row_center">
                            {(data.estado == 1 || data.estado == 4) ?
                                <Iconos.CheckBold />
                                :
                                <Iconos.Close />
                            }
                        </pre>
                    </td>
                    :
                    <>
                        <td className="td_with_forms">{data.estado_name}</td>
                        <td className="td_with_forms">{data.guia}</td>
                        <td className="td_with_forms">{data.orden ?? ""}</td>
                        <td className="td_with_forms">{data.codigo_excel}</td>
                        <td className="td_with_forms">{valorShopify}</td>
                        <td className="td_with_forms">{valorExcel}</td>
                        <td className="td_with_forms">{valorEnvio}</td>
                        <td className="td_with_forms">
                            {(data.estado == 1 || data.estado == 4) ?
                                <Iconos.CheckBold />
                                :
                                <Iconos.Close />
                            }
                        </td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.forEach((item, i) => {
            let _estado_name;
            switch (item.estado) {
                case '4':
                    _estado_name = "Conciliado pagado"; // Pagado
                    break;
                case '3':
                    _estado_name = "Devuelto"; // Rechazado
                    break;
                case '2':
                    _estado_name = "Pendiente"; // Pendiente
                    break;
                case '1':
                    _estado_name = "Conciliado"; // Aceptado
                    break;
                default:
                    _estado_name = "Sin conciliar";
                    break;
            }
            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={{
                    ...item,
                    estado_name: _estado_name
                }} />);
        });

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>{titulo}</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Estado</th>
                            <th>Guía</th>
                            <th>Pedido</th>
                            <th>Código</th>
                            <th>Valor Shopify</th>
                            <th>Valor Excel</th>
                            <th>Envío</th>
                            <th></th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            <h4 className="msg_lazy_load"> </h4>
            <table className="table apendice">
                <tbody>
                    {otros.length > 0 ?
                        <tr className="table_title">
                            <th>Otros ingresos y gastos</th>
                            <th></th>
                            <th></th>
                        </tr>
                        :
                        <tr className="table_title radius_bottom">
                            <th>Otros ingresos y gastos</th>
                        </tr>
                    }

                    {otros.map((v, i) => {
                        const clases = (i % 2) == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line";
                        return (<tr key={i} className={clases}>
                            <td>{v.nota}</td>
                            <td>{funciones.current.formatPrice(v.monto).format}</td>
                            <td>{v.tipo}</td>
                            <td className="td_menu"></td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </>
    }

    React.useEffect(() => {
        const data = location?.state?.data ?? {};

        setTitulo("Detalle conciliación #" + data.id + " con " + params.name.replace(/_/g, ' '));
        setTitle("Detalle conciliación #" + data.id + " con " + params.name.replace(/_/g, ' '));

        funciones.current.validaSesion("concilios_ver").then(response => {
            if (response) {
                const date = new Date(data.fecha_creado);

                setFecha(date);
                setDataList(data.guias);
                setOtros(data.otros);
                setValorRecaudo(data.recaudo);
                setValorServicio(data.servicios);
                setValorTotal(data.total);
                setValorOtros(data.otros.reduce((acumulador, elemento) => {
                    if (elemento.tipo === "Ingreso") {
                        return acumulador + parseInt(elemento.monto);
                    } else {
                        return acumulador - parseInt(elemento.monto);
                    }
                }, 0));

                setPermission(true);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, [params.carrying, location.pathname]);

    return (<RequirePermissions permission={permission} loading={showLoading}>
        <div className="head_bar">
            <h2 className={media_movil ? "" : "_center"}>{titulo}</h2>
        </div>

        <AllRow />

        <div className="content_btns in_movil">
            {/* Se deja vacío sólo para conservar el estilo visual  */}
        </div>

        <div className="content_sticky_bottom">
            <DatePickerShort
                placeholder="Fecha"
                value={fecha}
                readOnly={true}
                onChange={(date) => setFecha(date)} />
            <h2><span>Valor a recaudar</span> <span>{funciones.current.formatPrice(valorRecaudo).format}</span></h2>
            <h2><span>Servicios</span> <span>{valorServicio > 0 ? "-" : ""}{funciones.current.formatPrice(valorServicio).format}</span></h2>
            <h2><span>Otros</span> <span>{funciones.current.formatPrice(valorOtros).format}</span></h2>
            <h2><span>Total</span> <span>{funciones.current.formatPrice(valorTotal).format}</span></h2>
            <br />
        </div>


        <div className="content_btns in_movil">
            <NavLink
                to={`/editar-conciliacion/${params.carrying}/${params.name}`}
                state={{ data: location?.state?.data ?? {} }}
                className="btn btn_add_to_list _left">
                Editar conciliación <Iconos.TruckEdit />
            </NavLink>

            <button className="btn btn_add_to_list" onClick={() => navigate("/conciliaciones", { state: { rol: 6 } })}>Cambiar transportadora <Iconos.Truck /></button>

            <HojaDeCalculo />
        </div>
        <Loading state={showLoading} />
    </RequirePermissions>)
}

export default withRouter(ConciliationDetails);