import * as React from "react";
import * as Iconos from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const mesesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Suggesting = React.forwardRef((params, ref) => {
    const { list, onSelect, currentIndex, onTop = { height: 28, top: false }, adding = false } = params;
    let _styles = onTop.top ? { bottom: onTop.height } : { top: onTop.height };
    const [options, setOptions] = React.useState([]);


    React.useEffect(() => {
        let _options = [];

        list.map((item, index) => {
            _options.push(
                <li key={index}
                    className={currentIndex == index ? "_selected" : ""}
                    onClick={() => onSelect({ ...item, index: index })}>
                    <a>{item.text}</a>
                </li>
            );
        });

        if (_options.length < 1) {
            _options.push(<li key={0}><a>{adding ? "Enten para agregar" : "No hay resultados."}</a></li>);
        }

        setOptions(_options);

    }, [list, currentIndex]);

    return (
        <ul id="float_list" ref={ref}
            className={onTop.top ? "_float_list _on_top" : "_float_list"}
            style={_styles}>
            {options}
        </ul>
    );
});

export const InputList = React.forwardRef((params, ref) => {
    const {
        onChange,
        name,
        title,
        type = "text",
        list = false,
        value,
        Icono = Iconos.FormTextbox,
        readOnly = false
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [isFirst, setIsFirst] = React.useState(true);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();

    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            _list.push({ text: item });
        });

        setLista(_list);
        setIsFirst(false);
    }

    const handlerCriteio = (_crit) => {
        onChange(_crit);

        if (list !== false) {
            let _list = [];

            if (!show) {
                setShow(true);
            }

            if (_crit.length > 0) {

                // if (_crit.length < value.length) {
                setSuggestionIndex(0);
                list.map(item => {
                    let _new_item = { text: item };
                    if (_new_item.text.toLowerCase().includes(_crit.toLowerCase())) {
                        _list.push(_new_item);
                    }
                });
                // } else {
                //     _list = lista.filter(item => {
                //         return item.text.toLowerCase().includes(_crit.toLowerCase());
                //     });
                // }

            } else {
                list.map(item => {
                    _list.push({ text: item });
                });
            }

            setLista(_list);
        }
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        if (list !== false && isFirst) {
            buildUserList();
        }
    }, [list]);

    return (
        <div className="form_group">
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="form_group-icon">
                    <Icono />
                </div>
                <div className="input with_icon_right" onBlur={handlerBlur}>
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        autoComplete="off"
                        placeholder={"Ingresa " + title.toLowerCase()}
                        className={readOnly ? "radius_all unable" : "radius_all"}
                        readOnly={readOnly}
                        onFocus={handlerFocus}
                        onKeyUp={handleKey}
                        onChange={({ target: { value } }) => handlerCriteio(value)} />
                    {value.length > 0 && <Iconos.CloseCircle onClick={() => handlerCriteio("")} />}

                    {(list !== false && show) && <Suggesting
                        list={lista}
                        ref={listRef}
                        onTop={onTop}
                        currentIndex={suggestionIndex}
                        onSelect={handleSelect} />}
                </div>
            </div>
        </div >
    );
});

export const InputListOnly = React.forwardRef((params, ref) => {
    const {
        onChange,
        name,
        placeholder,
        type = "text",
        list = false,
        value
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [show, setShow] = React.useState(false);
    const [isFirst, setIsFirst] = React.useState(true);
    const [lista, setLista] = React.useState([]);
    const listRef = React.useRef();

    const buildUserList = () => {
        let _list = [];

        list.map(item => {
            _list.push({ text: item });
        });

        setLista(_list);
        setIsFirst(false);
    }

    const handlerCriteio = (_crit) => {
        onChange(_crit);

        if (list !== false) {
            let _list = [];

            if (!show) {
                setShow(true);
            }

            if (_crit.length > 0) {

                // if (_crit.length < value.length) {
                setSuggestionIndex(0);
                list.map(item => {
                    let _new_item = { text: item };
                    if (_new_item.text.toLowerCase().includes(_crit.toLowerCase())) {
                        _list.push(_new_item);
                    }
                });
                // } else {
                //     _list = lista.filter(item => {
                //         return item.text.toLowerCase().includes(_crit.toLowerCase());
                //     });
                // }

            } else {
                list.map(item => {
                    _list.push({ text: item });
                });
            }

            setLista(_list);
        }
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                onChange(lista[suggestionIndex].text);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        if (list !== false && isFirst) {
            buildUserList();
        }
    }, [list]);

    return (
        <div className="form_group">
            <div className="form_group-field input_radius">
                <div className="input with_icon_right" onBlur={handlerBlur}>
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        autoComplete="off"
                        placeholder={placeholder}
                        className="radius_all"
                        onFocus={handlerFocus}
                        onKeyUp={handleKey}
                        onChange={({ target: { value } }) => handlerCriteio(value)} />
                    {value.length > 0 && <Iconos.CloseCircle onClick={() => handlerCriteio("")} />}

                    {(list !== false && show) && <Suggesting
                        list={lista}
                        ref={listRef}
                        onTop={onTop}
                        currentIndex={suggestionIndex}
                        onSelect={handleSelect} />}
                </div>
            </div>
        </div >
    );
});

export const InputMultiList = React.forwardRef((params, ref) => {
    const {
        name,
        title,
        type = "text",
        suggestionList = false,
        currentList = [],
        removeTag = () => { },
        onChange,
        readOnly = false
    } = params;
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [onTop, setOnTop] = React.useState({ height: 28, top: false });
    const [isFirst, setIsFirst] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const [lista, setLista] = React.useState([]);
    const [value, setValue] = React.useState("");
    const listRef = React.useRef();


    const buildUserList = () => {
        let _list = [];

        if (typeof suggestionList === "object") {
            suggestionList.map(item => {
                _list.push({ text: item });
            });
        }

        setLista(_list);
        setIsFirst(false);
    }

    const handlerCriteio = (_crit) => {
        setValue(_crit);

        if (suggestionList !== false) {
            let _list = [];

            if (!show) {
                setShow(true);
            }

            if (_crit.length > 0) {
                setSuggestionIndex(0);
                suggestionList.map(item => {
                    let _new_item = { text: item };
                    if (_new_item.text.toLowerCase().includes(_crit.toLowerCase())) {
                        _list.push(_new_item);
                    }
                });
            } else {
                suggestionList.map(item => {
                    _list.push({ text: item });
                });
            }

            setLista(_list);
        }
    }

    const handleSelect = (data) => {

        setSuggestionIndex(data.index);
        setShow(false);
        setValue("");
        onChange(data.text);
    }

    const handlerFocus = (e) => {
        let _height = window.innerHeight;
        let _height_input = e.target.getBoundingClientRect().height;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;

        setOnTop({
            height: _height_input,
            top: _to_border_bottom < 150
        });

        setSuggestionIndex(0);

        if (lista.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        setTimeout(() => {
            setShow(false);
        }, 200);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (listRef.current) {
            let _caja = listRef.current.offsetHeight;
            let _scroll = listRef.current.scrollHeight;
            let _length = lista.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setShow(false);
                if (lista[suggestionIndex]) {
                    onChange(lista[suggestionIndex].text);
                } else {
                    onChange(value);
                }
                setValue("");
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        listRef.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        listRef.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    React.useEffect(() => {
        if (suggestionList !== false && isFirst) {
            buildUserList();
        }
    }, [suggestionList]);

    return (
        <div className="form_group">
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="input" onBlur={handlerBlur}>
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        autoComplete="off"
                        placeholder={"Ingresa " + title.toLowerCase()}
                        className={readOnly ? "radius_all unable" : "radius_all"}
                        readOnly={readOnly}
                        onFocus={handlerFocus}
                        onKeyUp={handleKey}
                        onChange={({ target: { value } }) => handlerCriteio(value)} />
                    {value.length > 0 && <Iconos.CloseCircle onClick={() => handlerCriteio("")} />}

                    {(typeof suggestionList === "object" && show) && <Suggesting
                        list={lista}
                        ref={listRef}
                        onTop={onTop}
                        adding={true}
                        currentIndex={suggestionIndex}
                        onSelect={handleSelect} />}
                    {suggestionList === "loading" && <div className="loading"><Iconos.Loading /></div>}
                </div>
            </div>
            <div className="cont_tags">
                {currentList.map((tag, index) => {
                    return <span key={index} className="tag">{tag} <Iconos.CloseCircle onClick={() => removeTag(index)} /></span>
                })}
            </div>
        </div>
    );
});

export const InputOnly = React.forwardRef((params, ref) => {
    const {
        onChange,
        onKeyUp = () => { },
        name,
        placeholder,
        type = "text",
        value
    } = params;

    const handlerCriteio = (_crit) => {
        onChange(_crit);
    }
    return (
        <div className="form_group">
            <div className="form_group-field input_radius">
                <div className="input with_icon_right">
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        autoComplete="off"
                        placeholder={placeholder}
                        onKeyUp={onKeyUp}
                        className="radius_all"
                        onChange={({ target: { value } }) => handlerCriteio(value)} />
                    {value.length > 0 && <Iconos.CloseCircle onClick={() => handlerCriteio("")} />}
                </div>
            </div>
        </div>
    );
});

export const Input = React.forwardRef(({
    onChange,
    name,
    title,
    type = "text",
    value
}, ref) => {
    return (
        <div className="form_group">
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="input">
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        placeholder={"Ingresa " + title.toLowerCase()}
                        className="radius_all"
                        onChange={e => onChange(e.target.value)} />
                </div>
            </div>
        </div>
    )
});

export const InputSearch = (params) => {
    const {
        onChange,
        name,
        type = "text",
        value = "",
        placeholder = ""
    } = params;
    const [cliterio, setCriterio] = React.useState("");

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Enter") {
            onChange(e.target.value);
        }
    }

    const handleClear = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setCriterio("");
        onChange("");
    }

    React.useEffect(() => {
        setCriterio(value);
    }, [value]);

    return (
        <div className="input with_icon_right">
            <input
                name={name}
                type={type}
                value={cliterio}
                placeholder={placeholder}
                onKeyUp={handleKey}
                onChange={e => setCriterio(e.target.value)} />
            {cliterio.length > 0 ? <Iconos.CloseCircle onClick={handleClear} /> : <Iconos.Magnify />}
        </div>
    );
}

export const InputWithIcon = React.forwardRef(({
    onChange,
    name,
    title,
    type = "text",
    value,
    readOnly = false,
    Icono = Iconos.FormTextbox,
    children,
    className
}, ref) => {
    return (
        <div className={className ? "form_group " + className : "form_group"}>
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="form_group-icon">
                    <Icono />
                </div>
                <div className="input">
                    <input
                        ref={ref}
                        name={name}
                        type={type}
                        value={value}
                        readOnly={readOnly}
                        placeholder={"Ingresa " + title.toLowerCase()}
                        className="radius_right"
                        onChange={e => onChange(e.target.value)} />
                </div>
            </div>
            {children}
        </div>
    )
});

export const InputPassword = React.forwardRef(({
    onChange,
    autoComplete = "on",
    name,
    title,
    value,
    Icono = Iconos.KeyVariant,
    children
}, ref) => {
    const [isPasswordShown, setIsPasswordShown] = React.useState(false);

    return (
        <div className="form_group">
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="form_group-icon">
                    <Icono />
                </div>
                <button type="button" className="form_group-button" onClick={() => setIsPasswordShown(current => !current)}>
                    {isPasswordShown ? <Iconos.EyeOff /> : <Iconos.Eye />}
                </button>
                <div className="input with_icon_right">
                    <input
                        ref={ref}
                        name={name}
                        autoComplete={autoComplete}
                        type={isPasswordShown ? 'text' : 'password'}
                        value={value}
                        placeholder={"Ingresa " + title.toLowerCase()}
                        className="radius_right"
                        onChange={e => onChange(e.target.value)} />
                </div>
            </div>
            {children}
        </div>
    )
});

export const InputCheck = ({
    name,
    title,
    checked,
    readOnly = false,
    onClick,
    className = ""
}) => {
    return (
        <label className={"form_group-check " + className} >
            {readOnly ?
                <input type="checkbox"
                    name={name}
                    checked={checked}
                    readOnly />
                :
                <input type="checkbox"
                    name={name}
                    checked={checked}
                    readOnly
                    onClick={onClick} />}
            <span>{title}</span>
        </label>
    )
}

export const SelectWithIcon = React.forwardRef(({
    onChange,
    name,
    title,
    type = "text",
    value,
    readOnly = false,
    Icono = Iconos.ListBox,
    children
}, ref) => {
    return (
        <div className="form_group">
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="form_group-icon">
                    <Icono />
                </div>
                <div className="input">
                    {readOnly ?
                        <input
                            ref={ref}
                            name={name}
                            type={type}
                            value={value}
                            readOnly={readOnly}
                            placeholder={"Ingresa " + title.toLowerCase()}
                            className="radius_right" />
                        :
                        <select
                            ref={ref}
                            name={name}
                            type={type}
                            value={value}
                            readOnly={readOnly}
                            placeholder={"Ingresa " + title.toLowerCase()}
                            className="radius_right"
                            onChange={e => onChange(e.target.value)} >
                            {children}
                        </select>}
                </div>
            </div>
        </div>
    )
});

export const Message = ({
    title,
    content,
    onTimeOut
}) => {

    React.useEffect(() => {
        setTimeout(() => {
            onTimeOut();
        }, 30000);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="messages_card">
            <h4 className="messages_title">{title}</h4>
            <p className="messages_text">{content}</p>
        </div>
    )
}

export const DatePicker = React.forwardRef(({
    onChange,
    name,
    title,
    value,
    Icono = Iconos.Calendar,
    className
}, ref) => {
    const [fecha, setFecha] = React.useState(value || new Date());
    const [current, setCurrent] = React.useState(value || new Date());
    const [diasMes, setDiasMes] = React.useState([]);
    const [show, setShow] = React.useState(false);


    const Day = ({ data, br }) => {
        const dayRef = React.useRef();

        const onClick = () => {
            let _days = document.getElementsByClassName("selected");

            for (let i = 0; i < _days.length; i++) {
                _days[i].classList.remove("selected");
            }

            dayRef.current.classList.add("selected");

            setFecha(f => {
                let _new = new Date(f.getFullYear(), f.getMonth(), data.dia);
                setTimeout(() => {
                    onChange(_new);
                }, 200);
                return _new;
            });
            setShow(false);
        }

        return (<>
            {br ? <><span ref={dayRef} className={data.mes} onClick={onClick}>{data.dia}</span><br /> </>
                :
                <span ref={dayRef} className={data.mes} onClick={onClick}>{data.dia}</span>
            }
        </>);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Enter") {
            setFecha(f => {
                let _new = new Date(f.getFullYear(), f.getMonth(), f.getDate());
                setTimeout(() => {
                    onChange(_new);
                }, 200);
                return _new;
            });
            setShow(false);
        }
        if (e.key === "Escape") {
            setShow(false);
        }
        // if (e.key === "ArrowUp")
        // if (e.key === "ArrowDown")
    }

    React.useEffect(() => {
        let _current_day = value.getDate();
        let _first_day = new Date(fecha.getFullYear(), fecha.getMonth(), 1).getDay();
        let _last_day_prev = new Date(fecha.getFullYear(), fecha.getMonth(), 0).getDate() - _first_day;
        let _last_day = new Date(fecha.getFullYear(), (fecha.getMonth() + 1), 0).getDate();
        let _days = [];

        for (let i = 0; i < _first_day; i++) {
            _last_day_prev++;
            _days.push({ dia: _last_day_prev, mes: "previo" });
        }

        for (let i = 1; i <= _last_day; i++) {
            _days.push({ dia: i, mes: i == _current_day ? "current selected" : "current" });
        }

        setDiasMes(_days.map((d, i) => {

            if (((i + 1) % 7) === 0) {
                return <Day key={i} data={d} br />;
            } else {
                return <Day key={i} data={d} />;
            }
        }));

        setCurrent(value);

    }, [value, show]);

    return (
        <div className={className ? "form_group " + className : "form_group"}>
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="form_group-icon">
                    <Icono />
                </div>
                <div className="input">
                    <input
                        ref={ref}
                        name={name}
                        type="text"
                        readOnly
                        value={meses[current.getMonth()] + " " + format(current.getDate()) + " del " + current.getFullYear()}
                        placeholder={"Ingresa " + title.toLowerCase()}
                        className="radius_right"
                        onFocus={() => setShow(true)}
                        onKeyUp={handleKey} />

                    {show && <>
                        <div className="back_float" onClick={() => setShow(false)}></div>
                        <div className="calendar">
                            <div className="calendar_content">
                                <div className="calendar_year">
                                    <Iconos.Left className="arrow_left" onClick={() => setFecha(new Date((fecha.getFullYear() - 1), fecha.getMonth(), fecha.getDate()))} />
                                    <h3>{fecha.getFullYear()}</h3>
                                    <Iconos.Right className="arrow_right" onClick={() => setFecha(new Date((fecha.getFullYear() + 1), fecha.getMonth(), fecha.getDate()))} />
                                </div>
                                <div className="calendar_month">
                                    <Iconos.Left className="arrow_left" onClick={() => setFecha(new Date(fecha.getFullYear(), (fecha.getMonth() - 1), fecha.getDate()))} />
                                    <h4>{meses[fecha.getMonth()]}</h4>
                                    <Iconos.Right className="arrow_right" onClick={() => setFecha(new Date(fecha.getFullYear(), (fecha.getMonth() + 1), fecha.getDate()))} />
                                </div>
                                <div className="calendar_days">
                                    <p>
                                        <span className="names">DOM</span>
                                        <span className="names">LUN</span>
                                        <span className="names">MAR</span>
                                        <span className="names">MIÉ</span>
                                        <span className="names">JUE</span>
                                        <span className="names">VIE</span>
                                        <span className="names">SÁB</span>
                                    </p>

                                    <p>
                                        {diasMes}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
});

export const DatePickerOnly = React.forwardRef(({
    onChange,
    name,
    placeholder,
    value,
    Icono = Iconos.Calendar,
    className
}, ref) => {
    const [fecha, setFecha] = React.useState(value || new Date());
    const [current, setCurrent] = React.useState(value || new Date());
    const [diasMes, setDiasMes] = React.useState([]);
    const [show, setShow] = React.useState(false);


    const Day = ({ data, br }) => {
        const dayRef = React.useRef();

        const onClick = () => {
            let _days = document.getElementsByClassName("selected");

            for (let i = 0; i < _days.length; i++) {
                _days[i].classList.remove("selected");
            }

            dayRef.current.classList.add("selected");

            setFecha(f => {
                let _new = new Date(f.getFullYear(), f.getMonth(), data.dia);
                setTimeout(() => {
                    onChange(_new);
                }, 200);
                return _new;
            });
            setShow(false);
        }

        return (<>
            {br ? <><span ref={dayRef} className={data.mes} onClick={onClick}>{data.dia}</span><br /> </>
                :
                <span ref={dayRef} className={data.mes} onClick={onClick}>{data.dia}</span>
            }
        </>);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Enter") {
            setFecha(f => {
                let _new = new Date(f.getFullYear(), f.getMonth(), f.getDate());
                setTimeout(() => {
                    onChange(_new);
                }, 200);
                return _new;
            });
            setShow(false);
        }
        if (e.key === "Escape") {
            setShow(false);
        }
        // if (e.key === "ArrowUp")
        // if (e.key === "ArrowDown")
    }

    React.useEffect(() => {
        let _current_day = value.getDate();
        let _first_day = new Date(fecha.getFullYear(), fecha.getMonth(), 1).getDay();
        let _last_day_prev = new Date(fecha.getFullYear(), fecha.getMonth(), 0).getDate() - _first_day;
        let _last_day = new Date(fecha.getFullYear(), (fecha.getMonth() + 1), 0).getDate();
        let _days = [];

        for (let i = 0; i < _first_day; i++) {
            _last_day_prev++;
            _days.push({ dia: _last_day_prev, mes: "previo" });
        }

        for (let i = 1; i <= _last_day; i++) {
            _days.push({ dia: i, mes: i == _current_day ? "current selected" : "current" });
        }

        setDiasMes(_days.map((d, i) => {

            if (((i + 1) % 7) === 0) {
                return <Day key={i} data={d} br />;
            } else {
                return <Day key={i} data={d} />;
            }
        }));

        setCurrent(value);

    }, [value, show]);

    return (
        <div className={className ? "form_group " + className : "form_group"}>
            <div className="form_group-field input_radius">
                <div className="input with_icon_right">
                    <input
                        ref={ref}
                        name={name}
                        type="text"
                        readOnly
                        value={meses[current.getMonth()] + " " + format(current.getDate()) + " del " + current.getFullYear()}
                        placeholder={"Ingresa " + placeholder.toLowerCase()}
                        className="radius_all"
                        onFocus={() => setShow(true)}
                        onKeyUp={handleKey} />
                    <Icono className="no_event" />

                    {show && <>
                        <div className="back_float" onClick={() => setShow(false)}></div>
                        <div className="calendar">
                            <div className="calendar_content">
                                <div className="calendar_year">
                                    <Iconos.Left className="arrow_left" onClick={() => setFecha(new Date((fecha.getFullYear() - 1), fecha.getMonth(), fecha.getDate()))} />
                                    <h3>{fecha.getFullYear()}</h3>
                                    <Iconos.Right className="arrow_right" onClick={() => setFecha(new Date((fecha.getFullYear() + 1), fecha.getMonth(), fecha.getDate()))} />
                                </div>
                                <div className="calendar_month">
                                    <Iconos.Left className="arrow_left" onClick={() => setFecha(new Date(fecha.getFullYear(), (fecha.getMonth() - 1), fecha.getDate()))} />
                                    <h4>{meses[fecha.getMonth()]}</h4>
                                    <Iconos.Right className="arrow_right" onClick={() => setFecha(new Date(fecha.getFullYear(), (fecha.getMonth() + 1), fecha.getDate()))} />
                                </div>
                                <div className="calendar_days">
                                    <p>
                                        <span className="names">DOM</span>
                                        <span className="names">LUN</span>
                                        <span className="names">MAR</span>
                                        <span className="names">MIÉ</span>
                                        <span className="names">JUE</span>
                                        <span className="names">VIE</span>
                                        <span className="names">SÁB</span>
                                    </p>

                                    <p>
                                        {diasMes}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
});

export const DatePickerShort = React.forwardRef(({
    onChange,
    name,
    placeholder,
    value,
    Icono = Iconos.Calendar,
    className,
    readOnly = false
}, ref) => {
    const [fecha, setFecha] = React.useState(value || new Date());
    const [current, setCurrent] = React.useState(value || new Date());
    const [diasMes, setDiasMes] = React.useState([]);
    const [show, setShow] = React.useState(false);


    const Day = ({ data, br }) => {
        const dayRef = React.useRef();

        const onClick = () => {
            let _days = document.getElementsByClassName("selected");

            for (let i = 0; i < _days.length; i++) {
                _days[i].classList.remove("selected");
            }

            dayRef.current.classList.add("selected");

            setFecha(f => {
                let _new = new Date(f.getFullYear(), f.getMonth(), data.dia);
                setTimeout(() => {
                    onChange(_new);
                }, 200);
                return _new;
            });
            setShow(false);
        }

        return (<>
            {br ? <><span ref={dayRef} className={data.mes} onClick={onClick}>{data.dia}</span><br /> </>
                :
                <span ref={dayRef} className={data.mes} onClick={onClick}>{data.dia}</span>
            }
        </>);
    }

    const handleKey = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Enter") {
            setFecha(f => {
                let _new = new Date(f.getFullYear(), f.getMonth(), f.getDate());
                setTimeout(() => {
                    onChange(_new);
                }, 200);
                return _new;
            });
            setShow(false);
        }
        if (e.key === "Escape") {
            setShow(false);
        }
        // if (e.key === "ArrowUp")
        // if (e.key === "ArrowDown")
    }

    React.useEffect(() => {
        let _current_day = value.getDate();
        let _first_day = new Date(fecha.getFullYear(), fecha.getMonth(), 1).getDay();
        let _last_day_prev = new Date(fecha.getFullYear(), fecha.getMonth(), 0).getDate() - _first_day;
        let _last_day = new Date(fecha.getFullYear(), (fecha.getMonth() + 1), 0).getDate();
        let _days = [];

        for (let i = 0; i < _first_day; i++) {
            _last_day_prev++;
            _days.push({ dia: _last_day_prev, mes: "previo" });
        }

        for (let i = 1; i <= _last_day; i++) {
            _days.push({ dia: i, mes: i == _current_day ? "current selected" : "current" });
        }

        setDiasMes(_days.map((d, i) => {

            if (((i + 1) % 7) === 0) {
                return <Day key={i} data={d} br />;
            } else {
                return <Day key={i} data={d} />;
            }
        }));

        setCurrent(value);

    }, [value, show]);

    return (
        <div className={className ? "form_group " + className : "form_group"}>
            <div className={readOnly ? "form_group-field input_radius read_only" : "form_group-field input_radius"}>
                <div className="input with_icon_right">
                    <input
                        ref={ref}
                        name={name}
                        type="text"
                        readOnly
                        value={mesesShort[current.getMonth()] + " " + format(current.getDate()) + " de " + current.getFullYear()}
                        placeholder={"Ingresa " + placeholder.toLowerCase()}
                        className="radius_all"
                        onFocus={() => setShow(true)}
                        onKeyUp={handleKey} />
                    <Icono className="no_event" />

                    {show && <>
                        <div className="back_float" onClick={() => setShow(false)}></div>
                        <div className="calendar">
                            <div className="calendar_content">
                                <div className="calendar_year">
                                    <Iconos.Left className="arrow_left" onClick={() => setFecha(new Date((fecha.getFullYear() - 1), fecha.getMonth(), fecha.getDate()))} />
                                    <h3>{fecha.getFullYear()}</h3>
                                    <Iconos.Right className="arrow_right" onClick={() => setFecha(new Date((fecha.getFullYear() + 1), fecha.getMonth(), fecha.getDate()))} />
                                </div>
                                <div className="calendar_month">
                                    <Iconos.Left className="arrow_left" onClick={() => setFecha(new Date(fecha.getFullYear(), (fecha.getMonth() - 1), fecha.getDate()))} />
                                    <h4>{meses[fecha.getMonth()]}</h4>
                                    <Iconos.Right className="arrow_right" onClick={() => setFecha(new Date(fecha.getFullYear(), (fecha.getMonth() + 1), fecha.getDate()))} />
                                </div>
                                <div className="calendar_days">
                                    <p>
                                        <span className="names">DOM</span>
                                        <span className="names">LUN</span>
                                        <span className="names">MAR</span>
                                        <span className="names">MIÉ</span>
                                        <span className="names">JUE</span>
                                        <span className="names">VIE</span>
                                        <span className="names">SÁB</span>
                                    </p>

                                    <p>
                                        {diasMes}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
});

export const TextArea = React.forwardRef(({
    onChange,
    name,
    title,
    cols = "20",
    rows = "3",
    value,
    readOnly = false,
    className
}, ref) => {
    return (
        <div className={className ? "form_group " + className : "form_group"}>
            <span className="form_group-label">{title}</span>
            <div className="form_group-field input_radius">
                <div className="input">
                    <textarea
                        ref={ref}
                        cols={cols}
                        rows={rows}
                        name={name}
                        value={value}
                        readOnly={readOnly}
                        placeholder={"Ingresa " + title.toLowerCase()}
                        onChange={e => onChange(e.target.value)} >
                    </textarea>
                </div>
            </div>
        </div >
    )
});

export const DropZone = React.forwardRef(({
    onChange,
    name,
    title,
}, ref) => {
    const [clases, setClases] = React.useState("dropzone width_limit");
    const [currentFile, setCurrentFile] = React.useState(null);
    const fileInputRef = React.useRef();


    const handleClear = (e) => {
        e.preventDefault();

        setCurrentFile(null);
        onChange("");
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (!file) return;

        setClases("dropzone width_limit");
        setCurrentFile(file);
        onChange(file);
    }

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setClases("dropzone width_limit");
        setCurrentFile(file);
        onChange(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();

        setClases("dropzone width_limit drag_over");
    }

    const handleDragLeave = (e) => {
        e.preventDefault();

        setClases("dropzone width_limit");
    }

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <div className={clases}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={handleClick}>
            <div className="dropzone__input">
                {currentFile ?
                    <p className="dropzone__drop-here"><button className="dropzone__file-delete" onClick={handleClear}>Borrar</button> {currentFile.name}</p>
                    :
                    <>
                        <p className="dropzone__drop-here"><Iconos.Upload /> Arrastra y suelta un archivo aquí, <br />o haz clic para seleccionar uno.</p>
                        <input type="file"
                            ref={fileInputRef}
                            onChange={handleChange}
                            accept=".xls,.xlsx"
                            style={{ display: 'none' }} />
                    </>
                }

            </div>
        </div>
    )
});