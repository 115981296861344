import * as React from "react";
import { uploadFile } from "services/Uploader";
import { alerta, alertaRemove } from "./Alert";
import { DropZone, ReadVouchers } from "./DropZone";
import * as Iconos from "images";
import log from "services/log";
import resize from "./Resize";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

export const ReadFiles = React.forwardRef(({ attach}, filesRef) => {
    const [files, setFiles] = React.useState([]);

    React.useEffect(() => {
        if (attach) {
            setFiles(attach);
        }
    }, [attach]);

    return (
        <div>
            <div>
                <ReadVouchers files={files} />
            </div>

            <div className="dropzone__alert">
                <input type="hidden" ref={filesRef} />
            </div>
        </div>
    )
});

export const DropFile = React.forwardRef(({ voucher, onChange, attach, readOnly = false }, filesRef) => {
    const [files, setFiles] = React.useState([]);
    const [filesToUpload, setFilesToUpload] = React.useState([]);


    const handleDeleteFile = (file) => {
        if (file) {
            let newFiles = files.filter(f => f.nameinserver != file.nameinserver);
            onChange(newFiles);
        }
    }

    React.useEffect(() => {
        if (attach) {
            setFiles(attach);
        }
    }, [attach]);

    React.useEffect(() => {
        if (filesToUpload.length > 0) {
            setFiles(filesToUpload);
            onChange(filesToUpload);
            alertaRemove(filesRef.current);
        }
    }, [filesToUpload]);

    return (
        <div>
            <div>
                <DropZone
                    readOnly={readOnly}
                    voucher={voucher}
                    onChange={setFilesToUpload}
                    onDelete={handleDeleteFile}
                    files={files} />
            </div>

            <div className="dropzone__alert">
                <input type="hidden" ref={filesRef} />
            </div>
        </div>
    )
});

export const FileUpload = React.forwardRef(({ voucher, onChange, attach, readOnly = false }, filesRef) => {
    const [files, setFiles] = React.useState([]);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [currentProgress, setCurrentProgress] = React.useState({ value: 0, file: "" });
    const [loading, setLoading] = React.useState(false);


    const validaEnvio = () => {
        let allFiles = [];
        let _urls = "";
        alertaRemove(filesRef.current);

        log.d("validando con ", voucher)

        if (filesToUpload.length < 1) {
            alerta(filesRef.current, "No hay archivos para subir! ");
        } else {
            setLoading(true);

            filesToUpload.forEach(async file => {
                const data = new FormData();
                const imgName = file.nameinserver;

                setCurrentProgress({
                    file: imgName,
                    value: Math.round(1)
                });

                if (file.type.toString().split('/')[0] === "image") {

                    resize(file, async image => {
                        if (image !== null) {
                            data.append('image', image);
                            data.append('nombre', imgName.trim());
                            data.append('destino', "packs");
                        }

                        await uploadFile(data).then(response => {

                            allFiles.push(response);

                            log.d("archivo subido", file.name);

                            if (allFiles.length == filesToUpload.length) {

                                setFiles(allFiles);

                                allFiles.forEach(f => {
                                    log.d("nameinserver", f.nameinserver);
                                    _urls += f.nameinserver + "|" + f.url + ",";
                                });

                                setCurrentProgress({
                                    file: imgName,
                                    value: Math.round(100)
                                });

                                onChange(_urls.slice(0, -1));
                                setFilesToUpload([]);
                                setLoading(false);

                                if (typeof android !== 'undefined') {

                                    setTimeout(() => {
                                        // eslint-disable-next-line no-undef
                                        android.eliminaArchivo(file.name);
                                    }, 3000);
                                }
                            }

                        }).catch(error => {
                            alerta(filesRef.current, error);
                            setLoading(false);
                        });
                    });
                } else {
                    await uploadFile(data).then(response => {

                        allFiles.push(response);

                        log.d("archivo subido", file.name);

                        if (allFiles.length == filesToUpload.length) {

                            setFiles(allFiles);

                            allFiles.forEach(f => {
                                log.d("nameinserver", f.nameinserver);
                                _urls += f.nameinserver + "|" + f.url + ",";
                            });

                            setCurrentProgress({
                                file: imgName,
                                value: Math.round(100)
                            });

                            onChange(_urls.slice(0, -1));
                            setFilesToUpload([]);
                            setLoading(false);
                        }

                    }).catch(error => {
                        alerta(filesRef.current, error);
                        setLoading(false);
                    });
                }
            });
        }
    }

    const handleDeleteFile = (file) => {
        if (file) {
            let newFiles = files.filter(f => f.nameinserver != file.nameinserver);
            onChange(newFiles);
        }
    }

    React.useEffect(() => {
        if (attach) {
            setFiles(attach);
        }
    }, [attach]);

    React.useEffect(() => {
        if (filesToUpload.length > 0) {
            validaEnvio();
            setFiles(filesToUpload);
        }
    }, [filesToUpload]);

    return (
        <div>
            <div>
                {loading ?
                    <div className="dropzone">
                        <div className="dropzone__input dropzone__loading">
                            <div className="dropzone__loading_icon">
                                <Iconos.Loading className="on_refresh" />
                                <i className="progress">{currentProgress.value}%</i>
                            </div>
                            <i className="file">{currentProgress.file}</i>
                        </div>
                    </div>
                    :
                    <DropZone
                        readOnly={readOnly}
                        voucher={voucher}
                        onChange={setFilesToUpload}
                        onDelete={handleDeleteFile}
                        files={files} />
                }
            </div>

            <div className="dropzone__alert">
                <input type="hidden" ref={filesRef} />
            </div>
        </div>
    )
});


/* export const FileUploadImg = ({ img, voucher, loading, onStart, onUpload, onError }) => {

    const handleSelectFile = async (e) => {

        if (e.target.files.length > 0) {
            const data = new FormData();
            const file = e.target.files[0];
            let imgName = file.name;
            let _ext = imgName.split(".");
            _ext = _ext[_ext.length - 1];
            imgName = `EVIDENCE_${voucher}_${(new Date().getTime() / 1000).toFixed(0)}.${_ext}`;

            onStart();

            resize(file, async image => {

                if (image !== null) {
                    data.append('image', image);
                    data.append('nombre', imgName.trim());
                    data.append('destino', "packs");
                }

                await uploadFile(data).then(imagen => {
                    onUpload({
                        url: imagen.url,
                        name: imgName.trim(),
                        realname: imagen.name
                    });
                }).catch(error => {
                    onError("Error: " + error);
                });
            });
        }
    }

    return (
        <>
            {img && img.map((f, i) => <i key={i} className="name_img">{f.realname.length > 30 ? f.realname.substring(0, 15) + "..." + f.realname.substring(f.realname.length - 10) : f.realname}</i>)}

            <label htmlFor="file_img" className="label_icon">{loading ? <Iconos.Loading className="on_refresh" /> : <Iconos.ImagePlus />}</label>
            <input type="file" accept=".jpg,.jpeg,.png,.gif" name="file_img" id="file_img" onChange={handleSelectFile} />
        </>
    );
}

export const FileUploadClip = ({ loading, onStart, onUpload }) => {

    const handleSelectFile = async (e) => {
        if (e.target.files.length > 0) {
            const data = new FormData();
            const file = e.target.files[0];
            const imgName = file.name;

            onStart();

            resize(file, async image => {

                if (image !== null) {
                    data.append('image', image);
                    data.append('nombre', imgName.trim());
                    data.append('destino', "chat");
                }

                await uploadFile(data).then(imagen => {
                    // log.i("url", imagen.url);
                    onUpload({ url: imagen.url, name: imgName });
                }).catch(error => {
                    log.e("Error: ", error);
                });
            });
        }
    }

    return (
        <>
            <label htmlFor="file_clip">{loading ? <Iconos.Loading className="on_refresh" /> : <Iconos.PaperClip />}</label>
            <input type="file" accept=".jpg,.jpeg,.png,.gif,.pdf,.xls,.xlsx" name="file_clip" id="file_clip" onChange={handleSelectFile} />
        </>
    );
}

export const ViewImage = ({ data }) => {
    const [pathImg, setPathImg] = React.useState(data.imagen_url);

    const handleZoom = (e) => {
        e.preventDefault();

        let _new_file = new File([data.imagen_url], data.imagen, { type: "image/" + data.imagen.split('.').pop() });

        _new_file = Object.assign(_new_file, { preview: data.imagen_url });

        VisorImg([_new_file]);
    }

    React.useEffect(() => {
        if (data.imagen_url == null && data.imagen != null) {

            log.i("url", data.imagen_url);
            setPathImg(data.imagen_url);
        }
    }, [data]);

    return (
        <img
            src={pathImg}
            alt="imagen enviada"
            onClick={handleZoom} />
    );
} */