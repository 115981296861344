import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { DatePickerShort } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";
import Menu from "./RowTr";
import Entrada from "../../ConciliarGuias/components/Entrada";
import Salida from "../../ConciliarGuias/components/Salida";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ConciliationEdit = (router) => {
    const { setTitle, media_movil } = useMedia();
    const { navigate, location, params } = router;
    const [titulo, setTitulo] = React.useState("Editar conciliación con " + params.name.replace(/_/g, ' '));
    const [valorServicio, setValorServicio] = React.useState(0);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [valorRecaudo, setValorRecaudo] = React.useState(0);
    const funciones = React.useRef(new Funciones(navigate));
    const [modalView, setModalView] = React.useState(null);
    const [dataList, setDataList] = React.useState([]);
    const [valorTotal, setValorTotal] = React.useState(0);
    const [valorOtros, setValorOtros] = React.useState(0);
    const [fecha, setFecha] = React.useState(new Date());
    const [otros, setOtros] = React.useState([]);


    const Row = ({ data, interline }) => {
        const [valorEnvio, setValorEnvio] = React.useState(data.valor_envio ?? 0);
        const [valorShopify, setValorShopify] = React.useState(data.valor_shopify ?? 0);
        const [valorExcel, setValorExcel] = React.useState(data.valor_excel ?? 0);

        // log.d("data in row", data);

        const handleSave = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    return {
                        ...item,
                        estado: '1',
                        valor_shopify: valorShopify,
                        valor_excel: valorExcel,
                        valor_envio: valorEnvio,
                        isTemp: true
                    };
                }
                return item;
            });

            setDataList(_newData);
        }

        const handlePayed = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    return {
                        ...item,
                        estado: '4',
                        valor_shopify: valorShopify,
                        valor_excel: valorExcel,
                        valor_envio: valorEnvio,
                        isTemp: true
                    };
                }
                return item;
            });

            setDataList(_newData);
        }

        const handleCancel = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const _newData = dataList.map(item => {
                if (data.id === item.id) {
                    return {
                        ...item,
                        estado: '2',
                        isTemp: true
                    };
                }
                return item;
            });

            setDataList(_newData);
        }

        return (
            <Menu data={data} interline={interline}>

                {media_movil ?
                    <td className="td_with_forms">
                        <pre><span>Guía:</span> {data.guia} <span>Pedido:</span> {data.orden ?? ""} <i>{data.estado_name}</i></pre>
                        <pre><span>Código:</span> {data.codigo_excel}</pre>

                        <pre>
                            <span className="width_fixed">Valor Shopify</span>
                            {!data.isTemp ?
                                <input type="text" value={funciones.current.formatPrice(valorShopify).format} onChange={e => setValorShopify(e.target.value)} />
                                :
                                <>{valorShopify}</>
                            }
                        </pre>
                        <pre>
                            <span className="width_fixed">Valor Excel</span>
                            {!data.isTemp ?
                                <input type="text" value={funciones.current.formatPrice(valorExcel).format} onChange={e => setValorExcel(e.target.value)} />
                                :
                                <>{valorExcel}</>
                            }
                        </pre>
                        <pre>
                            <span className="width_fixed">Envío</span>
                            {!data.isTemp ?
                                <input type="text" value={funciones.current.formatPrice(valorEnvio).format} onChange={e => setValorEnvio(e.target.value)} />
                                :
                                <>{valorEnvio}</>
                            }
                        </pre>
                        <pre className="row_center">
                            {data.isTemp ?
                                <Iconos.CheckBold />
                                :
                                <>
                                    <button type="button" className="btn btn_primary" onClick={handleSave}>Guardar</button>
                                    <button type="button" className="btn btn_primary blue" onClick={handlePayed}>pagada</button>
                                    <button type="button" className="btn btn_primary" onClick={handleCancel}>Rechazar</button>
                                </>
                            }
                        </pre>
                    </td>
                    :
                    <>
                        <td className="td_with_forms">{data.estado_name}</td>
                        <td className="td_with_forms">{data.guia}</td>
                        <td className="td_with_forms">{data.orden ?? ""}</td>
                        <td className="td_with_forms">{data.codigo_excel}</td>
                        <td className="td_with_forms">
                            {!data.isTemp ?
                                <input type="text" value={funciones.current.formatPrice(valorShopify).format} onChange={e => setValorShopify(e.target.value)} />
                                :
                                <>{valorShopify}</>
                            }
                        </td>
                        <td className="td_with_forms">
                            {!data.isTemp ?
                                <input type="text" value={funciones.current.formatPrice(valorExcel).format} onChange={e => setValorExcel(e.target.value)} />
                                :
                                <>{valorExcel}</>
                            }
                        </td>
                        <td className="td_with_forms">
                            {!data.isTemp ?
                                <input type="text" value={funciones.current.formatPrice(valorEnvio).format} onChange={e => setValorEnvio(e.target.value)} />
                                :
                                <>{valorEnvio}</>
                            }
                        </td>
                        <td className="td_with_forms">
                            {data.isTemp ?
                                <Iconos.CheckBold />
                                :
                                <>
                                    <button type="button" className="btn btn_primary yellow" onClick={handleSave}>Guardar</button>
                                    <button type="button" className="btn btn_primary blue" onClick={handlePayed}>pagada</button>
                                    <button type="button" className="btn btn_primary orange" onClick={handleCancel}>Rechazar</button>
                                </>
                            }
                        </td>
                    </>
                }
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _footer;

        dataList.forEach((item, i) => {
            let _estado_name;
            switch (item.estado) {
                case '4':
                    _estado_name = "Conciliado pagado"; // Pagado
                    break;
                case '3':
                    _estado_name = "Devuelto"; // Rechazado
                    break;
                case '2':
                    _estado_name = "Pendiente"; // Pendiente
                    break;
                case '1':
                    _estado_name = "Conciliado"; // Aceptado
                    break;
                default:
                    _estado_name = "Sin conciliar";
                    break;
            }
            _data.push(<Row
                key={item.id}
                interline={i % 2}
                data={{
                    ...item,
                    estado_name: _estado_name
                }} />);
        });

        if (_data.length < 1) {
            _footer = <h4 className="msg_lazy_load _alone _left">¡No hay ninguna guía!</h4>;
        } else {
            _footer = <>
                {
                    showLoading ?
                        <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                        :
                        <h4 className="msg_lazy_load">¡No hay más guías!</h4>
                }
            </>;
        }

        return <>
            <table className="table">
                <tbody>
                    {media_movil ?
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>{titulo}</th>
                        </tr>
                        :
                        <tr className={_data.length > 0 ? "table_title" : "table_title radius_bottom"}>
                            <th>Estado</th>
                            <th>Guía</th>
                            <th>Pedido</th>
                            <th>Código</th>
                            <th>Valor Shopify</th>
                            <th>Valor Excel</th>
                            <th>Envío</th>
                            <th></th>
                        </tr>
                    }
                    {_data}
                </tbody>
            </table>
            {_footer}
            <table className="table apendice">
                <tbody>
                    {otros.length > 0 ?
                        <tr className="table_title">
                            <th>Otros ingresos y gastos</th>
                            <th></th>
                            <th></th>
                        </tr>
                        :
                        <tr className="table_title radius_bottom">
                            <th>Otros ingresos y gastos</th>
                        </tr>
                    }

                    {otros.map((v, i) => {
                        const clases = (i % 2) == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line";
                        return (<tr key={i} className={clases}>
                            <td>{v.nota}</td>
                            <td>{funciones.current.formatPrice(v.monto).format}</td>
                            <td>{v.tipo}</td>
                            <td className="td_menu"></td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </>
    }

    const saveConciliation = async (e) => {
        e.preventDefault();
        let _guias = [];
        let _date = new Date();
        let _todas = dataList.length;

        dataList.forEach((guia) => {
            let _estado = parseInt(guia.carrier?.conciliacion?.estado ?? "2");

            if (guia.isTemp) {
                _guias.push(guia);
            } else {
                if ([1, 3].includes(_estado)) {
                    // log.d(guia.guia, _estado);
                    _todas--;
                }
            }
        });

        if (_guias.length < 1) {
            Alert("Es necesario que valides \n(Guardar o Rechazar) \npara poder continuar", "warning");
            return;
        }

        if (_guias.length !== _todas) {
            Alert("Es necesario validar cada una de las " + _todas + " guías para poder continuar", "warning");
            return;
        }

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-conciliation",
            data: {
                task: "conciliation_update",
                info: JSON.stringify({
                    id: location?.state?.data?.id ?? 0,
                    fecha: fecha.getFullYear() + "-" + format((fecha.getMonth() + 1)) + "-" + format(fecha.getDate()) + " " + format(_date.getHours()) + ":" + format(_date.getMinutes()) + ":00",
                    carrying: params.carrying,
                    recaudo: valorRecaudo,
                    servicios: valorServicio,
                    otros: otros,
                    total: valorTotal,
                    guias: _guias
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                navigate(`/conciliaciones/${params.carrying}/${params.name}`);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleRefresh = (v) => {
        setModalView(null); // Cierra el modal tras la respuesta

        setOtros([...otros, v]);
    }

    const abrirEntrada = (e) => {
        e.preventDefault();

        setModalView(<Entrada
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleRefresh}
            close={() => setModalView(null)} />);
    }

    const abrirSalida = (e) => {
        e.preventDefault();

        setModalView(<Salida
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            onChange={handleRefresh}
            close={() => setModalView(null)} />);
    }

    React.useEffect(() => {
        let _total = (valorRecaudo - valorServicio);
        let _otros = otros.reduce((acumulador, elemento) => {
            if (elemento.tipo === "Ingreso") {
                return acumulador + parseInt(elemento.monto);
            } else {
                return acumulador - parseInt(elemento.monto);
            }
        }, 0);
        setValorOtros(_otros);
        setValorTotal(_total + _otros);
    }, [otros, valorRecaudo, valorServicio]);

    React.useEffect(() => {
        let _valor_recaudo = 0;
        let _valor_servicios = 0;

        dataList.forEach(i => {
            if (i.isTemp && i.estado == 1) {
                _valor_recaudo += parseInt(i.valor_shopify || "0");
                _valor_servicios += parseInt(i.valor_envio || "0");
            }
            if (i.isTemp && i.estado == 4) {
                _valor_servicios += parseInt(i.valor_envio || "0");
            }
        });

        setValorRecaudo(_valor_recaudo);
        setValorServicio(_valor_servicios);
    }, [dataList]);

    React.useEffect(() => {
        const data = location?.state?.data ?? {};

        setTitulo("Editar conciliación #" + data.id + " con " + params.name.replace(/_/g, ' '));
        setTitle("Editar conciliación #" + data.id + " con " + params.name.replace(/_/g, ' '));

        funciones.current.validaSesion("concilios_edita").then(response => {
            if (response) {
                const date = new Date(data.fecha_creado);

                setFecha(date);
                setDataList(data.guias);
                setOtros(data.otros);
                setValorRecaudo(data.recaudo);
                setValorServicio(data.servicios);
                setValorTotal(data.total);
                setValorOtros(data.otros.reduce((acumulador, elemento) => {
                    if (elemento.tipo === "Ingreso") {
                        return acumulador + parseInt(elemento.monto);
                    } else {
                        return acumulador - parseInt(elemento.monto);
                    }
                }, 0));

                setPermission(true);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, [params.carrying, location.pathname]);

    return (<RequirePermissions permission={permission} loading={showLoading}>
        <div className="head_bar">
            <h2 className={media_movil ? "" : "_center"}>{titulo}</h2>
        </div>

        <AllRow />

        <div className="content_btns in_movil">
            <h3 className="btn btn_add_to_list _left" onClick={abrirEntrada}>Agregar Ingreso <Iconos.CashPlus /> </h3>
            <h3 className="btn btn_add_to_list _right" onClick={abrirSalida}>Agregar Gasto <Iconos.CashMinus /> </h3>
        </div>

        <div className="content_sticky_bottom">
            <DatePickerShort
                placeholder="Fecha"
                value={fecha}
                onChange={(date) => setFecha(date)} />
            <h2><span>Valor a recaudar</span> <span>{funciones.current.formatPrice(valorRecaudo).format}</span></h2>
            <h2><span>Servicios</span> <span>{valorServicio > 0 ? "-" : ""}{funciones.current.formatPrice(valorServicio).format}</span></h2>
            <h2><span>Otros</span> <span>{funciones.current.formatPrice(valorOtros).format}</span></h2>
            <h2><span>Total</span> <span>{funciones.current.formatPrice(valorTotal).format}</span></h2>

            <button className="btn btn_primary" onClick={saveConciliation}>Guardar conciliación</button>
        </div>


        <div className="content_btns in_movil">
            <NavLink
                to={`/conciliar-guias/${params.carrying}/${params.name}`}
                className="btn btn_add_to_list _left">
                Ir a conciliar guías <Iconos.TruckExcelUpload />
            </NavLink>

            <NavLink
                to={`/conciliaciones/${params.carrying}/${params.name}`}
                className="btn btn_add_to_list _right">
                Ir a conciliaciones <Iconos.TruckCheck />
            </NavLink>
        </div>
        <Loading state={showLoading} />
        {modalView}
    </RequirePermissions>)
}

export default withRouter(ConciliationEdit);