import * as React from "react";
import log from "services/log";

const ContextMedia = React.createContext();

export function MediaProvider({ children }) {
    const [lastPage, setLastPage] = React.useState({ url: window.location.href.toString().split(process.env.REACT_APP_SERVER)[1], title: document.title, state: {} });
    const [media_movil, setMediaMovil] = React.useState(window.innerWidth > 520 ? false : true);
    /** @type {CurrentQuery} */
    const [currentQuery, setCurrentQuery] = React.useState({
        orders: { task: "todos", criterio: "" },
        to_print: { task: "todos", criterio: "" }
    });

    /**
     * Configura el titulo de la paguína actual y
     * guarda el estado para cuando caduque la sesión 
     * redirija a esta ultima pagina
     * @param {String} title titulo de la pagina 
     * @param {JSON} state la información de la pagina actual `rol` o `data`
     */
    const setTitle = (title, state = {}) => {
        document.body.style.overflow = "auto";
        document.title = title;
        log.title(title);

        setLastPage({
            url: window.location.href.toString().split(process.env.REACT_APP_SERVER)[1],
            title: title,
            state: state
        });
    }

    React.useEffect(() => {
        let mediaQuery = window.matchMedia('(max-width: 520px)');

        let handleChange = (e) => {
            setMediaMovil(e.matches);
        }

        mediaQuery.addListener(handleChange);

        return () => mediaQuery.removeListener(handleChange);
    }, []);

    return <ContextMedia.Provider value={{ lastPage, setTitle, media_movil, currentQuery, setCurrentQuery }}>
        {children}
    </ContextMedia.Provider>
}

export { ContextMedia };