import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import VisorImg from 'shared/VisorImg';
import * as Iconos from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

export const ReadVouchers = ({ files }) => {

    const handleZoom = (e, imagen) => {
        e.preventDefault();
        if (imagen.type.toString().split('/')[0] === "image") {
            VisorImg([imagen]);
        }
    }

    const Aside = () => {
        let _content = [];
        let _aside_files = [];

        if (files && files.length > 0) {
            files.forEach(file => {
                _aside_files.push(file);
            });
        }

        _aside_files.map((item, index) => {
            _content.push(
                <h3 className="dropzone__voucher" key={index} onClick={e => handleZoom(e, item)}>
                    <span><b>Comprobante:</b> {item.voucher}</span>
                    <span><b>Cuenta:</b> {item.bank} - {item.bank_name}</span>
                </h3>);
        });

        return (
            <aside className="dropzone__img _voucher">
                {_content}
            </aside>
        );
    }

    return (
        <>
            <div className="dropzone">
                {files.length > 0 && <Aside />}
            </div>
        </>);
}

export const DropZone = ({ onChange, onDelete, files, readOnly }) => {
    const onDrop = React.useCallback(loaded => {
        let _file = loaded.map((file, i) => {
            let _name = file.name;
            let _sign = (new Date().getTime() / 1000).toFixed(0);
            let _ext = _name.split(".");
            _ext = _ext[_ext.length - 1];
            if (i > 0) {
                _name = `voucher_${i < 10 ? '0' + i : i}_${_sign}.${_ext}`;
            } else {
                _name = `voucher_${_sign}.${_ext}`;
            }

            return Object.assign(file, {
                preview: URL.createObjectURL(file),
                nameinserver: _name,
                sign: _sign,
            });
        });

        onChange(_file);
    }, []);

    const removeFile = (_file, e) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete(_file);
    }

    const handleZoom = (e, imagen) => {
        e.preventDefault();
        if (imagen.type.toString().split('/')[0] === "image") {
            VisorImg([imagen]);
        }
    }

    const Aside = () => {
        let _content = [];
        let _aside_files = [];

        if (files && files.length > 0) {
            files.forEach(file => {
                _aside_files.push(file);
            });
        }

        if (_aside_files.length == 1 && _aside_files[0].type.split("/")[0] === "image") {
            _content.push(<img key="1" src={_aside_files[0].preview} alt={_aside_files[0].name} />);
            _content.push(<p key="2" className="dropzone__img-name" onClick={e => handleZoom(e, _aside_files[0])}>{_aside_files[0].name}</p>);
            _content.push(<button key="3" className="dropzone__img-delete" type="button" onClick={e => removeFile(_aside_files[0], e)}>Borrar</button>);
        } else {
            _aside_files.map((item, index) => {
                _content.push(
                    <h3 className="dropzone__h3" key={index} onClick={e => handleZoom(e, item)}>
                        <span>
                            <button className="dropzone__file-delete" type="button" onClick={e => removeFile(item, e)}>Borrar</button>
                            {item.name}
                        </span>
                    </h3>);
            });
        }

        return (
            <aside className="dropzone__img">
                {_content}
            </aside>
        );
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <>
            <div className="dropzone">
                <div {...getRootProps()} className="dropzone__input">
                    {readOnly ?
                        <></>
                        :
                        <>
                            {files.length == 0 && (
                                <div className="dropzone__drop-here">
                                    <Iconos.Upload />
                                    <p>Clic aquí o arrastra tus archivos para agregarlos</p>
                                </div>
                            )}

                            <input {...getInputProps()} />
                        </>
                    }
                </div>
                {files.length > 0 && <Aside />}
            </div>
        </>);
}
