import * as React from "react";
import { DatePicker, InputList, InputWithIcon, TextArea } from "shared/Components";
import { Alert, alertArea, alerta, alertaRemove } from "shared/Alert";
import { useNavigate } from "react-router-dom";
import { Funciones } from "services/funciones";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const CambioCaja = ({ parent, reload, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(true);
    const [bancoDestino, setBancoDestino] = React.useState("");
    const [bancoOrigen, setBancoOrigen] = React.useState("");
    const funciones = React.useRef(new Funciones(navigate));
    const [fecha, setFecha] = React.useState(new Date());
    const [bankInfo, setBankInfo] = React.useState({});
    const [cuentas, setCuentas] = React.useState([]);
    const [monto, setMonto] = React.useState("");
    const [nota, setNota] = React.useState("");

    const bankDestinoRef = React.useRef(null);
    const bankOrigenRef = React.useRef(null);
    const montoRef = React.useRef(null);
    const fechaRef = React.useRef(null);
    const notaRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const handleBankOrigin = (_value) => {
        alertaRemove(bankOrigenRef.current);
        setBancoOrigen(_value);

        if (_value === "") {
            setBancoOrigen("");
            setBankInfo(b => ({ ...b, origin: {} }));
        } else {
            let item = cuentas.find(count => {
                let _cuentas = format(count.id) + " - " + count.nombre;
                _cuentas = _cuentas.trim();

                if (_cuentas === _value) {
                    return true;
                } else {
                    return false;
                }
            });

            if (typeof item === "object") {
                setBankInfo(b => ({ ...b, origin: { bank: format(item.id), bank_name: item.nombre } }));
            } else {
                // setBancoOrigen("");
                setBankInfo(b => ({ ...b, origin: {} }));
                alerta(bankOrigenRef.current, "¡La cuenta de origen es invalida!");
            }
        }
    }

    const handleBankDestination = (_value) => {
        alertaRemove(bankDestinoRef.current);
        setBancoDestino(_value);

        if (_value === "") {
            setBancoDestino("");
            setBankInfo(b => ({ ...b, destination: {} }));
        } else {
            let item = cuentas.find(count => {
                let _cuentas = format(count.id) + " - " + count.nombre;
                _cuentas = _cuentas.trim();

                if (_cuentas === _value) {
                    return true;
                } else {
                    return false;
                }
            });

            if (typeof item === "object") {
                setBankInfo(b => ({ ...b, destination: { bank: format(item.id), bank_name: item.nombre } }));
            } else {
                // setBancoDestino("");
                setBankInfo(b => ({ ...b, destination: {} }));
                alerta(bankDestinoRef.current, "¡La cuenta de destino es invalida!");
            }
        }
    }

    const saveInTill = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "set-cash-movement",
            data: {
                task: "cambio_caja",
                info: JSON.stringify({
                    tipo_name: "Cambio de caja",
                    banks: bankInfo,
                    monto: funciones.current.formatPrice(monto).int,
                    nota,
                    fecha: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}`
                })
            }
        });

        if (_data.response == 1) {
            // Alert(_data.msg, "ok", () => {
            document.body.style.overflow = "auto";
            reload();
            close(false);
            // });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(bankDestinoRef.current);
        alertaRemove(bankOrigenRef.current);
        alertaRemove(montoRef.current);
        alertaRemove(notaRef.current);
        let _monto = funciones.current.formatPrice(monto).int;

        if (_monto.length < 1) {
            alerta(montoRef.current, "¡Debes ingresar el monto!");
            montoRef.current.focus();
            return false;
        }

        if (isNaN(_monto.split(',')[0])) {
            alerta(montoRef.current, "¡Solo se admiten numeros!");
            montoRef.current.focus();
            return false;
        }

        if (_monto.split(',')[1] && isNaN(_monto.split(',')[1])) {
            alerta(montoRef.current, "¡Solo se admiten numeros!");
            montoRef.current.focus();
            return false;
        }

        if (parseFloat(_monto.toString().replace(',', '.')) < 0) {
            alerta(montoRef.current, "¡Debes ingresar un monto valido!");
            montoRef.current.focus();
            return false;
        }

        if (bancoOrigen.length < 1) {
            alerta(bankOrigenRef.current, "¡Debes ingresar la cuenta de origen!");
            // bankOrigenRef.current.focus();
            return false;
        }

        if (bancoDestino.length < 1) {
            alerta(bankDestinoRef.current, "¡Debes ingresar la cuenta de destino!");
            // bankDestinoRef.current.focus();
            return false;
        }

        if (bankInfo.destination?.bank === bankInfo.origin?.bank) {
            alerta(bankDestinoRef.current, "¡La cuenta destino es igual al origen!");
            // bankDestinoRef.current.focus();
            return false;
        }

        if (nota.length < 1) {
            alertArea(notaRef.current, "¡Debes ingresar un concepto!");
            notaRef.current.focus();
            return false;
        }

        saveInTill();
    }

    const getBanksList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-banks",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setCuentas(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getBanksList();

        if (montoRef.current) {
            montoRef.current.focus();
        }
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Cambio de caja">

            <div className="content_primary _on_popup">

                <InputWithIcon
                    ref={montoRef}
                    type="text"
                    title="Monto"
                    Icono={Iconos.Currency}
                    value={funciones.current.formatPrice(monto).format}
                    onChange={v => { alertaRemove(montoRef.current); setMonto(v) }} />

                <DatePicker
                    ref={fechaRef}
                    title="Fecha"
                    value={fecha}
                    onChange={(date) => { alertaRemove(fechaRef.current); setFecha(date); }} />

                <InputList
                    ref={bankOrigenRef}
                    title="Cuenta de origen"
                    Icono={Iconos.Bank}
                    value={bancoOrigen}
                    onChange={handleBankOrigin}
                    readOnly={cuentas.length < 1}
                    list={cuentas.map(item => {
                        let _cuentas = format(item.id) + " - " + item.nombre;
                        return _cuentas.trim();
                    })} />

                <InputList
                    ref={bankDestinoRef}
                    title="Cuenta de destino"
                    Icono={Iconos.Bank}
                    value={bancoDestino}
                    onChange={handleBankDestination}
                    readOnly={cuentas.length < 1}
                    list={cuentas.map(item => {
                        let _cuentas = format(item.id) + " - " + item.nombre;
                        return _cuentas.trim();
                    })} />


                <TextArea
                    ref={notaRef}
                    title="Concepto"
                    value={nota}
                    onChange={v => { alertaRemove(notaRef.current); setNota(v) }} />
            </div>


            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={e => validate(e)}>Guardar</button>
                }
            </div>
        </MovePopUp>)
}

export default CambioCaja;