import api from 'services/api';

/* eslint eqeqeq: 0 */

export function uploadFile(data) {
    const url = process.env.REACT_APP_IMAGES;
    let respuesta;

    return new Promise(async (resolve, reject) => {

        if (data !== null) {
            respuesta = await api.fetchFormData(data, "upload-file");

            if (respuesta.response == 1) {
                let _new_file = Object.assign(data.get("file"), {
                    url: url + data.get("destino") + "/" + respuesta.img,
                    preview: url + data.get("destino") + "/" + respuesta.img,
                    nameinserver: respuesta.img
                });

                resolve(_new_file);
            } else {
                reject(respuesta.msg);
            }
        } else {
            reject("No hay datos para subir!");
        }

    });
}

export function uploadExcel(data) {
    const url = process.env.REACT_APP_IMAGES;
    let respuesta;

    return new Promise(async (resolve, reject) => {

        if (data !== null) {
            respuesta = await api.fetchFormData(data, "upload-excel");

            if (respuesta.response == 1) {
                let _new_file = Object.assign(data.get("file"), {
                    url: url + data.get("destino") + "/" + respuesta.img,
                    preview: url + data.get("destino") + "/" + respuesta.img,
                    nameinserver: respuesta.img
                });

                resolve(_new_file);
            } else {
                reject(respuesta.msg);
            }
        } else {
            reject("No hay datos para subir!");
        }

    });
}
