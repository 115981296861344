import * as React from "react";
import { RequirePermissions } from "shared/RequirePermissions";
import { InputWithIcon } from "shared/Components";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { DropFile } from "shared/FileUpload";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";
import Menu from "./Menu";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const VoucherDetail = (router) => {
    const { navigate, location, params } = router;
    const { setTitle } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));
    const [noData, setNoData] = React.useState(false);

    const [vouchers, setVouchers] = React.useState([]);
    const [pedido, setPedido] = React.useState("");
    const vouchersRef = React.useRef([]);
    const pedidoRef = React.useRef(null);
    const [data, setData] = React.useState({});


    const createFile = React.useCallback(async (_data) => {
        let _attach = _data.files ?? [];
        _attach.forEach(async (_file, _index) => {
            let response = await fetch(_file.url, {
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            let data = await response.blob();

            let file = new File([data],
                _file.name,
                {
                    type: _file.name.split(".").pop() === "pdf" ? "application/pdf" : "image/" + _file.name.split(".").pop(),
                    lastModified: Date.now()
                });
            file = Object.assign(file, {
                preview: _file.url,
                url: _file.url,
                path: _file.name,
                nameinserver: _file.name,
                isUpdate: true,

                sign: _data.signature,
                bank: _file.bank ?? _data.bank,
                voucher: _file.voucher ?? _data.voucher,
                bank_name: _file.bank_name ?? _data.bank_name,
            });

            setVouchers(a => [...a, {
                bank: file.bank,
                bank_name: file.bank_name,
                voucher: file.voucher,
                pedido: _data.pedido,
                signature: _data.signature,
                file: [file]
            }]);
        });
    }, []);

    const getData = async (_voucher) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-vouchers",
            data: {
                task: "por_voucher",
                voucher: _voucher
            }
        });

        if (_data.response == 1) {
            let _datos = _data.data[0];
            if (_datos) {
                let _info = JSON.parse(_datos.info);

                setPedido(_info.pedido);
                createFile(_info);
                setData({
                    ..._info,
                    asesor: JSON.parse(_datos.asesor),
                    estado: _datos.estado,
                    id: _datos.id,
                });
            } else {
                setNoData(true);
            }
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {

        if (params.voucher) {
            setTitle("Detalle comprobante " + params.voucher);

            funciones.current.validaSesion("comprobantes_ver").then(response => {
                if (response) {
                    setPermission(true);
                    getData(params.voucher);
                } else {
                    setPermission(false);
                    setShowLoading(false);
                }
            });
        } else {
            if (location.state && location.state.data) {
                let _data = location.state.data;

                setTitle("Detalle comprobante " + _data.voucher, location.state);
                setData(_data);

                funciones.current.validaSesion("comprobantes_ver").then(response => {
                    if (response) {
                        setPermission(true);
                        setPedido(_data.pedido);
                        createFile(_data);
                    } else {
                        setPermission(false);
                    }
                    setShowLoading(false);
                });
            } else {
                setShowLoading(false);
                setTitle("Detalle comprobante", location.state);
                Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                    "warning",
                    () => navigate(-1));
            }
        }
    }, [params.voucher, location.pathname]);

    return (
        <RequirePermissions menu={true} permission={permission} loading={showLoading}>
            <Menu
                data={data}
                navigate={navigate}
                reload={() => navigate(-1)}>

                {noData ?
                    <div className="head_bar">
                        <h2 className="head_bar-title">No se encuentra el comprobante "{vouchers.map(item => item.voucher).join(", ")}"</h2>
                    </div>
                    :
                    <>
                        <div className="head_bar">

                            {data.estado == 1 ?
                                <h2 className="head_bar-title">
                                    <span className="_aceptado">Comprobante "{vouchers.map(item => item.voucher).join(", ")}"
                                        <sup>Aceptado</sup>
                                    </span>
                                    {(data.validacion && data.validacion.nota.length > 0) && <small><strong>Nota:</strong> {data.validacion.nota}</small>}
                                </h2>
                                :
                                data.estado == 2 ?
                                    <h2 className="head_bar-title">
                                        <span className="_rechazado">Comprobante "{vouchers.map(item => item.voucher).join(", ")}"
                                            <sup>rechazado</sup>
                                        </span>
                                        {(data.validacion && data.validacion.nota.length > 0) && <small><strong>Motivo del rechazo:</strong> {data.validacion.nota}</small>}
                                    </h2>
                                    :
                                    <h2 className="head_bar-title"><span>Comprobante "{vouchers.map(item => item.voucher).join(", ")}"</span></h2>
                            }
                        </div>

                        <div className="content_primary _on_details _pedido_voucher">
                            <InputWithIcon
                                ref={pedidoRef}
                                type="text"
                                title="Número de pedido"
                                Icono={Iconos.Pedidos}
                                value={pedido}
                                onChange={v => setPedido(v)} />
                        </div>
                        <div className="content_primary _on_details _in_center">

                            {vouchers.map((item, index) => <fieldset key={index} className="voucher">
                                <legend>Comprobante {index + 1}</legend>

                                <InputWithIcon
                                    ref={e => vouchersRef.current["voucher" + index] = e}
                                    type="text"
                                    title="Número de comprobante"
                                    value={item.voucher}
                                    onChange={v => {
                                        setVouchers(all => {
                                            let _new = [...all];
                                            _new[index].voucher = v;
                                            return _new;
                                        });
                                    }} />

                                <InputWithIcon
                                    ref={e => vouchersRef.current["bank" + index] = e}
                                    title="Cuenta Bancaria"
                                    Icono={Iconos.Bank}
                                    value={item.bank_name}
                                    onChange={v => { }} />

                                <div className="form_group">
                                    <span className="form_group-label">Foto{item.file.length > 1 ? "s" : ""} del comprobante</span>
                                    <DropFile
                                        readOnly={true}
                                        ref={e => vouchersRef.current["file" + index] = e}
                                        voucher={item.voucher}
                                        attach={item.file}
                                        onChange={v => {
                                            setVouchers(all => {
                                                let _new = [...all];
                                                _new[index].file = v.length > 0 ? [v[0]] : [];
                                                return _new;
                                            });
                                        }} />
                                </div>
                            </fieldset>)}
                        </div>

                    </>
                }
                <Loading state={showLoading} />
            </Menu>
        </RequirePermissions>
    )
}

export default withRouter(VoucherDetail);