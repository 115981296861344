import { Alert } from "shared/Alert";
import api from "./api";
import log from "./log";

/* eslint eqeqeq: 0 */

export class Funciones {

    constructor(navigate) {
        this.navigate = navigate;
    }

    async getSession() {
        const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10, permisos: {} };
        let current_time = new Date().getTime() / 1000;
        let _to_return = data_user;


        if (data_user.auth === undefined) {
            this.navigate("/sign-in");
        } else if (current_time - data_user.time > 10800) {
            _to_return = { nivel: 10, permisos: {} };
            localStorage.removeItem('usuario');
            Alert("¡La sesión a caducado! \nDebes iniciar de nuevo", "warning", () => {
                this.navigate("/sign-in");
            });
        } else {
            let _data = await api.fetchJson({
                url: "valida-session",
                data: {}
            });

            if (_data.response == 1) {
                if (parseInt(_data.auth.estado) === 1) {
                    // llamo denuevo la información del usuario ya que en el digest se actualiza el tienpo
                    let _new_data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10, permisos: {} };
                    let _info = JSON.parse(_data.auth.info);
                    _to_return = {
                        ..._new_data_user,
                        permisos: _info.permisos,
                        time: _data.auth.time,
                        token: _data.auth.token,
                        validate: _data.auth.validate,
                        nivel: _data.auth.nivel,
                    };

                    localStorage.setItem('usuario', JSON.stringify(_to_return));
                } else {
                    _to_return = { nivel: 10, permisos: {} };
                    localStorage.removeItem('usuario');
                    Alert("El estado de tu cuenta ha cambiado, es necesario que inicies sesión de nuevo.", "warning", () => {
                        this.navigate("/sign-in");
                    });
                }
            } else if (_data.response == -2) {
                _to_return = { nivel: 10, permisos: {} };
                Alert(_data.msg, "warning", () => {
                    this.navigate("/sign-in");
                });
            } else {
                _to_return = { nivel: 10, permisos: {} };
                Alert(_data.msg, "warning");
            }
        }

        return _to_return;
    }

    /**
     * Válida la sesión actual y renueva el tiempo
     * o reenvía a login si se excede dicho tiempo
     * @param {String} permission El permiso a validar si se deja vacío el valor sera `"default"` tambien puedes pasar un areglo de varios permisos `["perm_01", "perm_02"]`
     * @returns {Promise}
     */
    async validaSesion(permission = "default") {
        let _show = false;
        let _usuario = await this.getSession();

        if (_usuario.auth === undefined) {
            _show = false;
        } else if (permission === "default") {
            _show = _usuario.auth !== undefined;
        } else if (typeof permission === "object") {
            for (let i = 0; i < permission.length; i++) {
                const item = permission[i];
                _show = _usuario.permisos[item] !== undefined;
                log.d(item, _usuario.permisos[item] !== undefined);

                if (!_show) {
                    break;
                }
            }
        } else {
            _show = _usuario.permisos[permission] !== undefined;
            log.d(permission, _show);
        }

        return _show;
    }

    /**
     * 
     * Válida el permiso pasado con los permisos del usuario
     * @param {String} permission El permiso a validar si se deja vacío el valor sera "default"
     * @returns {Boolean}
     */
    validaPermisos(permission = "default") {
        const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 10, permisos: {} };

        if (permission === "default") {
            return data_user.auth !== undefined;
        } else if (typeof permission === "object") {
            let _have = false;
            permission.forEach(item => {
                if (data_user.permisos[item] !== undefined) {
                    _have = true;
                }
            });
            return _have;
        } else {
            return data_user.permisos[permission] !== undefined;
        }
    }

    /**
     * Calcula la posición del popup en la fila
     * @param {MouseEvent} e Evento del mouse
     * @param {RefObject} _popup la referencia del popup
     * @returns {JSON} Objeto con la posicion de la ventana emergente
     */
    getPosInRow(e, _popup) {
        const _top_bar = 70;
        const _popup_width = _popup.offsetWidth;
        // const _popup_height = _popup.offsetHeight;
        const _popup_height = (_popup.firstChild.getBoundingClientRect().height + 24);

        let _left = e.pageX - 16;
        let _top = e.target.getBoundingClientRect().top;
        let _bottom = e.target.getBoundingClientRect().bottom;
        let _width = document.getElementById("root").offsetWidth;
        let _height = (window.innerHeight - _bottom);

        _left = (_left + _popup_width) > _width ? (_width - (_popup_width + 5)) : _left;
        _top = _height < _popup_height ? (_bottom - (_popup_height + 5)) : _top;
        _top = _top < _top_bar ? _top_bar : _top;
        return {
            top: _top,
            left: _left,
            width: _popup_width,
            height: _popup_height,
        };
    }

    /**
     * Calcula la posición del popup en la pantalla
     * @param {MouseEvent} e Evento del mouse
     * @param {RefObject} _popup la referencia del popup
     * @returns {JSON} Objeto con la posicion de la ventana emergente
     */
    getPosInDisplay(e, _popup) {
        let _clic_left = e.pageX;
        let _clic_top = e.pageY;

        const _shadow_top_bar = 90;
        const _window_bottom = window.innerHeight;

        const _menu_width = _popup.offsetWidth;
        const _menu_height = (_popup.firstChild.getBoundingClientRect().height + 24); // _popup.offsetHeight
        const _menu_bottom = (_clic_top + _menu_height);

        const _container_top = e.target.getBoundingClientRect().top; // eslint-disable-next-line
        const _container_bottom = e.target.getBoundingClientRect().bottom;

        const _width = document.getElementById("root").offsetWidth;


        _clic_left = (_clic_left + _menu_width) > _width ? (_width - (_menu_width + 12)) : _clic_left;
        _clic_top = _menu_bottom > _window_bottom ? (_window_bottom - (_menu_height + 5)) : _clic_top;
        _clic_top = _clic_top + (_container_top - _shadow_top_bar);
        _clic_top = _clic_top < _shadow_top_bar ? _shadow_top_bar : _clic_top;

        return {
            top: _clic_top,
            left: _clic_left,
            width: _menu_width,
            height: _menu_height,
        };
    }

    /**
     * 
     * @param {JSON} parent los datos de posicion del elemeto padre
     * @param {RefObject} _popup la referencia del popup
     * @returns {JSON} datos de la posicion en formato json
     */
    getPositonPopUp(parent, _popup) {
        const media_movil = window.matchMedia('(max-width: 520px)').matches;
        const _top_bar = 70;
        const _corre_left = media_movil ? 8 : 16;
        const _popup_width = parent.width ?? _popup.offsetWidth;
        const _popup_height = _popup.offsetHeight;

        let _top = parent.top;
        let _left = parent.left;
        let _bottom = (_top + _popup_height);
        let _width_container = document.getElementById("root").offsetWidth;
        let _height_window = window.innerHeight;

        _left = (_left + _popup_width) > _width_container ? (_width_container - (_popup_width + _corre_left)) : _left;
        _top = _bottom > _height_window ? (_height_window - (_popup_height + 5)) : _top;
        _top = _top < _top_bar ? _top_bar : _top;

        return {
            top: _top,
            left: _left,
            width: parent.width,
            // height: _popup_height > (_height_window - _top_bar) ? (_height_window - _top_bar) : _popup_height
        };
    }


    compareObjects(obj1, obj2) {
        const firstObject = Object.keys(obj1);
        const secondObject = Object.keys(obj2);

        if (firstObject.length !== secondObject.length) {
            return false;
        }

        return firstObject.every((key) => {
            if (obj1[key] === null && obj2[key] === null) {
                return true;
            }

            if (obj1[key] === null || obj2[key] === null) {
                return false;
            }

            if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                return this.compareObjects(obj1[key], obj2[key]);
            }

            return obj1[key] === obj2[key];
        });
    }

    /**
     * 
     * @param num Number to format 
     * @returns {JSON} obj {`int`, `format`}
     */
    formatPrice(num) {
        // num = parseFloat(num.toFixed(2));
        num = num.toString().replaceAll(".", "");
        let _float = num.split(',')[1];
        _float = _float && _float.length > 2 ? _float.substring(0, 2) : _float;
        let _clean = num.split(',')[0];
        let _minus = _clean.substring(0, 1);
        _clean = _minus == "-" ? _clean.substring(1) : _clean;
        _minus = _minus == "-" ? _minus : "";
        let _cant = parseInt(_clean.length / 3);
        let _to_return = "";
        let _rest = _clean;
        let _parts = [];

        for (let i = 0; i < _cant; i++) {
            _parts.push(_rest.slice(-3));
            _rest = _rest.slice(0, -3);
        }

        for (let e = _parts.length - 1; e >= 0; e--) {
            _to_return += "." + _parts[e];
        }

        _to_return = _rest + _to_return;
        _to_return = _to_return.slice(0, 1) == "." ? _to_return.slice(1, _to_return.length) : _to_return;

        if (isNaN(_clean)) {
            return {
                int: _float ? _clean + "," + _float : _clean,
                format: _float ? _minus + _clean + "," + _float : _minus + _clean
            };
        }

        if (num.substring(num.length - 1, num.length) === ",") {
            return {
                int: _clean + ",",
                format: _minus + _to_return + ","
            };
        } else {
            _to_return = _float ? _minus + _to_return + "," + _float : _minus + _to_return;
            return {
                int: _float ? _clean + "," + _float : _clean,
                format: _to_return
            };
        }
    }

}