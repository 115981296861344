import * as React from "react";
import { InputCheck, InputWithIcon, Message } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { NavLink } from "react-router-dom";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const NewDispatch = (router) => {
    const { navigate } = router;
    const { setTitle } = useMedia();
    const [listaPedidos, setListaPedidos] = React.useState([{ id: 1, value: "" }]);
    const [listaMensajes, setListaMensajes] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [automatico, setAutomatico] = React.useState(true);
    const funciones = React.useRef(new Funciones(navigate));
    const [guia, setGuia] = React.useState("");

    const btnAddRef = React.useRef(null);
    const guiaRef = React.useRef(null);
    const itemsRef = React.useRef([]);


    const handleInputGuia = (_value) => {
        alertaRemove(guiaRef.current);
        setGuia(_value);
        if (automatico) {
            setTimeout(() => {
                if (guiaRef.current.value.length === _value.length) {
                    itemsRef.current[0].focus();
                }
            }, 1000);
        }
    }

    const handleInputCode = (_index, _id, _value) => {
        alertaRemove(itemsRef.current[_index]);
        setListaPedidos(p => {
            let _new = p.map(item => {
                if (item.id == _id) {
                    _value = _value.replaceAll("'", "-");
                    let _info = _value.split(")");

                    return {
                        ...item,
                        value: _value,
                        numero: _info[0] ? _info[0].trim() : "No aplica",
                        fecha: _info[1] ? _info[1].trim() : "No aplica",
                        cantidad: _info[2] ? _info[2].trim() : "No aplica",
                        cliente: _info[3] ? _info[3].trim() : "No aplica",
                        valor: _info[4] ? _info[4].trim() : "No aplica",
                        ciudad: _info[5] ? _info[5].trim() : "No aplica",
                        contacto: _info[6] ? _info[6].trim() : "No aplica",
                        cedula: _info[7] ? _info[7].trim() : "No aplica",
                        etiquetas: _info[8] ? _info[8].trim() : "No aplica",
                        productos: _info[11] ? _info[11].trim() : "No aplica",
                    };
                } else {
                    return item;
                }
            });

            if (automatico) {
                setTimeout(() => {
                    if (itemsRef.current[_index].value.length === _value.length) {
                        if ((_index + 1) < p.length) {
                            itemsRef.current[_index + 1].focus();
                        } else {
                            validate(_new);
                        }
                    }
                }, 1000);
            }
            return _new;
        });
    }

    const handleRemove = (_id, _value) => {
        setListaPedidos(p => {
            return p.filter(item => item.id !== _id);
        });
    }

    const saveDispatch = async (_lista_pedidos) => {
        setShowLoading(true);
        let _lista = [];

        _lista_pedidos.map(item => {
            _lista.push(item.numero);
        });

        let _data = await api.fetchJson({
            url: "set-dispatch",
            data: {
                task: "??",
                info: JSON.stringify({
                    guia,
                    pedidos: _lista_pedidos
                })
            }
        });

        if (_data.response == 1) {
            let _pedidos = listaPedidos.length > 1 ? "\n Pedidos: " + _lista : "\n Pedido: " + _lista;
            if (automatico) {
                setTimeout(() => {
                    setListaMensajes(l => {
                        return [...l, { id: (l.length + 1), title: _data.msg, content: "Guía: " + guia + _pedidos }];
                    });
                    setGuia("");
                    setListaPedidos([{ id: 1, value: "" }]);
                    guiaRef.current.focus();
                    setShowLoading(false);
                }, 1000);
            } else {
                Alert(_data.msg + _pedidos, "ok", () => {
                    setGuia("");
                    setListaPedidos([{ id: 1, value: "" }]);
                    guiaRef.current.focus();
                });
                setShowLoading(false);
            }
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
            setShowLoading(false);
        } else {
            Alert(_data.msg, "warning");
            setShowLoading(false);
        }

    }

    const validate = (_lista_pedidos) => {
        alertaRemove(guiaRef.current);
        alertaRemove(btnAddRef.current);


        if (guia == null || guia.length < 1) {
            alerta(guiaRef.current, "¡Debes ingresar el número de la guía!");
            guiaRef.current.focus();
            return false;
        }

        if (_lista_pedidos.length < 1) {
            alerta(btnAddRef.current, "¡Debes agregar almenos un pedido!");
            btnAddRef.current.focus();
            return false;
        }

        for (let i = 0; i < _lista_pedidos.length; i++) {
            alertaRemove(itemsRef.current[i]);

            if (_lista_pedidos[i].value == null || _lista_pedidos[i].value.length < 1) {
                alerta(itemsRef.current[i], "¡Debes ingresar información del pedido!");
                itemsRef.current[i].focus();
                return false;
            }

            let codigo = _lista_pedidos.filter(item => (_lista_pedidos[i].value.length > 1 && item.numero === _lista_pedidos[i].numero));

            if (codigo.length > 1) {
                alerta(itemsRef.current[i], "¡El número de pedido se encuentra repetido!");
                itemsRef.current[i].focus();
                return false;
            }
        }

        saveDispatch(_lista_pedidos);
    }

    React.useEffect(() => {
        setTitle("Crear nuevo despacho");

        funciones.current.validaSesion("despachos_crear").then(response => {
            if (response) {
                setPermission(true);
                setTimeout(() => {
                    guiaRef.current.focus();
                }, 1000);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Crear nuevo despacho</h2>
            </div>

            <div className="content_primary">

                <InputCheck
                    className="_bottom"
                    title="Guardado automatico"
                    checked={automatico}
                    onClick={() => setAutomatico(v => !v)} />

            </div>

            <div className="internal_frame">
                <div className="content_primary">

                    <InputWithIcon
                        ref={guiaRef}
                        type="text"
                        title="Número de guía"
                        value={guia}
                        onChange={handleInputGuia} />

                    {listaPedidos.map((item, i) => {
                        return <InputWithIcon
                            key={i}
                            ref={r => itemsRef.current[i] = r}
                            title={"Información del pedido " + (item.numero || item.id)}
                            Icono={Iconos.QrcodeScan}
                            value={item.value}
                            onChange={v => handleInputCode(i, item.id, v)} >
                            <Iconos.CloseCircle className="close" onClick={() => handleRemove(item.id)} />
                            {(item.numero && item.numero !== "No aplica") && <p className="detalles">
                                <span><b>Numero:</b> {item.numero} </span><br />
                                {(item.fecha && item.fecha !== "No aplica") && <><span><b>Fecha:</b> {item.fecha} </span><br /></>}
                                {(item.cantidad && item.cantidad !== "No aplica") && <><span><b>Cantidad:</b> {item.cantidad} </span><br /></>}
                                {(item.cliente && item.cliente !== "No aplica") && <><span><b>Cliente:</b> {item.cliente} </span><br /></>}
                                {(item.valor && item.valor !== "No aplica") && <><span><b>Valor:</b> {item.valor} </span><br /></>}
                                {(item.ciudad && item.ciudad !== "No aplica") && <><span><b>Ciudad:</b> {item.ciudad} </span><br /></>}
                                {(item.contacto && item.contacto !== "No aplica") && <><span><b>Contacto:</b> {item.contacto} </span><br /></>}
                                {(item.cedula && item.cedula !== "No aplica") && <><span><b>Cédula:</b> {item.cedula} </span><br /></>}
                                {(item.etiquetas && item.etiquetas !== "No aplica") && <><span><b>Etiquetas:</b> {item.etiquetas} </span><br /></>}
                                {(item.productos && item.productos !== "No aplica") && <><span><b>Productos:</b> {item.productos}  </span><br /></>}
                            </p>}
                        </InputWithIcon>
                    })}
                </div>

                <button type="button" ref={btnAddRef}
                    className="btn btn_with_icon _left"
                    onClick={() => {
                        alertaRemove(btnAddRef.current);
                        setListaPedidos(p => [...p, { id: (p.length + 1), value: "" }]);
                    }}>
                    <Iconos.QrcodeAdd />
                    Agregar pedido
                </button>
            </div>

            {listaMensajes.length > 0 &&
                <div className="content_messages">

                    {listaMensajes.map((item, i) => {
                        return <Message
                            key={i}
                            title={item.title}
                            content={item.content}
                            onTimeOut={() => setListaMensajes(l => l.filter(m => m.id !== item.id))} />
                    })}
                </div>}

            {!automatico && <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={() => validate(listaPedidos)}>Guardar</button>
            </div>}

            <div className="content_btns">
                <NavLink
                    to="/despachos"
                    className="btn link_to_btn _right">
                    Ver todos los despachos
                </NavLink>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(NewDispatch);