import * as React from "react";
import { Funciones } from "services/funciones";
import PropTypes from "prop-types";
import { useMedia } from "hooks";
import * as Iconos from "images";


const MovePopUp = (params) => {
    const { children, className = "popup_window", close, title } = params;
    const [pos, set_pos] = React.useState({ top: 80, left: 0 });
    const [dif, setDif] = React.useState({ top: 0, left: 0 });
    const [inDrag, setInDrag] = React.useState(false);
    const funciones = React.useRef(new Funciones());
    const { media_movil } = useMedia();
    const popupRef = React.useRef();


    const onMouseMove = (e) => {
        if (inDrag) {
            set_pos(p => {
                return { ...p, top: (e.screenY - dif.top), left: (e.screenX - dif.left) };
            });
        }
    }

    const onTouchMove = (e) => {
        if (inDrag) {
            set_pos(p => {
                return { ...p, top: (e.touches[0].screenY - dif.top), left: (e.touches[0].screenX - dif.left) };
            });
        }
    }

    const onMouseDown = (e) => {
        let _dif_y = e.screenY - popupRef.current.getBoundingClientRect().top;
        let _dif_x = e.screenX - popupRef.current.getBoundingClientRect().left;
        setDif({ top: _dif_y, left: _dif_x });
        setInDrag(true);
    }

    const onTouchStart = (e) => {
        let _dif_y = e.touches[0].screenY - popupRef.current.getBoundingClientRect().top;
        let _dif_x = e.touches[0].screenX - popupRef.current.getBoundingClientRect().left;
        setDif({ top: _dif_y, left: _dif_x });
        setInDrag(true);
    }

    React.useEffect(() => {
        set_pos(funciones.current.getPositonPopUp(pos, popupRef.current));
    }, [params.parent]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        set_pos(funciones.current.getPositonPopUp(params.parent, popupRef.current));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {media_movil ?
                <div className="popup" onTouchMove={onTouchMove}>
                    <div className={className} style={pos} ref={popupRef}>
                        <h3 className="popup_title"
                            onTouchStart={onTouchStart}
                            onTouchEnd={() => setInDrag(false)}>
                            <Iconos.CloseCircle onClick={close} />{title} </h3>
                        <div className="popup_content">
                            {children}
                        </div>
                    </div>
                </div>
                :
                <div className="popup" onMouseMove={onMouseMove}>
                    <div className={className} style={pos} ref={popupRef}>
                        <h3 className="popup_title"
                            onMouseDown={onMouseDown}
                            onMouseUp={() => setInDrag(false)}>
                            <Iconos.CloseCircle onClick={close} />{title} </h3>
                        <div className="popup_content">
                            {children}
                        </div>
                    </div>
                </div>}
        </>
    )
}

MovePopUp.propTypes = {
    parent: PropTypes.shape({
        top: PropTypes.any,
        left: PropTypes.any,
        width: PropTypes.any,
    }).isRequired,
    close: PropTypes.func.isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default MovePopUp;