import * as React from "react";
import { Alert, alertArea, alertaRemove } from "shared/Alert";
import { useNavigate } from "react-router-dom";
import { TextArea } from "shared/Components";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CrearBanco = ({ parent, reload, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);
    const [nombre, setNombre] = React.useState("");

    const nombreRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const saveCode = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "set-concept",
            data: {
                task: "entrada",
                info: JSON.stringify({
                    tipo: 1, // 1 - entrada
                    caegoria: "entradas",
                    nombre: nombre.trim(),
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                close(false);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();

        alertaRemove(nombreRef.current);

        if (nombre.length < 1) {
            alertArea(nombreRef.current, "¡Debes ingresar el nombre del banco!");
            nombreRef.current.focus();
            return false;
        }

        saveCode();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        if (nombreRef.current) {
            nombreRef.current.focus();
        }
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title={"Crear banco"}>

            <div className="content_primary _on_popup">

                <h5>Recuerda que el número se asigna de forma automática al momento de guardar.</h5>

                <TextArea
                    ref={nombreRef}
                    title="Nombre del banco"
                    value={nombre}
                    onChange={v => { alertaRemove(nombreRef.current); setNombre(v) }} />

            </div>

            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={validate}>Guardar</button>
                }
            </div>
        </MovePopUp>)
}

export default CrearBanco;