/* eslint import/no-anonymous-default-export: 0 */

class Logs {
    d(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 100, 255)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        obj ?
            console.log(`\n %c${msg}:`, style, obj, '\n\n')
            :
            console.log(`\n %c${msg}`, style, '\n\n');
    }

    i(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(255, 255, 255)",
            "background-color: rgb(0, 128, 0)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        obj ?
            console.log(`\n %c${msg}:`, style, obj, '\n\n')
            :
            console.log(`\n %c${msg}`, style, '\n\n');
    }

    w(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(40, 40, 40)",
            "background-color: rgb(0, 255, 0)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        obj ?
            console.log(`\n %c${msg}:`, style, obj, '\n\n')
            :
            console.log(`\n %c${msg}`, style, '\n\n');
    }

    e(msg, obj) {
        const Style = [
            "padding: 2px 4px",
            "color: rgb(238, 238, 238)",
            "background-color: rgb(255, 58, 58)",
            "font-weight: bold",
            "border-radius: 4px",
        ];

        const style = Style.join(';') + ';';

        obj ?
            console.log(`\n %c${msg}:`, style, obj, '\n\n')
            :
            console.log(`\n %c${msg}`, style, '\n\n');
    }

    title(msg, obj) {
        const Style = [
            "padding: 0.45rem 0.9rem 0.4rem",
            "margin-top: 0.5rem",
            "color: rgb(240, 240, 240)",
            "background-color: #202020",
            "border: 1px solid rgb(240, 240, 240)",
            "border-radius: 8px",
            "font-weight: bold",
            "font-size: 1.5rem",
        ];

        const style = Style.join(';') + ';';

        obj ?
            console.log(`\n %c${msg}:`, style, obj, '\n\n')
            :
            console.log(`\n %c${msg}`, style, '\n\n');
    }
}

export default new Logs();