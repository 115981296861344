import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { InputPassword } from "shared/Components";
import { useNavigate } from "react-router-dom";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import CryptoJS from "crypto-js";
import api from "services/api";

/* eslint eqeqeq: 0 */

const ChangePass = ({ parent, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);

    const [oldPass, setOldPass] = React.useState("");
    const [newPass, setNewPass] = React.useState("");
    const [confirmPass, setConfirmPass] = React.useState("");

    const inputOldPass = React.useRef(null);
    const inputNewPass = React.useRef(null);
    const inputConfirmPass = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const savePass = async () => {
        setShowLoading(true);
        let _old_pass = CryptoJS.SHA256(oldPass.trim());
        let _new_pass = CryptoJS.SHA256(newPass.trim());

        let _data = await api.fetchJson({
            url: "update-password",
            data: {
                info: JSON.stringify({
                    old_pass: _old_pass.toString(),
                    new_pass: _new_pass.toString()
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                close(false);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(inputOldPass.current);
        alertaRemove(inputNewPass.current);
        alertaRemove(inputConfirmPass.current);


        if (oldPass.length < 1) {
            alerta(inputOldPass.current, "¡Debes ingresar tu contraseña actual!");
            inputOldPass.current.focus();
            return false;
        }

        if (oldPass.trim().length < 8) {
            alerta(inputOldPass.current, "¡Esta contraseña es invalida!");
            inputOldPass.current.focus();
            return false;
        }

        if (newPass.length < 1) {
            alerta(inputNewPass.current, "¡Debes ingresar tu nueva contraseña!");
            inputNewPass.current.focus();
            return false;
        }

        if (newPass.length < 8) {
            alerta(inputNewPass.current, "¡La contraseña no puede ser menor a ocho digitos!");
            inputNewPass.current.focus();
            return false;
        }

        if (confirmPass.length < 1) {
            alerta(inputConfirmPass.current, "¡Debes confirmar la nueva contraseña!");
            inputConfirmPass.current.focus();
            return false;
        }

        if (newPass.trim() !== confirmPass.trim()) {
            alerta(inputConfirmPass.current, "¡La contraseña no coincide!");
            inputConfirmPass.current.focus();
            return false;
        }

        savePass();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title="Cambiar contraseña">

            <div className="content_primary _on_popup">

                <InputPassword
                    ref={inputOldPass}
                    autoComplete="off"
                    name="old_pass"
                    title="Contraseña actual"
                    value={oldPass}
                    onChange={v => { alertaRemove(inputOldPass.current); setOldPass(v) }} />

                <InputPassword
                    ref={inputNewPass}
                    autoComplete="off"
                    name="new_pass"
                    title="Nueva contraseña"
                    value={newPass}
                    onChange={v => { alertaRemove(inputNewPass.current); setNewPass(v) }} />

                <InputPassword
                    ref={inputConfirmPass}
                    autoComplete="off"
                    name="confrm_pass"
                    title="Confirmación de contraseña"
                    value={confirmPass}
                    onChange={v => { alertaRemove(inputConfirmPass.current); setConfirmPass(v) }} />
            </div>


            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={e => validate(e)}>Guardar</button>
                }
            </div>
        </MovePopUp>)
}

export default ChangePass;