import * as React from "react";
import { Alert, alertArea, alerta, alertaRemove } from "shared/Alert";
import { Input, TextArea } from "shared/Components";
import { useNavigate } from "react-router-dom";
import MovePopUp from "shared/MovePopUp";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const EditarCodigo = ({ data, parent, reload, close }) => {
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = React.useState(false);
    const [categoria, setCategoria] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [tipo, setTipo] = React.useState(2);

    const categoriaRef = React.useRef(null);
    const nombreRef = React.useRef(null);
    const tipoRef = React.useRef(null);


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const saveCode = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-concept",
            data: {
                task: tipo == 1 ? "entrada" : "salida",
                info: JSON.stringify({
                    id: data.id,
                    tipo: tipo, // 0 - salida, 1 - entrada
                    caegoria: categoria.trim().toLowerCase(),
                    nombre: nombre.trim(),
                })
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                close(false);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                close(false);
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        alertaRemove(categoriaRef.current);
        alertaRemove(nombreRef.current);
        alertaRemove(tipoRef.current);

        if (categoria.length < 1) {
            alerta(categoriaRef.current, "¡Debes ingresar la categoria!");
            categoriaRef.current.focus();
            return false;
        }

        if (nombre.length < 1) {
            alertArea(nombreRef.current, "¡Debes ingresar el nombre del código!");
            nombreRef.current.focus();
            return false;
        }

        if (tipo === 2) {
            alerta(tipoRef.current, "¡Debes indicar si es de entreda o salida!");
            tipoRef.current.focus();
            return false;
        }

        saveCode();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        setTipo(parseInt(data.tipo));
        setCategoria(data.caegoria);
        setNombre(data.nombre);
        if (categoriaRef.current) {
            categoriaRef.current.focus();
        }
    }, [data]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            title={"Editar código " + format(data.id)}>

            <div className="content_primary _on_popup">

                <h5>Recuerda que el número es único y no se puede modificar.</h5>

                <Input
                    ref={categoriaRef}
                    title="Categoria"
                    value={categoria}
                    onChange={v => { alertaRemove(categoriaRef.current); setCategoria(v) }} />

                <TextArea
                    ref={nombreRef}
                    title="Nombre"
                    value={nombre}
                    onChange={v => { alertaRemove(nombreRef.current); setNombre(v) }} />

            </div>

            <div className="radio_goup">
                <label><input type="radio" name="tipo" value="1" checked={tipo === 1} onChange={() => { alertaRemove(tipoRef.current); setTipo(1) }} /><Iconos.CashIn />Entrada</label>
                <label><input type="radio" name="tipo" value="0" checked={tipo === 0} onChange={() => { alertaRemove(tipoRef.current); setTipo(0) }} /><Iconos.CashOut />Salida</label>
                <div className="contain_alert">
                    <input type="hidden" ref={tipoRef} />
                </div>
            </div>
            <br />

            <div className="content_btns _without_margin">
                {showLoading ?
                    <button type="button" className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <button type="button" className="btn btn_primary" onClick={validate}>Guardar</button>
                }
            </div>
        </MovePopUp>)
}

export default EditarCodigo;