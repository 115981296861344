const ciudadesInter = [
    { ciudad: "ABEJORRAL", departamento: "ANTIOQUIA", codigo: "05002000" },
    { ciudad: "ABREGO", departamento: "NORTE DE SANTANDER", codigo: "54003000" },
    { ciudad: "ABRIAQUI", departamento: "ANTIOQUIA", codigo: "05004000" },
    { ciudad: "ACACIAS", departamento: "META", codigo: "50006000" },
    { ciudad: "ACANDI", departamento: "CHOCO", codigo: "27006000" },
    { ciudad: "ACEVEDO", departamento: "HUILA", codigo: "41006000" },
    { ciudad: "ACHI", departamento: "BOLIVAR", codigo: "13006000" },
    { ciudad: "AGRADO", departamento: "HUILA", codigo: "41013000" },
    { ciudad: "AGUA DE DIOS", departamento: "CUNDINAMARCA", codigo: "25001000" },
    { ciudad: "AGUACHICA", departamento: "CESAR", codigo: "20011000" },
    { ciudad: "AGUADA", departamento: "SANTANDER", codigo: "68013000" },
    { ciudad: "AGUADAS", departamento: "CALDAS", codigo: "17013000" },
    { ciudad: "AGUAZUL", departamento: "CASANARE", codigo: "85010000" },
    { ciudad: "AGUSTIN CODAZZI", departamento: "CESAR", codigo: "20013000" },
    { ciudad: "AIPE", departamento: "HUILA", codigo: "41016000" },
    { ciudad: "ALBAN", departamento: "CUNDINAMARCA", codigo: "25019000" },
    { ciudad: "ALBAN", departamento: "NARIÑO", codigo: "52019000" },
    { ciudad: "ALBANIA", departamento: "CAQUETA", codigo: "18029000" },
    { ciudad: "ALBANIA", departamento: "LA GUAJIRA", codigo: "44035000" },
    { ciudad: "ALBANIA", departamento: "SANTANDER", codigo: "68020000" },
    { ciudad: "ALCALA", departamento: "VALLE DEL CAUCA", codigo: "76020000" },
    { ciudad: "ALDANA", departamento: "NARIÑO", codigo: "52022000" },
    { ciudad: "ALEJANDRIA", departamento: "ANTIOQUIA", codigo: "05021000" },
    { ciudad: "ALGARROBO", departamento: "MAGDALENA", codigo: "47030000" },
    { ciudad: "ALGECIRAS", departamento: "HUILA", codigo: "41020000" },
    { ciudad: "ALMAGUER", departamento: "CAUCA", codigo: "19022000" },
    { ciudad: "ALMEIDA", departamento: "BOYACA", codigo: "15022000" },
    { ciudad: "ALPUJARRA", departamento: "TOLIMA", codigo: "73024000" },
    { ciudad: "ALTAMIRA", departamento: "BETULIA", codigo: "05093001" },
    { ciudad: "ALTAMIRA", departamento: "HUILA", codigo: "41026000" },
    { ciudad: "ALTO BAUDO", departamento: "CHOCO", codigo: "27025000" },
    { ciudad: "ALTOS DEL ROSARIO", departamento: "BOLIVAR", codigo: "13030000" },
    { ciudad: "ALVARADO", departamento: "TOLIMA", codigo: "73026000" },
    { ciudad: "AMAGA", departamento: "ANTIOQUIA", codigo: "05030000" },
    { ciudad: "AMALFI", departamento: "ANTIOQUIA", codigo: "05031000" },
    { ciudad: "AMBALEMA", departamento: "TOLIMA", codigo: "73030000" },
    { ciudad: "ANAPOIMA", departamento: "CUNDINAMARCA", codigo: "25035000" },
    { ciudad: "ANCUYA", departamento: "NARIÑO", codigo: "52036000" },
    { ciudad: "ANDALUCIA", departamento: "VALLE DEL CAUCA", codigo: "76036000" },
    { ciudad: "ANDES", departamento: "ANTIOQUIA", codigo: "05034000" },
    { ciudad: "ANGELOPOLIS", departamento: "ANTIOQUIA", codigo: "05036000" },
    { ciudad: "ANGOSTURA", departamento: "ANTIOQUIA", codigo: "05038000" },
    { ciudad: "ANOLAIMA", departamento: "CUNDINAMARCA", codigo: "25040000" },
    { ciudad: "ANORI", departamento: "ANTIOQUIA", codigo: "05040000" },
    { ciudad: "ANSERMA", departamento: "CALDAS", codigo: "17042000" },
    { ciudad: "ANSERMANUEVO", departamento: "VALLE DEL CAUCA", codigo: "76041000" },
    { ciudad: "ANZA", departamento: "ANTIOQUIA", codigo: "05044000" },
    { ciudad: "ANZOATEGUI", departamento: "TOLIMA", codigo: "73043000" },
    { ciudad: "APARTADO", departamento: "ANTIOQUIA", codigo: "05045000" },
    { ciudad: "APIA", departamento: "RISARALDA", codigo: "66045000" },
    { ciudad: "APULO", departamento: "CUNDINAMARCA", codigo: "25599000" },
    { ciudad: "AQUITANIA", departamento: "BOYACA", codigo: "15047000" },
    { ciudad: "ARACATACA", departamento: "MAGDALENA", codigo: "47053000" },
    { ciudad: "ARANZAZU", departamento: "CALDAS", codigo: "17050000" },
    { ciudad: "ARARACUARA", departamento: "LETICIA", codigo: "91001005" },
    { ciudad: "ARATOCA", departamento: "SANTANDER", codigo: "68051000" },
    { ciudad: "ARAUCA", departamento: "ARAUCA", codigo: "81001000" },
    { ciudad: "ARAUCA", departamento: "PALESTINA", codigo: "17524001" },
    { ciudad: "ARAUQUITA", departamento: "ARAUCA", codigo: "81065000" },
    { ciudad: "ARBELAEZ", departamento: "CUNDINAMARCA", codigo: "25053000" },
    { ciudad: "ARBOLEDA", departamento: "NARIÑO", codigo: "52051000" },
    { ciudad: "ARBOLEDAS", departamento: "NORTE DE SANTANDER", codigo: "54051000" },
    { ciudad: "ARBOLETES", departamento: "ANTIOQUIA", codigo: "05051000" },
    { ciudad: "ARCABUCO", departamento: "BOYACA", codigo: "15051000" },
    { ciudad: "ARENAL", departamento: "BOLIVAR", codigo: "13042000" },
    { ciudad: "ARGELIA", departamento: "ANTIOQUIA", codigo: "05055000" },
    { ciudad: "ARGELIA", departamento: "CAUCA", codigo: "19050000" },
    { ciudad: "ARGELIA", departamento: "VALLE DEL CAUCA", codigo: "76054000" },
    { ciudad: "ARIGUANI", departamento: "MAGDALENA", codigo: "47058000" },
    { ciudad: "ARJONA", departamento: "BOLIVAR", codigo: "13052000" },
    { ciudad: "ARMENIA", departamento: "ANTIOQUIA", codigo: "05059000" },
    { ciudad: "ARMENIA", departamento: "QUINDIO", codigo: "63001000" },
    { ciudad: "ARMERO", departamento: "TOLIMA", codigo: "73055000" },
    { ciudad: "ARROYOHONDO", departamento: "BOLIVAR", codigo: "13062000" },
    { ciudad: "ASTREA", departamento: "CESAR", codigo: "20032000" },
    { ciudad: "ATACO", departamento: "TOLIMA", codigo: "73067000" },
    { ciudad: "ATRATO", departamento: "CHOCO", codigo: "27050000" },
    { ciudad: "AYAPEL", departamento: "CORDOBA", codigo: "23068000" },
    { ciudad: "BAGADO", departamento: "CHOCO", codigo: "27073000" },
    { ciudad: "BAHIA SOLANO", departamento: "CHOCO", codigo: "27075000" },
    { ciudad: "BAJO BAUDO", departamento: "CHOCO", codigo: "27077000" },
    { ciudad: "BALBOA", departamento: "CAUCA", codigo: "19075000" },
    { ciudad: "BALBOA", departamento: "RISARALDA", codigo: "66075000" },
    { ciudad: "BARANOA", departamento: "ATLANTICO", codigo: "08078000" },
    { ciudad: "BARAYA", departamento: "HUILA", codigo: "41078000" },
    { ciudad: "BARBACOAS", departamento: "NARIÑO", codigo: "52079000" },
    { ciudad: "BARBOSA", departamento: "ANTIOQUIA", codigo: "05079000" },
    { ciudad: "BARBOSA", departamento: "SANTANDER", codigo: "68077000" },
    { ciudad: "BARCELONA", departamento: "QUINDIO", codigo: "63130001" },
    { ciudad: "BARICHARA", departamento: "SANTANDER", codigo: "68079000" },
    { ciudad: "BARRANCA DE UPIA", departamento: "META", codigo: "50110000" },
    { ciudad: "BARRANCABERMEJA", departamento: "SANTANDER", codigo: "68081000" },
    { ciudad: "BARRANCAS", departamento: "LA GUAJIRA", codigo: "44078000" },
    { ciudad: "BARRANCO DE LOBA", departamento: "BOLIVAR", codigo: "13074000" },
    { ciudad: "BARRANCO MINAS", departamento: "GUAINIA", codigo: "94343000" },
    { ciudad: "BARRANQUILLA", departamento: "ATLANTICO", codigo: "08001000" },
    { ciudad: "BARU", departamento: "CARTAGENA", codigo: "13001003" },
    { ciudad: "BAYUNCA", departamento: "CARTAGENA", codigo: "13001004" },
    { ciudad: "BECERRIL", departamento: "CESAR", codigo: "20045000" },
    { ciudad: "BELALCAZAR", departamento: "CALDAS", codigo: "17088000" },
    { ciudad: "BELEN DE BAJIRA", departamento: "RIOSUCIO", codigo: "27615023" },
    { ciudad: "BELEN DE LOS ANDAQUIES", departamento: "CAQUETA", codigo: "18094000" },
    { ciudad: "BELEN DE UMBRIA", departamento: "RISARALDA", codigo: "66088000" },
    { ciudad: "BELEN LA PLATA", departamento: "HUILA", codigo: "41396001" },
    { ciudad: "BELEN", departamento: "BOYACA", codigo: "15087000" },
    { ciudad: "BELEN", departamento: "NARIÑO", codigo: "52083000" },
    { ciudad: "BELENCITO", departamento: "BOYACA", codigo: "15491001" },
    { ciudad: "BELLO", departamento: "ANTIOQUIA", codigo: "05088000" },
    { ciudad: "BELMIRA", departamento: "ANTIOQUIA", codigo: "05086000" },
    { ciudad: "BELTRAN", departamento: "CUNDINAMARCA", codigo: "25086000" },
    { ciudad: "BERBEO", departamento: "BOYACA", codigo: "15090000" },
    { ciudad: "BETANIA", departamento: "ANTIOQUIA", codigo: "05091000" },
    { ciudad: "BETEITIVA", departamento: "BOYACA", codigo: "15092000" },
    { ciudad: "BETULIA", departamento: "ANTIOQUIA", codigo: "05093000" },
    { ciudad: "BETULIA", departamento: "SANTANDER", codigo: "68092000" },
    { ciudad: "BITUIMA", departamento: "CUNDINAMARCA", codigo: "25095000" },
    { ciudad: "BOAVITA", departamento: "BOYACA", codigo: "15097000" },
    { ciudad: "BOCAS DE SATINGA(OLAYA HERRERA)", departamento: "NARIÑO", codigo: "52490000" },
    { ciudad: "BOCHALEMA", departamento: "NORTE DE SANTANDER", codigo: "54099000" },
    { ciudad: "BOGOTA", departamento: "CUNDINAMARCA", codigo: "11001000" },
    { ciudad: "BOHORQUEZ", departamento: "ATLANTICO", codigo: "08137001" },
    { ciudad: "BOJACA", departamento: "CUNDINAMARCA", codigo: "25099000" },
    { ciudad: "BOJAYA", departamento: "CHOCO", codigo: "27099000" },
    { ciudad: "BOLIVAR", departamento: "CAUCA", codigo: "19100000" },
    { ciudad: "BOLIVAR", departamento: "SANTANDER", codigo: "68101000" },
    { ciudad: "BOLIVAR", departamento: "VALLE DEL CAUCA", codigo: "76100000" },
    { ciudad: "BOLIVIA", departamento: "CALDAS", codigo: "17541002" },
    { ciudad: "BOLOMBOLO", departamento: "ANTIOQUIA", codigo: "05861002" },
    { ciudad: "BOSCONIA", departamento: "CESAR", codigo: "20060000" },
    { ciudad: "BOYACA", departamento: "BOYACA", codigo: "15104000" },
    { ciudad: "BRICEÑO", departamento: "ANTIOQUIA", codigo: "05107000" },
    { ciudad: "BRICEÑO", departamento: "BOYACA", codigo: "15106000" },
    { ciudad: "BRICEÑO", departamento: "CUNDINAMARCA", codigo: "25817014" },
    { ciudad: "BUCARAMANGA", departamento: "SANTANDER", codigo: "68001000" },
    { ciudad: "BUCARASICA", departamento: "NORTE DE SANTANDER", codigo: "54109000" },
    { ciudad: "BUENAVENTURA", departamento: "VALLE DEL CAUCA", codigo: "76109000" },
    { ciudad: "BUENAVISTA", departamento: "BOYACA", codigo: "15109000" },
    { ciudad: "BUENAVISTA", departamento: "CORDOBA", codigo: "23079000" },
    { ciudad: "BUENAVISTA", departamento: "QUINDIO", codigo: "63111000" },
    { ciudad: "BUENAVISTA", departamento: "SUCRE", codigo: "70110000" },
    { ciudad: "BUENOS AIRES", departamento: "CAUCA", codigo: "19110000" },
    { ciudad: "BUESACO", departamento: "NARIÑO", codigo: "52110000" },
    { ciudad: "BUGA", departamento: "VALLE DEL CAUCA", codigo: "76111000" },
    { ciudad: "BUGALAGRANDE", departamento: "VALLE DEL CAUCA", codigo: "76113000" },
    { ciudad: "BURITICA", departamento: "ANTIOQUIA", codigo: "05113000" },
    { ciudad: "BUSBANZA", departamento: "BOYACA", codigo: "15114000" },
    { ciudad: "CABRERA", departamento: "CUNDINAMARCA", codigo: "25120000" },
    { ciudad: "CABRERA", departamento: "SANTANDER", codigo: "68121000" },
    { ciudad: "CABUYARO", departamento: "META", codigo: "50124000" },
    { ciudad: "CACAHUAL", departamento: "INIRIDA", codigo: "94886000" },
    { ciudad: "CACERES", departamento: "ANTIOQUIA", codigo: "05120000" },
    { ciudad: "CACHIPAY", departamento: "CUNDINAMARCA", codigo: "25123000" },
    { ciudad: "CACHIRA", departamento: "NORTE DE SANTANDER", codigo: "54128000" },
    { ciudad: "CACOTA", departamento: "NORTE DE SANTANDER", codigo: "54125000" },
    { ciudad: "CAICEDO", departamento: "ANTIOQUIA", codigo: "05125000" },
    { ciudad: "CAICEDONIA", departamento: "VALLE DEL CAUCA", codigo: "76122000" },
    { ciudad: "CAIMITO", departamento: "SUCRE", codigo: "70124000" },
    { ciudad: "CAJAMARCA", departamento: "TOLIMA", codigo: "73124000" },
    { ciudad: "CAJIBIO", departamento: "CAUCA", codigo: "19130000" },
    { ciudad: "CAJICA", departamento: "CUNDINAMARCA", codigo: "25126000" },
    { ciudad: "CALAMAR", departamento: "BOLIVAR", codigo: "13140000" },
    { ciudad: "CALAMAR", departamento: "GUAVIARE", codigo: "95015000" },
    { ciudad: "CALARCA", departamento: "QUINDIO", codigo: "63130000" },
    { ciudad: "CALDAS", departamento: "ANTIOQUIA", codigo: "05129000" },
    { ciudad: "CALDAS", departamento: "BOYACA", codigo: "15131000" },
    { ciudad: "CALDONO", departamento: "CAUCA", codigo: "19137000" },
    { ciudad: "CALI", departamento: "VALLE DEL CAUCA", codigo: "76001000" },
    { ciudad: "CALIFORNIA", departamento: "SANTANDER", codigo: "68132000" },
    { ciudad: "CALIMA DARIEN", departamento: "VALLE DEL CAUCA", codigo: "76126000" },
    { ciudad: "CALOTO", departamento: "CAUCA", codigo: "19142000" },
    { ciudad: "CAMBAO", departamento: "TOLIMA", codigo: "25662001" },
    { ciudad: "CAMPAMENTO", departamento: "ANTIOQUIA", codigo: "05134000" },
    { ciudad: "CAMPO DE LA CRUZ", departamento: "ATLANTICO", codigo: "08137000" },
    { ciudad: "CAMPOALEGRE", departamento: "HUILA", codigo: "41132000" },
    { ciudad: "CAMPOHERMOSO", departamento: "BOYACA", codigo: "15135000" },
    { ciudad: "CANALETE", departamento: "CORDOBA", codigo: "23090000" },
    { ciudad: "CANASGORDAS", departamento: "ANTIOQUIA", codigo: "05138000" },
    { ciudad: "CANDELARIA", departamento: "ATLANTICO", codigo: "08141000" },
    { ciudad: "CANDELARIA", departamento: "VALLE DEL CAUCA", codigo: "76130000" },
    { ciudad: "CANTAGALLO", departamento: "BOLIVAR", codigo: "13160000" },
    { ciudad: "CAPARRAPI", departamento: "CUNDINAMARCA", codigo: "25148000" },
    { ciudad: "CAPELLANIA", departamento: "CUNDINAMARCA", codigo: "25288001" },
    { ciudad: "CAPITANEJO", departamento: "SANTANDER", codigo: "68147000" },
    { ciudad: "CAQUEZA", departamento: "CUNDINAMARCA", codigo: "25151000" },
    { ciudad: "CARACOLI", departamento: "ANTIOQUIA", codigo: "05142000" },
    { ciudad: "CARAMANTA", departamento: "ANTIOQUIA", codigo: "05145000" },
    { ciudad: "CARCASI", departamento: "SANTANDER", codigo: "68152000" },
    { ciudad: "CAREPA", departamento: "ANTIOQUIA", codigo: "05147000" },
    { ciudad: "CARMEN DE APICALA", departamento: "TOLIMA", codigo: "73148000" },
    { ciudad: "CARMEN DE CARUPA", departamento: "CUNDINAMARCA", codigo: "25154000" },
    { ciudad: "CARMEN DEL DARIEN", departamento: "CHOCO", codigo: "27150000" },
    { ciudad: "CAROLINA", departamento: "ANTIOQUIA", codigo: "05150000" },
    { ciudad: "CARTAGENA DEL CHAIRA", departamento: "CAQUETA", codigo: "18150000" },
    { ciudad: "CARTAGENA", departamento: "BOLIVAR", codigo: "13001000" },
    { ciudad: "CARTAGENA", departamento: "ZIPACON", codigo: "25898006" },
    { ciudad: "CARTAGO", departamento: "VALLE DEL CAUCA", codigo: "76147000" },
    { ciudad: "CARURU", departamento: "VAUPES", codigo: "97161000" },
    { ciudad: "CASABIANCA", departamento: "TOLIMA", codigo: "73152000" },
    { ciudad: "CASTILLA LA NUEVA", departamento: "META", codigo: "50150000" },
    { ciudad: "CASTILLA", departamento: "TOLIMA", codigo: "73217001" },
    { ciudad: "CAUCASIA", departamento: "ANTIOQUIA", codigo: "05154000" },
    { ciudad: "CENTRO", departamento: "SANTANDER", codigo: "68081001" },
    { ciudad: "CEPITA", departamento: "SANTANDER", codigo: "68160000" },
    { ciudad: "CERETE", departamento: "CORDOBA", codigo: "23162000" },
    { ciudad: "CERINZA", departamento: "BOYACA", codigo: "15162000" },
    { ciudad: "CERRITO", departamento: "SANTANDER", codigo: "68162000" },
    { ciudad: "CERRO SAN ANTONIO", departamento: "MAGDALENA", codigo: "47161000" },
    { ciudad: "CERTEGUI", departamento: "CHOCO", codigo: "27160000" },
    { ciudad: "CHACHAGUI", departamento: "NARIÑO", codigo: "52240000" },
    { ciudad: "CHAGUANI", departamento: "CUNDINAMARCA", codigo: "25168000" },
    { ciudad: "CHALAN", departamento: "SUCRE", codigo: "70230000" },
    { ciudad: "CHAMEZA", departamento: "CASANARE", codigo: "85015000" },
    { ciudad: "CHAPARRAL", departamento: "TOLIMA", codigo: "73168000" },
    { ciudad: "CHARALA", departamento: "SANTANDER", codigo: "68167000" },
    { ciudad: "CHARTA", departamento: "SANTANDER", codigo: "68169000" },
    { ciudad: "CHIA", departamento: "CUNDINAMARCA", codigo: "25175000" },
    { ciudad: "CHIBOLO", departamento: "MAGDALENA", codigo: "47170000" },
    { ciudad: "CHICORAL", departamento: "TOLIMA", codigo: "73268001" },
    { ciudad: "CHIFLAS", departamento: "SANTANDER", codigo: "68051001" },
    { ciudad: "CHIGORODO", departamento: "ANTIOQUIA", codigo: "05172000" },
    { ciudad: "CHIMA", departamento: "CORDOBA", codigo: "23168000" },
    { ciudad: "CHIMA", departamento: "SANTANDER", codigo: "68176000" },
    { ciudad: "CHIMICHAGUA", departamento: "CESAR", codigo: "20175000" },
    { ciudad: "CHINACOTA", departamento: "NORTE DE SANTANDER", codigo: "54172000" },
    { ciudad: "CHINAVITA", departamento: "BOYACA", codigo: "15172000" },
    { ciudad: "CHINCHINA", departamento: "CALDAS", codigo: "17174000" },
    { ciudad: "CHINU", departamento: "CORDOBA", codigo: "23182000" },
    { ciudad: "CHIPAQUE", departamento: "CUNDINAMARCA", codigo: "25178000" },
    { ciudad: "CHIPATA", departamento: "SANTANDER", codigo: "68179000" },
    { ciudad: "CHIQUINQUIRA", departamento: "BOYACA", codigo: "15176000" },
    { ciudad: "CHIQUIZA", departamento: "BOYACA", codigo: "15232000" },
    { ciudad: "CHIQUIZA", departamento: "RIOSUCIO", codigo: "17614004" },
    { ciudad: "CHIRIGUANA", departamento: "CESAR", codigo: "20178000" },
    { ciudad: "CHISCAS", departamento: "BOYACA", codigo: "15180000" },
    { ciudad: "CHITA", departamento: "BOYACA", codigo: "15183000" },
    { ciudad: "CHITAGA", departamento: "NORTE DE SANTANDER", codigo: "54174000" },
    { ciudad: "CHITARAQUE", departamento: "BOYACA", codigo: "15185000" },
    { ciudad: "CHIVATA", departamento: "BOYACA", codigo: "15187000" },
    { ciudad: "CHIVOR", departamento: "BOYACA", codigo: "15236000" },
    { ciudad: "CHOACHI", departamento: "CUNDINAMARCA", codigo: "25181000" },
    { ciudad: "CHOCONTA", departamento: "CUNDINAMARCA", codigo: "25183000" },
    { ciudad: "CICUCO", departamento: "BOLIVAR", codigo: "13188000" },
    { ciudad: "CIENAGA DE ORO", departamento: "CORDOBA", codigo: "23189000" },
    { ciudad: "CIENAGA", departamento: "MAGDALENA", codigo: "47189000" },
    { ciudad: "CIENEGA", departamento: "BOYACA", codigo: "15189000" },
    { ciudad: "CIMITARRA", departamento: "SANTANDER", codigo: "68190000" },
    { ciudad: "CINCELADA", departamento: "COROMORO", codigo: "68217001" },
    { ciudad: "CIRCASIA", departamento: "QUINDIO", codigo: "63190000" },
    { ciudad: "CISNEROS", departamento: "ANTIOQUIA", codigo: "05190000" },
    { ciudad: "CITE", departamento: "BARBOSA", codigo: "68077001" },
    { ciudad: "CIUDAD BOLIVAR", departamento: "ANTIOQUIA", codigo: "05101000" },
    { ciudad: "CLEMENCIA", departamento: "BOLIVAR", codigo: "13222000" },
    { ciudad: "COCORNA", departamento: "ANTIOQUIA", codigo: "05197000" },
    { ciudad: "COELLO", departamento: "TOLIMA", codigo: "73200000" },
    { ciudad: "COGUA", departamento: "CUNDINAMARCA", codigo: "25200000" },
    { ciudad: "COLOMBIA", departamento: "HUILA", codigo: "41206000" },
    { ciudad: "COLON", departamento: "NARIÑO", codigo: "52203000" },
    { ciudad: "COLON", departamento: "PUTUMAYO", codigo: "86219000" },
    { ciudad: "COLOSO", departamento: "SUCRE", codigo: "70204000" },
    { ciudad: "COMBITA", departamento: "BOYACA", codigo: "15204000" },
    { ciudad: "CONCEPCION", departamento: "ANTIOQUIA", codigo: "05206000" },
    { ciudad: "CONCEPCION", departamento: "SANTANDER", codigo: "68207000" },
    { ciudad: "CONCORDIA", departamento: "ANTIOQUIA", codigo: "05209000" },
    { ciudad: "CONCORDIA", departamento: "MAGDALENA", codigo: "47205000" },
    { ciudad: "CONDOTO", departamento: "CHOCO", codigo: "27205000" },
    { ciudad: "CONFINES", departamento: "SANTANDER", codigo: "68209000" },
    { ciudad: "CONSACA", departamento: "NARIÑO", codigo: "52207000" },
    { ciudad: "CONTADERO", departamento: "NARIÑO", codigo: "52210000" },
    { ciudad: "CONTRATACION", departamento: "SANTANDER", codigo: "68211000" },
    { ciudad: "CONVENCION", departamento: "NORTE DE SANTANDER", codigo: "54206000" },
    { ciudad: "COPACABANA", departamento: "ANTIOQUIA", codigo: "05212000" },
    { ciudad: "COPER", departamento: "BOYACA", codigo: "15212000" },
    { ciudad: "CORDOBA", departamento: "BOLIVAR", codigo: "13212000" },
    { ciudad: "CORDOBA", departamento: "NARIÑO", codigo: "52215000" },
    { ciudad: "CORDOBA", departamento: "QUINDIO", codigo: "63212000" },
    { ciudad: "CORINTO", departamento: "CAUCA", codigo: "19212000" },
    { ciudad: "COROMORO", departamento: "SANTANDER", codigo: "68217000" },
    { ciudad: "COROZAL", departamento: "SUCRE", codigo: "70215000" },
    { ciudad: "CORRALES", departamento: "BOYACA", codigo: "15215000" },
    { ciudad: "COTA", departamento: "CUNDINAMARCA", codigo: "25214000" },
    { ciudad: "COTORRA", departamento: "CORDOBA", codigo: "23300000" },
    { ciudad: "COVARACHIA", departamento: "BOYACA", codigo: "15218000" },
    { ciudad: "COVEÑAS", departamento: "SUCRE", codigo: "70221000" },
    { ciudad: "COYAIMA", departamento: "TOLIMA", codigo: "73217000" },
    { ciudad: "CRAVO NORTE", departamento: "ARAUCA", codigo: "81220000" },
    { ciudad: "CUASPUD", departamento: "NARIÑO", codigo: "52224000" },
    { ciudad: "CUBARA", departamento: "BOYACA", codigo: "15223000" },
    { ciudad: "CUBARRAL", departamento: "META", codigo: "50223000" },
    { ciudad: "CUCAITA", departamento: "BOYACA", codigo: "15224000" },
    { ciudad: "CUCUNUBA", departamento: "CUNDINAMARCA", codigo: "25224000" },
    { ciudad: "CUCUTA", departamento: "NORTE DE SANTANDER", codigo: "54001000" },
    { ciudad: "CUCUTILLA", departamento: "NORTE DE SANTANDER", codigo: "54223000" },
    { ciudad: "CUITIVA", departamento: "BOYACA", codigo: "15226000" },
    { ciudad: "CUMARAL", departamento: "META", codigo: "50226000" },
    { ciudad: "CUMARIBO", departamento: "VICHADA", codigo: "99773000" },
    { ciudad: "CUMBAL", departamento: "NARIÑO", codigo: "52227000" },
    { ciudad: "CUMBITARA", departamento: "NARIÑO", codigo: "52233000" },
    { ciudad: "CUNDAY", departamento: "TOLIMA", codigo: "73226000" },
    { ciudad: "CURILLO", departamento: "CAQUETA", codigo: "18205000" },
    { ciudad: "CURITI", departamento: "SANTANDER", codigo: "68229000" },
    { ciudad: "CURRULAO", departamento: "ANTIOQUIA", codigo: "05837001" },
    { ciudad: "CURUMANI", departamento: "CESAR", codigo: "20228000" },
    { ciudad: "DABEIBA", departamento: "ANTIOQUIA", codigo: "05234000" },
    { ciudad: "DAGUA", departamento: "VALLE DEL CAUCA", codigo: "76233000" },
    { ciudad: "DEPT. RUSIA", departamento: "RUSIA", codigo: "01000071" },
    { ciudad: "DIBULLA", departamento: "LA GUAJIRA", codigo: "44090000" },
    { ciudad: "DISTRACCION", departamento: "LA GUAJIRA", codigo: "44098000" },
    { ciudad: "DOLORES", departamento: "TOLIMA", codigo: "73236000" },
    { ciudad: "DON MATIAS", departamento: "ANTIOQUIA", codigo: "05237000" },
    { ciudad: "DORADAL", departamento: "ANTIOQUIA", codigo: "05591004" },
    { ciudad: "DOSQUEBRADAS", departamento: "RISARALDA", codigo: "66170000" },
    { ciudad: "DUITAMA", departamento: "BOYACA", codigo: "15238000" },
    { ciudad: "DURANIA", departamento: "NORTE DE SANTANDER", codigo: "54239000" },
    { ciudad: "EBEJICO", departamento: "ANTIOQUIA", codigo: "05240000" },
    { ciudad: "EL AGUILA", departamento: "VALLE DEL CAUCA", codigo: "76243000" },
    { ciudad: "EL BAGRE", departamento: "ANTIOQUIA", codigo: "05250000" },
    { ciudad: "EL BANCO", departamento: "MAGDALENA", codigo: "47245000" },
    { ciudad: "EL CAGUAN", departamento: "HUILA", codigo: "41001001" },
    { ciudad: "EL CAIRO", departamento: "VALLE DEL CAUCA", codigo: "76246000" },
    { ciudad: "EL CALVARIO", departamento: "META", codigo: "50245000" },
    { ciudad: "EL CANTON DEL SAN PABLO", departamento: "CHOCO", codigo: "27135000" },
    { ciudad: "EL CARMEN DE ATRATO", departamento: "CHOCO", codigo: "27245000" },
    { ciudad: "EL CARMEN DE BOLIVAR", departamento: "BOLIVAR", codigo: "13244000" },
    { ciudad: "EL CARMEN DE CHUCURI", departamento: "SANTANDER", codigo: "68235000" },
    { ciudad: "EL CARMEN DE VIBORAL", departamento: "ANTIOQUIA", codigo: "05148000" },
    { ciudad: "EL CARMEN", departamento: "NORTE DE SANTANDER", codigo: "54245000" },
    { ciudad: "EL CASTILLO", departamento: "META", codigo: "50251000" },
    { ciudad: "EL CERRITO", departamento: "VALLE DEL CAUCA", codigo: "76248000" },
    { ciudad: "EL CHARCO", departamento: "NARIÑO", codigo: "52250000" },
    { ciudad: "EL COCUY", departamento: "BOYACA", codigo: "15244000" },
    { ciudad: "EL COLEGIO", departamento: "CUNDINAMARCA", codigo: "25245000" },
    { ciudad: "EL COPEY", departamento: "CESAR", codigo: "20238000" },
    { ciudad: "EL DONCELLO", departamento: "CAQUETA", codigo: "18247000" },
    { ciudad: "EL DORADO", departamento: "META", codigo: "50270000" },
    { ciudad: "EL DOVIO", departamento: "VALLE DEL CAUCA", codigo: "76250000" },
    { ciudad: "EL ENCANTO", departamento: "AMAZONAS", codigo: "91263000" },
    { ciudad: "EL ESPINO", departamento: "BOYACA", codigo: "15248000" },
    { ciudad: "EL GUACAMAYO", departamento: "SANTANDER", codigo: "68245000" },
    { ciudad: "EL GUAMO", departamento: "BOLIVAR", codigo: "13248000" },
    { ciudad: "EL JUNCAL", departamento: "HUILA", codigo: "41524006" },
    { ciudad: "EL MOLINO", departamento: "LA GUAJIRA", codigo: "44110000" },
    { ciudad: "EL PASO", departamento: "CESAR", codigo: "20250000" },
    { ciudad: "EL PAUJIL", departamento: "CAQUETA", codigo: "18256000" },
    { ciudad: "EL PEÑOL", departamento: "NARIÑO", codigo: "52254000" },
    { ciudad: "EL PENON", departamento: "BOLIVAR", codigo: "13268000" },
    { ciudad: "EL PENON", departamento: "CUNDINAMARCA", codigo: "25258000" },
    { ciudad: "EL PENON", departamento: "SANTANDER", codigo: "68250000" },
    { ciudad: "EL PINON", departamento: "MAGDALENA", codigo: "47258000" },
    { ciudad: "EL PLACER", departamento: "PUTUMAYO", codigo: "86865004" },
    { ciudad: "EL PLATEADO", departamento: "ARGELIA", codigo: "19050006" },
    { ciudad: "EL PLAYON", departamento: "SANTANDER", codigo: "68255000" },
    { ciudad: "EL RETEN", departamento: "MAGDALENA", codigo: "47268000" },
    { ciudad: "EL RETORNO", departamento: "GUAVIARE", codigo: "95025000" },
    { ciudad: "EL ROBLE", departamento: "SUCRE", codigo: "70233000" },
    { ciudad: "EL ROSAL", departamento: "CUNDINAMARCA", codigo: "25260000" },
    { ciudad: "EL ROSARIO", departamento: "NARIÑO", codigo: "52256000" },
    { ciudad: "EL SANTUARIO", departamento: "ANTIOQUIA", codigo: "05697000" },
    { ciudad: "EL TABLAZO", departamento: "TOLIMA", codigo: "73283003" },
    { ciudad: "EL TABLON DE GOMEZ", departamento: "NARIÑO", codigo: "52258000" },
    { ciudad: "EL TAMBO", departamento: "CAUCA", codigo: "19256000" },
    { ciudad: "EL TAMBO", departamento: "NARIÑO", codigo: "52260000" },
    { ciudad: "EL TARRA", departamento: "NORTE DE SANTANDER", codigo: "54250000" },
    { ciudad: "EL TRIUNFO", departamento: "CUNDINAMARCA", codigo: "25245001" },
    { ciudad: "EL ZULIA", departamento: "NORTE DE SANTANDER", codigo: "54261000" },
    { ciudad: "ELIAS", departamento: "HUILA", codigo: "41244000" },
    { ciudad: "ENCINO", departamento: "SANTANDER", codigo: "68264000" },
    { ciudad: "ENCISO", departamento: "SANTANDER", codigo: "68266000" },
    { ciudad: "ENTRERRIOS", departamento: "ANTIOQUIA", codigo: "05264000" },
    { ciudad: "ENVIGADO", departamento: "ANTIOQUIA", codigo: "05266000" },
    { ciudad: "ESPINAL", departamento: "TOLIMA", codigo: "73268000" },
    { ciudad: "FACATATIVA", departamento: "CUNDINAMARCA", codigo: "25269000" },
    { ciudad: "FALAN", departamento: "TOLIMA", codigo: "73270000" },
    { ciudad: "FILADELFIA", departamento: "CALDAS", codigo: "17272000" },
    { ciudad: "FILANDIA", departamento: "QUINDIO", codigo: "63272000" },
    { ciudad: "FIRAVITOBA", departamento: "BOYACA", codigo: "15272000" },
    { ciudad: "FLANDES", departamento: "TOLIMA", codigo: "73275000" },
    { ciudad: "FLORENCIA", departamento: "CAUCA", codigo: "19290000" },
    { ciudad: "FLORENCIA", departamento: "CAQUETA", codigo: "18001000" },
    { ciudad: "FLORESTA", departamento: "BOYACA", codigo: "15276000" },
    { ciudad: "FLORIAN", departamento: "SANTANDER", codigo: "68271000" },
    { ciudad: "FLORIDA", departamento: "VALLE DEL CAUCA", codigo: "76275000" },
    { ciudad: "FLORIDABLANCA", departamento: "SANTANDER", codigo: "68276000" },
    { ciudad: "FOMEQUE", departamento: "CUNDINAMARCA", codigo: "25279000" },
    { ciudad: "FONSECA", departamento: "LA GUAJIRA", codigo: "44279000" },
    { ciudad: "FORTALECILLAS", departamento: "HUILA", codigo: "41001003" },
    { ciudad: "FORTUL", departamento: "ARAUCA", codigo: "81300000" },
    { ciudad: "FOSCA", departamento: "CUNDINAMARCA", codigo: "25281000" },
    { ciudad: "FRANCISCO PIZARRO", departamento: "NARIÑO", codigo: "52520000" },
    { ciudad: "FREDONIA", departamento: "ANTIOQUIA", codigo: "05282000" },
    { ciudad: "FRESNO", departamento: "TOLIMA", codigo: "73283000" },
    { ciudad: "FRONTINO", departamento: "ANTIOQUIA", codigo: "05284000" },
    { ciudad: "FUENTE DE ORO", departamento: "META", codigo: "50287000" },
    { ciudad: "FUNDACION", departamento: "MAGDALENA", codigo: "47288000" },
    { ciudad: "FUNES", departamento: "NARIÑO", codigo: "52287000" },
    { ciudad: "FUNZA", departamento: "CUNDINAMARCA", codigo: "25286000" },
    { ciudad: "FUQUENE", departamento: "CUNDINAMARCA", codigo: "25288000" },
    { ciudad: "FUSAGASUGA", departamento: "CUNDINAMARCA", codigo: "25290000" },
    { ciudad: "GACHALA", departamento: "CUNDINAMARCA", codigo: "25293000" },
    { ciudad: "GACHANCIPA", departamento: "CUNDINAMARCA", codigo: "25295000" },
    { ciudad: "GACHANTIVA", departamento: "BOYACA", codigo: "15293000" },
    { ciudad: "GACHETA", departamento: "CUNDINAMARCA", codigo: "25297000" },
    { ciudad: "GAITANIA", departamento: "TOLIMA", codigo: "73555002" },
    { ciudad: "GALAN", departamento: "SANTANDER", codigo: "68296000" },
    { ciudad: "GALAPA", departamento: "ATLANTICO", codigo: "08296000" },
    { ciudad: "GALERAS", departamento: "SUCRE", codigo: "70235000" },
    { ciudad: "GAMA", departamento: "CUNDINAMARCA", codigo: "25299000" },
    { ciudad: "GAMARRA", departamento: "CESAR", codigo: "20295000" },
    { ciudad: "GAMBITA", departamento: "SANTANDER", codigo: "68298000" },
    { ciudad: "GAMEZA", departamento: "BOYACA", codigo: "15296000" },
    { ciudad: "GARAGOA", departamento: "BOYACA", codigo: "15299000" },
    { ciudad: "GARZON", departamento: "HUILA", codigo: "41298000" },
    { ciudad: "GENOVA", departamento: "QUINDIO", codigo: "63302000" },
    { ciudad: "GIGANTE", departamento: "HUILA", codigo: "41306000" },
    { ciudad: "GINEBRA", departamento: "VALLE DEL CAUCA", codigo: "76306000" },
    { ciudad: "GIRALDO", departamento: "ANTIOQUIA", codigo: "05306000" },
    { ciudad: "GIRARDOT", departamento: "CUNDINAMARCA", codigo: "25307000" },
    { ciudad: "GIRARDOTA", departamento: "ANTIOQUIA", codigo: "05308000" },
    { ciudad: "GIRON", departamento: "SANTANDER", codigo: "68307000" },
    { ciudad: "GOMEZ PLATA", departamento: "ANTIOQUIA", codigo: "05310000" },
    { ciudad: "GONZALEZ ", departamento: "TEORAMA", codigo: "54800027" },
    { ciudad: "GONZALEZ", departamento: "CESAR", codigo: "20310000" },
    { ciudad: "GRAMALOTE", departamento: "NORTE DE SANTANDER", codigo: "54313000" },
    { ciudad: "GRANADA", departamento: "ANTIOQUIA", codigo: "05313000" },
    { ciudad: "GRANADA", departamento: "CUNDINAMARCA", codigo: "25312000" },
    { ciudad: "GRANADA", departamento: "META", codigo: "50313000" },
    { ciudad: "GUACA", departamento: "SANTANDER", codigo: "68318000" },
    { ciudad: "GUACAMAYAS", departamento: "BOYACA", codigo: "15317000" },
    { ciudad: "GUACARI", departamento: "VALLE DEL CAUCA", codigo: "76318000" },
    { ciudad: "GUACHENE", departamento: "CAUCA", codigo: "19300000" },
    { ciudad: "GUACHETA", departamento: "CUNDINAMARCA", codigo: "25317000" },
    { ciudad: "GUACHUCAL", departamento: "NARIÑO", codigo: "52317000" },
    { ciudad: "GUADALUPE", departamento: "ANTIOQUIA", codigo: "05315000" },
    { ciudad: "GUADALUPE", departamento: "HUILA", codigo: "41319000" },
    { ciudad: "GUADALUPE", departamento: "SANTANDER", codigo: "68320000" },
    { ciudad: "GUADUAS", departamento: "CUNDINAMARCA", codigo: "25320000" },
    { ciudad: "GUAITARILLA", departamento: "NARIÑO", codigo: "52320000" },
    { ciudad: "GUALANDAY", departamento: "TOLIMA", codigo: "73200001" },
    { ciudad: "GUALMATAN", departamento: "NARIÑO", codigo: "52323000" },
    { ciudad: "GUAMAL", departamento: "MAGDALENA", codigo: "47318000" },
    { ciudad: "GUAMAL", departamento: "META", codigo: "50318000" },
    { ciudad: "GUAMALITO", departamento: "EL CARMEN", codigo: "54245006" },
    { ciudad: "GUAMO", departamento: "TOLIMA", codigo: "73319000" },
    { ciudad: "GUAPI", departamento: "CAUCA", codigo: "19318000" },
    { ciudad: "GUAPOTA", departamento: "SANTANDER", codigo: "68322000" },
    { ciudad: "GUARANDA", departamento: "SUCRE", codigo: "70265000" },
    { ciudad: "GUARNE", departamento: "ANTIOQUIA", codigo: "05318000" },
    { ciudad: "GUASCA", departamento: "CUNDINAMARCA", codigo: "25322000" },
    { ciudad: "GUATAPE", departamento: "ANTIOQUIA", codigo: "05321000" },
    { ciudad: "GUATAQUI", departamento: "CUNDINAMARCA", codigo: "25324000" },
    { ciudad: "GUATAVITA", departamento: "CUNDINAMARCA", codigo: "25326000" },
    { ciudad: "GUATEQUE", departamento: "BOYACA", codigo: "15322000" },
    { ciudad: "GUATICA", departamento: "RISARALDA", codigo: "66318000" },
    { ciudad: "GUAVATA", departamento: "SANTANDER", codigo: "68324000" },
    { ciudad: "GUAYABAL DE SIQUIMA", departamento: "CUNDINAMARCA", codigo: "25328000" },
    { ciudad: "GUAYABETAL", departamento: "CUNDINAMARCA", codigo: "25335000" },
    { ciudad: "GUAYATA", departamento: "BOYACA", codigo: "15325000" },
    { ciudad: "GUEPSA", departamento: "SANTANDER", codigo: "68327000" },
    { ciudad: "GUICAN", departamento: "BOYACA", codigo: "15332000" },
    { ciudad: "GUTIERREZ", departamento: "CUNDINAMARCA", codigo: "25339000" },
    { ciudad: "HACARI", departamento: "NORTE DE SANTANDER", codigo: "54344000" },
    { ciudad: "HATILLO DE LOBA", departamento: "BOLIVAR", codigo: "13300000" },
    { ciudad: "HATO COROZAL", departamento: "CASANARE", codigo: "85125000" },
    { ciudad: "HATO", departamento: "SANTANDER", codigo: "68344000" },
    { ciudad: "HATONUEVO", departamento: "LA GUAJIRA", codigo: "44378000" },
    { ciudad: "HELICONIA", departamento: "ANTIOQUIA", codigo: "05347000" },
    { ciudad: "HERRAN", departamento: "NORTE DE SANTANDER", codigo: "54347000" },
    { ciudad: "HERRERA", departamento: "RIOBLANCO", codigo: "73616001" },
    { ciudad: "HERVEO", departamento: "TOLIMA", codigo: "73347000" },
    { ciudad: "HISPANIA", departamento: "ANTIOQUIA", codigo: "05353000" },
    { ciudad: "HOBO", departamento: "HUILA", codigo: "41349000" },
    { ciudad: "HONDA", departamento: "TOLIMA", codigo: "73349000" },
    { ciudad: "IBAGUE", departamento: "TOLIMA", codigo: "73001000" },
    { ciudad: "ICONONZO", departamento: "TOLIMA", codigo: "73352000" },
    { ciudad: "ILES", departamento: "NARIÑO", codigo: "52352000" },
    { ciudad: "IMUES", departamento: "NARIÑO", codigo: "52354000" },
    { ciudad: "INIRIDA", departamento: "GUAINIA", codigo: "94001000" },
    { ciudad: "INZA", departamento: "CAUCA", codigo: "19355000" },
    { ciudad: "IPIALES", departamento: "NARIÑO", codigo: "52356000" },
    { ciudad: "IQUIRA", departamento: "HUILA", codigo: "41357000" },
    { ciudad: "ISNOS", departamento: "HUILA", codigo: "41359000" },
    { ciudad: "ISTMINA", departamento: "CHOCO", codigo: "27361000" },
    { ciudad: "ITAGUI", departamento: "ANTIOQUIA", codigo: "05360000" },
    { ciudad: "ITUANGO", departamento: "ANTIOQUIA", codigo: "05361000" },
    { ciudad: "IZA", departamento: "BOYACA", codigo: "15362000" },
    { ciudad: "JAMBALO", departamento: "CAUCA", codigo: "19364000" },
    { ciudad: "JAMUNDI", departamento: "VALLE DEL CAUCA", codigo: "76364000" },
    { ciudad: "JARDIN", departamento: "ANTIOQUIA", codigo: "05364000" },
    { ciudad: "JENESANO", departamento: "BOYACA", codigo: "15367000" },
    { ciudad: "JERICO", departamento: "ANTIOQUIA", codigo: "05368000" },
    { ciudad: "JERICO", departamento: "BOYACA", codigo: "15368000" },
    { ciudad: "JERUSALEN", departamento: "CUNDINAMARCA", codigo: "25368000" },
    { ciudad: "JESUS MARIA", departamento: "SANTANDER", codigo: "68368000" },
    { ciudad: "JORDAN", departamento: "SANTANDER", codigo: "68370000" },
    { ciudad: "JUAN DE ACOSTA", departamento: "ATLANTICO", codigo: "08372000" },
    { ciudad: "JUNIN", departamento: "CUNDINAMARCA", codigo: "25372000" },
    { ciudad: "JUNIN", departamento: "VENADILLO", codigo: "73861001" },
    { ciudad: "JURADO", departamento: "CHOCO", codigo: "27372000" },
    { ciudad: "LA APARTADA", departamento: "CORDOBA", codigo: "23350000" },
    { ciudad: "LA ARADA", departamento: "TOLIMA", codigo: "73024001" },
    { ciudad: "LA ARGENTINA", departamento: "HUILA", codigo: "41378000" },
    { ciudad: "LA BELLEZA", departamento: "SANTANDER", codigo: "68377000" },
    { ciudad: "LA CALERA", departamento: "CUNDINAMARCA", codigo: "25377000" },
    { ciudad: "LA CAPILLA", departamento: "BOYACA", codigo: "15380000" },
    { ciudad: "LA CEJA", departamento: "ANTIOQUIA", codigo: "05376000" },
    { ciudad: "LA CELIA", departamento: "RISARALDA", codigo: "66383000" },
    { ciudad: "LA CHAMBA", departamento: "GUAMO", codigo: "73319002" },
    { ciudad: "LA CHAPARRERA", departamento: "YOPAL", codigo: "85001002" },
    { ciudad: "LA CHORRERA", departamento: "LETICIA", codigo: "91405000" },
    { ciudad: "LA CRUZ", departamento: "NARIÑO", codigo: "52378000" },
    { ciudad: "LA CUMBRE", departamento: "VALLE DEL CAUCA", codigo: "76377000" },
    { ciudad: "LA DONJUANA", departamento: "BOCHALEMA", codigo: "54099002" },
    { ciudad: "LA DORADA", departamento: "CALDAS", codigo: "17380000" },
    { ciudad: "LA ESMERALDA", departamento: "ARAUQUITA", codigo: "81065008" },
    { ciudad: "LA ESPERANZA", departamento: "NORTE DE SANTANDER", codigo: "54385000" },
    { ciudad: "LA ESTRELLA", departamento: "ANTIOQUIA", codigo: "05380000" },
    { ciudad: "LA FLORIDA", departamento: "NARIÑO", codigo: "52381000" },
    { ciudad: "LA GABARRA", departamento: "TIBU", codigo: "54810002" },
    { ciudad: "LA GRAN VÍA - ZONA BANANERA", departamento: "ZONA BANANERA", codigo: "47980003" },
    { ciudad: "LA GRAN VÌA", departamento: "CUNDINAMARCA", codigo: "25797001" },
    { ciudad: "LA GUADALUPE", departamento: "INIRIDA", codigo: "94885000" },
    { ciudad: "LA JAGUA DE IBIRICO", departamento: "CESAR", codigo: "20400000" },
    { ciudad: "LA JAGUA DEL PILAR", departamento: "LA GUAJIRA", codigo: "44420000" },
    { ciudad: "LA JAGUA", departamento: "GARZON", codigo: "41298002" },
    { ciudad: "LA JULIA", departamento: "META", codigo: "50370001" },
    { ciudad: "LA LIBERTAD", departamento: "EL RETORNO", codigo: "95025001" },
    { ciudad: "LA LLANADA", departamento: "NARIÑO", codigo: "52385000" },
    { ciudad: "LA GLORIA", departamento: "CESAR", codigo: "20383000" },
    { ciudad: "LA MACARENA", departamento: "META", codigo: "50350000" },
    { ciudad: "LA MERCED", departamento: "CALDAS", codigo: "17388000" },
    { ciudad: "LA MESA", departamento: "CUNDINAMARCA", codigo: "25386000" },
    { ciudad: "LA MONTANITA", departamento: "CAQUETA", codigo: "18410000" },
    { ciudad: "LA PAILA", departamento: "ZARZAL", codigo: "76895002" },
    { ciudad: "LA PALMA", departamento: "CUNDINAMARCA", codigo: "25394000" },
    { ciudad: "LA PALMA", departamento: "GAMBITA", codigo: "68298002" },
    { ciudad: "LA PAZ", departamento: "CESAR", codigo: "20621000" },
    { ciudad: "LA PAZ", departamento: "SANTANDER", codigo: "68397000" },
    { ciudad: "LA PEDRERA", departamento: "LETICIA", codigo: "91407000" },
    { ciudad: "LA PENA", departamento: "CUNDINAMARCA", codigo: "25398000" },
    { ciudad: "LA PINTADA", departamento: "ANTIOQUIA", codigo: "05390000" },
    { ciudad: "LA PLATA", departamento: "HUILA", codigo: "41396000" },
    { ciudad: "LA PLAYA", departamento: "NORTE DE SANTANDER", codigo: "54398000" },
    { ciudad: "LA PRIMAVERA", departamento: "VICHADA", codigo: "99524000" },
    { ciudad: "LA PUNTA", departamento: "TENJO", codigo: "25799001" },
    { ciudad: "LA SALINA", departamento: "CASANARE", codigo: "85136000" },
    { ciudad: "LA SIERRA", departamento: "CAUCA", codigo: "19392000" },
    { ciudad: "LA SIERRA", departamento: "LERIDA", codigo: "73408002" },
    { ciudad: "LA TEBAIDA", departamento: "QUINDIO", codigo: "63401000" },
    { ciudad: "LA TOLA", departamento: "NARIÑO", codigo: "52390000" },
    { ciudad: "LA ULLOA", departamento: "RIVERA", codigo: "41615001" },
    { ciudad: "LA UNION", departamento: "ANTIOQUIA", codigo: "05400000" },
    { ciudad: "LA UNION", departamento: "NARIÑO", codigo: "52399000" },
    { ciudad: "LA UNION", departamento: "SUCRE", codigo: "70400000" },
    { ciudad: "LA UNION", departamento: "VALLE DEL CAUCA", codigo: "76400000" },
    { ciudad: "LA UVITA", departamento: "BOYACA", codigo: "15403000" },
    { ciudad: "LA VEGA", departamento: "CAUCA", codigo: "19397000" },
    { ciudad: "LA VEGA", departamento: "CUNDINAMARCA", codigo: "25402000" },
    { ciudad: "LA VICTORIA PACOA", departamento: "LA VICTORIA PACOA", codigo: "91430000" },
    { ciudad: "LA VICTORIA", departamento: "BOYACA", codigo: "15401000" },
    { ciudad: "LA VICTORIA", departamento: "EL COLEGIO", codigo: "25245002" },
    { ciudad: "LA VICTORIA", departamento: "VALLE DEL CAUCA", codigo: "76403000" },
    { ciudad: "LA VIRGINIA", departamento: "RISARALDA", codigo: "66400000" },
    { ciudad: "LABATECA", departamento: "NORTE DE SANTANDER", codigo: "54377000" },
    { ciudad: "LABRANZAGRANDE", departamento: "BOYACA", codigo: "15377000" },
    { ciudad: "LANDAZURI", departamento: "SANTANDER", codigo: "68385000" },
    { ciudad: "LAS MERCEDES (SARDINATA)", departamento: "SARDINATA", codigo: "54720004" },
    { ciudad: "LEBRIJA", departamento: "SANTANDER", codigo: "68406000" },
    { ciudad: "LEGUIZAMO", departamento: "PUTUMAYO", codigo: "86573000" },
    { ciudad: "LEIVA", departamento: "NARIÑO", codigo: "52405000" },
    { ciudad: "LEJANIAS", departamento: "META", codigo: "50400000" },
    { ciudad: "LENGUAZAQUE", departamento: "CUNDINAMARCA", codigo: "25407000" },
    { ciudad: "LERIDA", departamento: "TOLIMA", codigo: "73408000" },
    { ciudad: "LETICIA", departamento: "AMAZONAS", codigo: "91001000" },
    { ciudad: "LIBANO", departamento: "TOLIMA", codigo: "73411000" },
    { ciudad: "LIBORINA", departamento: "ANTIOQUIA", codigo: "05411000" },
    { ciudad: "LIMA", departamento: "PERU", codigo: "01000511" },
    { ciudad: "LINARES", departamento: "NARIÑO", codigo: "52411000" },
    { ciudad: "LITORAL BAJO SAN JUAN", departamento: "CHOCO", codigo: "27250000" },
    { ciudad: "LLANOS DE CUIVA", departamento: "SANTA ROSA DE OSOS", codigo: "05686011" },
    { ciudad: "LLORENTE", departamento: "NARIÑO", codigo: "52215002" },
    { ciudad: "LLORO", departamento: "CHOCO", codigo: "27413000" },
    { ciudad: "LOPEZ", departamento: "CAUCA", codigo: "19418000" },
    { ciudad: "LORICA", departamento: "CORDOBA", codigo: "23417000" },
    { ciudad: "LOS ANDES", departamento: "NARIÑO", codigo: "52418000" },
    { ciudad: "LOS CORDOBAS", departamento: "CORDOBA", codigo: "23419000" },
    { ciudad: "LOS PALMITOS", departamento: "SUCRE", codigo: "70418000" },
    { ciudad: "LOS PATIOS", departamento: "NORTE DE SANTANDER", codigo: "54405000" },
    { ciudad: "LOS SANTOS", departamento: "SANTANDER", codigo: "68418000" },
    { ciudad: "LOURDES", departamento: "NORTE DE SANTANDER", codigo: "54418000" },
    { ciudad: "LURUACO", departamento: "ATLANTICO", codigo: "08421000" },
    { ciudad: "MACANAL", departamento: "BOYACA", codigo: "15425000" },
    { ciudad: "MACARAVITA", departamento: "SANTANDER", codigo: "68425000" },
    { ciudad: "MACEO", departamento: "ANTIOQUIA", codigo: "05425000" },
    { ciudad: "MACHETA", departamento: "CUNDINAMARCA", codigo: "25426000" },
    { ciudad: "MADRID", departamento: "CUNDINAMARCA", codigo: "25430000" },
    { ciudad: "MADRIGAL", departamento: "POLICARPA", codigo: "52540002" },
    { ciudad: "MAGANGUE", departamento: "BOLIVAR", codigo: "13430000" },
    { ciudad: "MAGUI", departamento: "NARIÑO", codigo: "52427000" },
    { ciudad: "MAHATES", departamento: "BOLIVAR", codigo: "13433000" },
    { ciudad: "MAICAO", departamento: "LA GUAJIRA", codigo: "44430000" },
    { ciudad: "MAITO", departamento: "TARQUI", codigo: "41791002" },
    { ciudad: "MAJAGUAL", departamento: "SUCRE", codigo: "70429000" },
    { ciudad: "MALAGA", departamento: "SANTANDER", codigo: "68432000" },
    { ciudad: "MALAMBO", departamento: "ATLANTICO", codigo: "08433000" },
    { ciudad: "MALLAMA", departamento: "NARIÑO", codigo: "52435000" },
    { ciudad: "MANATI", departamento: "ATLANTICO", codigo: "08436000" },
    { ciudad: "MANAURE", departamento: "CESAR", codigo: "20443000" },
    { ciudad: "MANAURE", departamento: "LA GUAJIRA", codigo: "44560000" },
    { ciudad: "MANI", departamento: "CASANARE", codigo: "85139000" },
    { ciudad: "MANIZALES", departamento: "CALDAS", codigo: "17001000" },
    { ciudad: "MANTA", departamento: "CUNDINAMARCA", codigo: "25436000" },
    { ciudad: "MANZANARES", departamento: "CALDAS", codigo: "17433000" },
    { ciudad: "MAPIRIPAN", departamento: "META", codigo: "50325000" },
    { ciudad: "MAPIRIPANA", departamento: "INIRIDA", codigo: "94663000" },
    { ciudad: "MARGARITA", departamento: "BOLIVAR", codigo: "13440000" },
    { ciudad: "MARIA LA BAJA", departamento: "BOLIVAR", codigo: "13442000" },
    { ciudad: "MARINILLA", departamento: "ANTIOQUIA", codigo: "05440000" },
    { ciudad: "MARIPI", departamento: "BOYACA", codigo: "15442000" },
    { ciudad: "MARIQUITA", departamento: "TOLIMA", codigo: "73443000" },
    { ciudad: "MARMATO", departamento: "CALDAS", codigo: "17442000" },
    { ciudad: "MARQUETALIA", departamento: "CALDAS", codigo: "17444000" },
    { ciudad: "MARSELLA", departamento: "RISARALDA", codigo: "66440000" },
    { ciudad: "MARULANDA", departamento: "CALDAS", codigo: "17446000" },
    { ciudad: "MATANZA", departamento: "SANTANDER", codigo: "68444000" },
    { ciudad: "MAYA", departamento: "PARATEBUENO", codigo: "25530001" },
    { ciudad: "MEDELLIN", departamento: "ANTIOQUIA", codigo: "05001000" },
    { ciudad: "MEDINA", departamento: "CUNDINAMARCA", codigo: "25438000" },
    { ciudad: "MEDIO ATRATO", departamento: "CHOCO", codigo: "27425000" },
    { ciudad: "MEDIO BAUDO", departamento: "CHOCO", codigo: "27430000" },
    { ciudad: "MEDIO SAN JUAN", departamento: "CHOCO", codigo: "27450000" },
    { ciudad: "MELGAR", departamento: "TOLIMA", codigo: "73449000" },
    { ciudad: "MERCADERES", departamento: "CAUCA", codigo: "19450000" },
    { ciudad: "MESA DE LOS SANTOS ", departamento: "LOS SANTOS", codigo: "68547019" },
    { ciudad: "MESETAS", departamento: "META", codigo: "50330000" },
    { ciudad: "MIAMI", departamento: "EE.UU. Y CANADA", codigo: "10000101" },
    { ciudad: "MILAN", departamento: "CAQUETA", codigo: "18460000" },
    { ciudad: "MIRAFLORES", departamento: "BOYACA", codigo: "15455000" },
    { ciudad: "MIRAFLORES", departamento: "GUAVIARE", codigo: "95200000" },
    { ciudad: "MIRANDA", departamento: "CAUCA", codigo: "19455000" },
    { ciudad: "MIRITI - PARANA", departamento: "LETICIA", codigo: "91460000" },
    { ciudad: "MISTRATO", departamento: "RISARALDA", codigo: "66456000" },
    { ciudad: "MITU", departamento: "VAUPES", codigo: "97001000" },
    { ciudad: "MOCOA", departamento: "PUTUMAYO", codigo: "86001000" },
    { ciudad: "MOGOTES", departamento: "SANTANDER", codigo: "68464000" },
    { ciudad: "MOLAGAVITA", departamento: "SANTANDER", codigo: "68468000" },
    { ciudad: "MOMIL", departamento: "CORDOBA", codigo: "23464000" },
    { ciudad: "MOMPOS", departamento: "BOLIVAR", codigo: "13468000" },
    { ciudad: "MONGUA", departamento: "BOYACA", codigo: "15464000" },
    { ciudad: "MONGUI", departamento: "BOYACA", codigo: "15466000" },
    { ciudad: "MONIQUIRA", departamento: "BOYACA", codigo: "15469000" },
    { ciudad: "MONITOS", departamento: "CORDOBA", codigo: "23500000" },
    { ciudad: "MONTEBELLO", departamento: "ANTIOQUIA", codigo: "05467000" },
    { ciudad: "MONTECRISTO", departamento: "BOLIVAR", codigo: "13458000" },
    { ciudad: "MONTELIBANO", departamento: "CORDOBA", codigo: "23466000" },
    { ciudad: "MONTENEGRO", departamento: "QUINDIO", codigo: "63470000" },
    { ciudad: "MONTERIA", departamento: "CORDOBA", codigo: "23001000" },
    { ciudad: "MONTERREY", departamento: "CASANARE", codigo: "85162000" },
    { ciudad: "MORALES", departamento: "BOLIVAR", codigo: "13473000" },
    { ciudad: "MORALES", departamento: "CAUCA", codigo: "19473000" },
    { ciudad: "MORELIA", departamento: "CAQUETA", codigo: "18479000" },
    { ciudad: "MORICHAL", departamento: "INIRIDA", codigo: "94888000" },
    { ciudad: "MORROA", departamento: "SUCRE", codigo: "70473000" },
    { ciudad: "MOSCU", departamento: "DEPT. RUSIA", codigo: "10000711" },
    { ciudad: "MOSQUERA", departamento: "CUNDINAMARCA", codigo: "25473000" },
    { ciudad: "MOSQUERA", departamento: "NARIÑO", codigo: "52473000" },
    { ciudad: "MOTAVITA", departamento: "BOYACA", codigo: "15476000" },
    { ciudad: "MURILLO", departamento: "TOLIMA", codigo: "73461000" },
    { ciudad: "MURINDO", departamento: "ANTIOQUIA", codigo: "05475000" },
    { ciudad: "MUTATA", departamento: "ANTIOQUIA", codigo: "05480000" },
    { ciudad: "MUTISCUA", departamento: "NORTE DE SANTANDER", codigo: "54480000" },
    { ciudad: "MUZO", departamento: "BOYACA", codigo: "15480000" },
    { ciudad: "NARANJAL", departamento: "ROLDANILLO", codigo: "76622025" },
    { ciudad: "NARIÑO", departamento: "ANTIOQUIA", codigo: "05483000" },
    { ciudad: "NARIÑO", departamento: "CUNDINAMARCA", codigo: "25483000" },
    { ciudad: "NARIÑO", departamento: "NARIÑO", codigo: "52480000" },
    { ciudad: "NATAGA", departamento: "HUILA", codigo: "41483000" },
    { ciudad: "NATAGAIMA", departamento: "TOLIMA", codigo: "73483000" },
    { ciudad: "NAZARETH", departamento: "PARATEBUENO", codigo: "15491007" },
    { ciudad: "NECHI", departamento: "ANTIOQUIA", codigo: "05495000" },
    { ciudad: "NECOCLI", departamento: "ANTIOQUIA", codigo: "05490000" },
    { ciudad: "NEIRA", departamento: "CALDAS", codigo: "17486000" },
    { ciudad: "NEIVA", departamento: "HUILA", codigo: "41001000" },
    { ciudad: "NEMOCON", departamento: "CUNDINAMARCA", codigo: "25486000" },
    { ciudad: "NILO", departamento: "CUNDINAMARCA", codigo: "25488000" },
    { ciudad: "NIMAIMA", departamento: "CUNDINAMARCA", codigo: "25489000" },
    { ciudad: "NOBSA", departamento: "BOYACA", codigo: "15491000" },
    { ciudad: "NOCAIMA", departamento: "CUNDINAMARCA", codigo: "25491000" },
    { ciudad: "NORCASIA", departamento: "CALDAS", codigo: "17495000" },
    { ciudad: "NOROSI BOLIVAR", departamento: "BOLIVAR", codigo: "13490000" },
    { ciudad: "NOVITA", departamento: "CHOCO", codigo: "27491000" },
    { ciudad: "NUEVA GRANADA", departamento: "MAGDALENA", codigo: "47460000" },
    { ciudad: "NUEVO COLON", departamento: "BOYACA", codigo: "15494000" },
    { ciudad: "NUNCHIA", departamento: "CASANARE", codigo: "85225000" },
    { ciudad: "NUQUI", departamento: "CHOCO", codigo: "27495000" },
    { ciudad: "OBANDO", departamento: "VALLE DEL CAUCA", codigo: "76497000" },
    { ciudad: "OCAMONTE", departamento: "SANTANDER", codigo: "68498000" },
    { ciudad: "OCANA", departamento: "NORTE DE SANTANDER", codigo: "54498000" },
    { ciudad: "OIBA", departamento: "SANTANDER", codigo: "68500000" },
    { ciudad: "OICATA", departamento: "BOYACA", codigo: "15500000" },
    { ciudad: "OLAYA", departamento: "ANTIOQUIA", codigo: "05501000" },
    { ciudad: "OLIVAL", departamento: "SUAITA", codigo: "68770001" },
    { ciudad: "ONZAGA", departamento: "SANTANDER", codigo: "68502000" },
    { ciudad: "OPORAPA", departamento: "HUILA", codigo: "41503000" },
    { ciudad: "ORITO", departamento: "PUTUMAYO", codigo: "86320000" },
    { ciudad: "OROCUE", departamento: "CASANARE", codigo: "85230000" },
    { ciudad: "ORTEGA", departamento: "TOLIMA", codigo: "73504000" },
    { ciudad: "OSPINA", departamento: "NARIÑO", codigo: "52506000" },
    { ciudad: "OTANCHE", departamento: "BOYACA", codigo: "15507000" },
    { ciudad: "OVEJAS", departamento: "SUCRE", codigo: "70508000" },
    { ciudad: "PACARNI", departamento: "TESALIA", codigo: "41797001" },
    { ciudad: "PACHAQUIARO", departamento: "PUERTO LOPEZ", codigo: "50573003" },
    { ciudad: "PACHAVITA", departamento: "BOYACA", codigo: "15511000" },
    { ciudad: "PACHO", departamento: "CUNDINAMARCA", codigo: "25513000" },
    { ciudad: "PACOA", departamento: "MITU", codigo: "97511000" },
    { ciudad: "PACORA", departamento: "CALDAS", codigo: "17513000" },
    { ciudad: "PADILLA", departamento: "CAUCA", codigo: "19513000" },
    { ciudad: "PADUA", departamento: "TOLIMA", codigo: "73347005" },
    { ciudad: "PAEZ", departamento: "BOYACA", codigo: "15514000" },
    { ciudad: "PAEZ", departamento: "CAUCA", codigo: "19517000" },
    { ciudad: "PAICOL", departamento: "HUILA", codigo: "41518000" },
    { ciudad: "PAILITAS", departamento: "CESAR", codigo: "20517000" },
    { ciudad: "PAIME", departamento: "CUNDINAMARCA", codigo: "25518000" },
    { ciudad: "PAIPA", departamento: "BOYACA", codigo: "15516000" },
    { ciudad: "PAJARITO", departamento: "BOYACA", codigo: "15518000" },
    { ciudad: "PALERMO", departamento: "HUILA", codigo: "41524000" },
    { ciudad: "PALESTINA", departamento: "CALDAS", codigo: "17524000" },
    { ciudad: "PALESTINA", departamento: "HUILA", codigo: "41530000" },
    { ciudad: "PALMAR DE VARELA", departamento: "ATLANTICO", codigo: "08520000" },
    { ciudad: "PALMAR", departamento: "SANTANDER", codigo: "68522000" },
    { ciudad: "PALMAS DEL SOCORRO", departamento: "SANTANDER", codigo: "68524000" },
    { ciudad: "PALMIRA", departamento: "VALLE DEL CAUCA", codigo: "76520000" },
    { ciudad: "PALMITO", departamento: "SUCRE", codigo: "70523000" },
    { ciudad: "PALOCABILDO", departamento: "TOLIMA", codigo: "73520000" },
    { ciudad: "PAMPLONA", departamento: "NORTE DE SANTANDER", codigo: "54518000" },
    { ciudad: "PAMPLONITA", departamento: "NORTE DE SANTANDER", codigo: "54520000" },
    { ciudad: "PANA PANA", departamento: "GUAINIA", codigo: "94887000" },
    { ciudad: "PANDI", departamento: "CUNDINAMARCA", codigo: "25524000" },
    { ciudad: "PANQUEBA", departamento: "BOYACA", codigo: "15522000" },
    { ciudad: "PAPUNAUA", departamento: "MITU", codigo: "97777000" },
    { ciudad: "PARAMO", departamento: "SANTANDER", codigo: "68533000" },
    { ciudad: "PARATEBUENO", departamento: "CUNDINAMARCA", codigo: "25530000" },
    { ciudad: "PASCA", departamento: "CUNDINAMARCA", codigo: "25535000" },
    { ciudad: "PASTO", departamento: "NARIÑO", codigo: "52001000" },
    { ciudad: "PATIA", departamento: "CAUCA", codigo: "19532000" },
    { ciudad: "PAUNA", departamento: "BOYACA", codigo: "15531000" },
    { ciudad: "PAYA", departamento: "BOYACA", codigo: "15533000" },
    { ciudad: "PAYANDE", departamento: "TOLIMA", codigo: "73678004" },
    { ciudad: "PAZ DE ARIPORO", departamento: "CASANARE", codigo: "85250000" },
    { ciudad: "PAZ DE RIO", departamento: "BOYACA", codigo: "15537000" },
    { ciudad: "PEDRAZA", departamento: "MAGDALENA", codigo: "47541000" },
    { ciudad: "PELAYA", departamento: "CESAR", codigo: "20550000" },
    { ciudad: "PEÑOL", departamento: "ANTIOQUIA", codigo: "05541000" },
    { ciudad: "PENSILVANIA", departamento: "CALDAS", codigo: "17541000" },
    { ciudad: "PEQUE", departamento: "ANTIOQUIA", codigo: "05543000" },
    { ciudad: "PEREIRA", departamento: "RISARALDA", codigo: "66001000" },
    { ciudad: "PESCA", departamento: "BOYACA", codigo: "15542000" },
    { ciudad: "PIAMONTE", departamento: "CAUCA", codigo: "19533000" },
    { ciudad: "PIEDECUESTA", departamento: "SANTANDER", codigo: "68547000" },
    { ciudad: "PIEDRAS", departamento: "TOLIMA", codigo: "73547000" },
    { ciudad: "PIENDAMO", departamento: "CAUCA", codigo: "19548000" },
    { ciudad: "PIJAO", departamento: "QUINDIO", codigo: "63548000" },
    { ciudad: "PIJINO DEL CARMEN", departamento: "MAGDALENA", codigo: "47545000" },
    { ciudad: "PIÑALITO", departamento: "VISTAHERMOSA", codigo: "50711002" },
    { ciudad: "PINCHOTE", departamento: "SANTANDER", codigo: "68549000" },
    { ciudad: "PINILLOS", departamento: "BOLIVAR", codigo: "13549000" },
    { ciudad: "PIOJO", departamento: "ATLANTICO", codigo: "08549000" },
    { ciudad: "PISBA", departamento: "BOYACA", codigo: "15550000" },
    { ciudad: "PITAL", departamento: "HUILA", codigo: "41548000" },
    { ciudad: "PITALITO", departamento: "HUILA", codigo: "41551000" },
    { ciudad: "PIVIJAY", departamento: "MAGDALENA", codigo: "47551000" },
    { ciudad: "PLANADAS", departamento: "TOLIMA", codigo: "73555000" },
    { ciudad: "PLANETA RICA", departamento: "CORDOBA", codigo: "23555000" },
    { ciudad: "PLATO", departamento: "MAGDALENA", codigo: "47555000" },
    { ciudad: "PLAYARRICA", departamento: "SAN ANTONIO", codigo: "73675002" },
    { ciudad: "POLICARPA", departamento: "NARIÑO", codigo: "52540000" },
    { ciudad: "POLONUEVO", departamento: "ATLANTICO", codigo: "08558000" },
    { ciudad: "PONEDERA", departamento: "ATLANTICO", codigo: "08560000" },
    { ciudad: "POPAYAN", departamento: "CAUCA", codigo: "19001000" },
    { ciudad: "PORE", departamento: "CASANARE", codigo: "85263000" },
    { ciudad: "POTOSI", departamento: "NARIÑO", codigo: "52560000" },
    { ciudad: "PRADERA", departamento: "VALLE DEL CAUCA", codigo: "76563000" },
    { ciudad: "PRADO", departamento: "TOLIMA", codigo: "73563000" },
    { ciudad: "PROVIDENCIA", departamento: "ARCHIPIELAGO DE SAN ANDRES , PROVIDENCIA Y SANTA CATALINA", codigo: "88564000" },
    { ciudad: "PROVIDENCIA", departamento: "NARIÑO", codigo: "52565000" },
    { ciudad: "PUEBLO BELLO", departamento: "CESAR", codigo: "20570000" },
    { ciudad: "PUEBLO NUEVO", departamento: "CORDOBA", codigo: "23570000" },
    { ciudad: "PUEBLO RICO", departamento: "RISARALDA", codigo: "66572000" },
    { ciudad: "PUEBLORRICO", departamento: "ANTIOQUIA", codigo: "05576000" },
    { ciudad: "PUEBLOVIEJO", departamento: "MAGDALENA", codigo: "47570000" },
    { ciudad: "PUENTE DE PIEDRA", departamento: "MADRID", codigo: "25430004" },
    { ciudad: "PUENTE NACIONAL", departamento: "SANTANDER", codigo: "68572000" },
    { ciudad: "PUERRES", departamento: "NARIÑO", codigo: "52573000" },
    { ciudad: "PUERTO ALEGRIA", departamento: "LETICIA", codigo: "91530000" },
    { ciudad: "PUERTO ARICA", departamento: "LETICIA", codigo: "91536000" },
    { ciudad: "PUERTO ASIS", departamento: "PUTUMAYO", codigo: "86568000" },
    { ciudad: "PUERTO BERRIO", departamento: "ANTIOQUIA", codigo: "05579000" },
    { ciudad: "PUERTO CAICEDO", departamento: "PUTUMAYO", codigo: "86569000" },
    { ciudad: "PUERTO CARRENO", departamento: "VICHADA", codigo: "99001000" },
    { ciudad: "PUERTO COLOMBIA", departamento: "ATLANTICO", codigo: "08573000" },
    { ciudad: "PUERTO COLOMBIA", departamento: "INIRIDA", codigo: "94884000" },
    { ciudad: "PUERTO CONCORDIA", departamento: "META", codigo: "50450000" },
    { ciudad: "PUERTO ESCONDIDO", departamento: "CORDOBA", codigo: "23574000" },
    { ciudad: "PUERTO GAITAN", departamento: "META", codigo: "50568000" },
    { ciudad: "PUERTO GIRALDO", departamento: "PONEDERA", codigo: "08560003" },
    { ciudad: "PUERTO GUZMAN", departamento: "PUTUMAYO", codigo: "86571000" },
    { ciudad: "PUERTO LIBERTADOR", departamento: "CORDOBA", codigo: "23580000" },
    { ciudad: "PUERTO LLERAS", departamento: "META", codigo: "50577000" },
    { ciudad: "PUERTO LOPEZ", departamento: "META", codigo: "50573000" },
    { ciudad: "PUERTO NARE", departamento: "ANTIOQUIA", codigo: "05585000" },
    { ciudad: "PUERTO NARINO", departamento: "AMAZONAS", codigo: "91540000" },
    { ciudad: "PUERTO PARRA", departamento: "SANTANDER", codigo: "68573000" },
    { ciudad: "PUERTO RICO", departamento: "CAQUETA", codigo: "18592000" },
    { ciudad: "PUERTO RICO", departamento: "META", codigo: "50590000" },
    { ciudad: "PUERTO RONDON", departamento: "ARAUCA", codigo: "81591000" },
    { ciudad: "PUERTO SALGAR", departamento: "CUNDINAMARCA", codigo: "25572000" },
    { ciudad: "PUERTO SANTANDER", departamento: "LETICIA", codigo: "91669000" },
    { ciudad: "PUERTO SANTANDER", departamento: "NORTE DE SANTANDER", codigo: "54553000" },
    { ciudad: "PUERTO TEJADA", departamento: "CAUCA", codigo: "19573000" },
    { ciudad: "PUERTO TRIUNFO", departamento: "ANTIOQUIA", codigo: "05591000" },
    { ciudad: "PUERTO WILCHES", departamento: "SANTANDER", codigo: "68575000" },
    { ciudad: "PUERTO", departamento: "BOYACA", codigo: "15572000" },
    { ciudad: "PULI", departamento: "CUNDINAMARCA", codigo: "25580000" },
    { ciudad: "PUPIALES", departamento: "NARIÑO", codigo: "52585000" },
    { ciudad: "PURACE", departamento: "CAUCA", codigo: "19585000" },
    { ciudad: "PURIFICACION", departamento: "TOLIMA", codigo: "73585000" },
    { ciudad: "PURISIMA", departamento: "CORDOBA", codigo: "23586000" },
    { ciudad: "QUEBRADANEGRA", departamento: "CUNDINAMARCA", codigo: "25592000" },
    { ciudad: "QUETAME", departamento: "CUNDINAMARCA", codigo: "25594000" },
    { ciudad: "QUIBDO", departamento: "CHOCO", codigo: "27001000" },
    { ciudad: "QUIMBAYA", departamento: "QUINDIO", codigo: "63594000" },
    { ciudad: "QUINCHIA", departamento: "RISARALDA", codigo: "66594000" },
    { ciudad: "QUIPAMA", departamento: "BOYACA", codigo: "15580000" },
    { ciudad: "QUIPILE", departamento: "CUNDINAMARCA", codigo: "25596000" },
    { ciudad: "RAGONVALIA", departamento: "NORTE DE SANTANDER", codigo: "54599000" },
    { ciudad: "RAMIRIQUI", departamento: "BOYACA", codigo: "15599000" },
    { ciudad: "RAQUIRA", departamento: "BOYACA", codigo: "15600000" },
    { ciudad: "RECETOR", departamento: "CASANARE", codigo: "85279000" },
    { ciudad: "REGIDOR", departamento: "BOLIVAR", codigo: "13580000" },
    { ciudad: "REMEDIOS", departamento: "ANTIOQUIA", codigo: "05604000" },
    { ciudad: "REMOLINO", departamento: "MAGDALENA", codigo: "47605000" },
    { ciudad: "REMOLINO", departamento: "TAMINANGO", codigo: "52786019" },
    { ciudad: "REPELON", departamento: "ATLANTICO", codigo: "08606000" },
    { ciudad: "RESTREPO", departamento: "META", codigo: "50606000" },
    { ciudad: "RESTREPO", departamento: "VALLE DEL CAUCA", codigo: "76606000" },
    { ciudad: "RETIRO", departamento: "ANTIOQUIA", codigo: "05607000" },
    { ciudad: "RICAURTE", departamento: "CUNDINAMARCA", codigo: "25612000" },
    { ciudad: "RICAURTE", departamento: "NARIÑO", codigo: "52612000" },
    { ciudad: "RIO DE ORO", departamento: "CESAR", codigo: "20614000" },
    { ciudad: "RIO IRO", departamento: "CHOCO", codigo: "27580000" },
    { ciudad: "RIO QUITO", departamento: "CHOCO", codigo: "27600000" },
    { ciudad: "RIO VIEJO", departamento: "BOLIVAR", codigo: "13600000" },
    { ciudad: "RIOBLANCO", departamento: "TOLIMA", codigo: "73616000" },
    { ciudad: "RIOFRIO", departamento: "VALLE DEL CAUCA", codigo: "76616000" },
    { ciudad: "RIOHACHA", departamento: "LA GUAJIRA", codigo: "44001000" },
    { ciudad: "RIONEGRO", departamento: "ANTIOQUIA", codigo: "05615000" },
    { ciudad: "RIONEGRO", departamento: "SANTANDER", codigo: "68615000" },
    { ciudad: "RIOSUCIO", departamento: "CALDAS", codigo: "17614000" },
    { ciudad: "RIOSUCIO", departamento: "CHOCO", codigo: "27615000" },
    { ciudad: "RISARALDA", departamento: "CALDAS", codigo: "17616000" },
    { ciudad: "RIVERA", departamento: "HUILA", codigo: "41615000" },
    { ciudad: "ROBERTO PAYAN", departamento: "NARIÑO", codigo: "52621000" },
    { ciudad: "ROLDANILLO", departamento: "VALLE DEL CAUCA", codigo: "76622000" },
    { ciudad: "RONCESVALLES", departamento: "TOLIMA", codigo: "73622000" },
    { ciudad: "RONDON", departamento: "BOYACA", codigo: "15621000" },
    { ciudad: "ROSAS", departamento: "CAUCA", codigo: "19622000" },
    { ciudad: "ROVIRA", departamento: "TOLIMA", codigo: "73624000" },
    { ciudad: "SABANA DE TORRES", departamento: "SANTANDER", codigo: "68655000" },
    { ciudad: "SABANAGRANDE", departamento: "ATLANTICO", codigo: "08634000" },
    { ciudad: "SABANALARGA", departamento: "ANTIOQUIA", codigo: "05628000" },
    { ciudad: "SABANALARGA", departamento: "ATLANTICO", codigo: "08638000" },
    { ciudad: "SABANALARGA", departamento: "CASANARE", codigo: "85300000" },
    { ciudad: "SABANAS DE SAN ANGEL", departamento: "MAGDALENA", codigo: "47660000" },
    { ciudad: "SABANETA", departamento: "ANTIOQUIA", codigo: "05631000" },
    { ciudad: "SABOYA", departamento: "BOYACA", codigo: "15632000" },
    { ciudad: "SACAMA", departamento: "CASANARE", codigo: "85315000" },
    { ciudad: "SACHICA", departamento: "BOYACA", codigo: "15638000" },
    { ciudad: "SAHAGUN", departamento: "CORDOBA", codigo: "23660000" },
    { ciudad: "SALADOBLANCO", departamento: "HUILA", codigo: "41660000" },
    { ciudad: "SALAMINA", departamento: "CALDAS", codigo: "17653000" },
    { ciudad: "SALAMINA", departamento: "MAGDALENA", codigo: "47675000" },
    { ciudad: "SALAZAR", departamento: "NORTE DE SANTANDER", codigo: "54660000" },
    { ciudad: "SALDANA", departamento: "TOLIMA", codigo: "73671000" },
    { ciudad: "SALENTO", departamento: "QUINDIO", codigo: "63690000" },
    { ciudad: "SALGAR", departamento: "ANTIOQUIA", codigo: "05642000" },
    { ciudad: "SALONICA", departamento: "RIOFRIO", codigo: "76616009" },
    { ciudad: "SAMACA", departamento: "BOYACA", codigo: "15646000" },
    { ciudad: "SAMANA", departamento: "CALDAS", codigo: "17662000" },
    { ciudad: "SAMANIEGO", departamento: "NARIÑO", codigo: "52678000" },
    { ciudad: "SAMPUES", departamento: "SUCRE", codigo: "70670000" },
    { ciudad: "SAN AGUSTIN", departamento: "HUILA", codigo: "41668000" },
    { ciudad: "SAN ALBERTO", departamento: "CESAR", codigo: "20710000" },
    { ciudad: "SAN ANDRES DE CUERQUIA", departamento: "ANTIOQUIA", codigo: "05647000" },
    { ciudad: "SAN ANDRES DE SOTAVENTO", departamento: "CORDOBA", codigo: "23670000" },
    { ciudad: "SAN ANDRES", departamento: "ARCHIPIELAGO DE SAN ANDRES , PROVIDENCIA Y SANTA CATALINA", codigo: "88001000" },
    { ciudad: "SAN ANDRES", departamento: "SANTANDER", codigo: "68669000" },
    { ciudad: "SAN ANTERO", departamento: "CORDOBA", codigo: "23672000" },
    { ciudad: "SAN ANTONIO DE PALMITOS", departamento: "SINCELEJO", codigo: "70001014" },
    { ciudad: "SAN ANTONIO DEL TEQUENDAMA", departamento: "CUNDINAMARCA", codigo: "25645000" },
    { ciudad: "SAN ANTONIO", departamento: "TOLIMA", codigo: "73675000" },
    { ciudad: "SAN BENITO ABAD", departamento: "SUCRE", codigo: "70678000" },
    { ciudad: "SAN BENITO", departamento: "SANTANDER", codigo: "68673000" },
    { ciudad: "SAN BERNARDO DEL VIENTO", departamento: "CORDOBA", codigo: "23675000" },
    { ciudad: "SAN BERNARDO", departamento: "CUNDINAMARCA", codigo: "25649000" },
    { ciudad: "SAN BERNARDO", departamento: "NARIÑO", codigo: "52685000" },
    { ciudad: "SAN CALIXTO", departamento: "NORTE DE SANTANDER", codigo: "54670000" },
    { ciudad: "SAN CARLOS DE GUAROA", departamento: "META", codigo: "50680000" },
    { ciudad: "SAN CARLOS", departamento: "ANTIOQUIA", codigo: "05649000" },
    { ciudad: "SAN CARLOS", departamento: "CORDOBA", codigo: "23678000" },
    { ciudad: "SAN CAYETANO", departamento: "CUNDINAMARCA", codigo: "25653000" },
    { ciudad: "SAN CAYETANO", departamento: "NORTE DE SANTANDER", codigo: "54673000" },
    { ciudad: "SAN CRISTOBAL", departamento: "BOLIVAR", codigo: "13620000" },
    { ciudad: "SAN DIEGO", departamento: "CESAR", codigo: "20750000" },
    { ciudad: "SAN EDUARDO", departamento: "BOYACA", codigo: "15660000" },
    { ciudad: "SAN ESTANISLAO", departamento: "BOLIVAR", codigo: "13647000" },
    { ciudad: "SAN FELIPE", departamento: "INIRIDA", codigo: "94001002" },
    { ciudad: "SAN FELIX", departamento: "SALAMINA", codigo: "17653007" },
    { ciudad: "SAN FERNANDO", departamento: "BOLIVAR", codigo: "13650000" },
    { ciudad: "SAN FRANCISCO", departamento: "ANTIOQUIA", codigo: "05652000" },
    { ciudad: "SAN FRANCISCO", departamento: "CUNDINAMARCA", codigo: "25658000" },
    { ciudad: "SAN FRANCISCO", departamento: "PUTUMAYO", codigo: "86755000" },
    { ciudad: "SAN GIL", departamento: "SANTANDER", codigo: "68679000" },
    { ciudad: "SAN JACINTO DEL CAUCA", departamento: "BOLIVAR", codigo: "13655000" },
    { ciudad: "SAN JACINTO", departamento: "BOLIVAR", codigo: "13654000" },
    { ciudad: "SAN JERONIMO", departamento: "ANTIOQUIA", codigo: "05656000" },
    { ciudad: "SAN JOAQUIN", departamento: "SANTANDER", codigo: "68682000" },
    { ciudad: "SAN JOSE DE LA MONTANA", departamento: "ANTIOQUIA", codigo: "05658000" },
    { ciudad: "SAN JOSE DE MIRANDA", departamento: "SANTANDER", codigo: "68684000" },
    { ciudad: "SAN JOSE DE OCUNE ", departamento: "CUMARIBO", codigo: "99773024" },
    { ciudad: "SAN JOSE DE PARE", departamento: "BOYACA", codigo: "15664000" },
    { ciudad: "SAN JOSE DE URE", departamento: "CORDOBA", codigo: "23682000" },
    { ciudad: "SAN JOSE DEL FRAGUA", departamento: "CAQUETA", codigo: "18610000" },
    { ciudad: "SAN JOSE DEL GUAVIARE", departamento: "GUAVIARE", codigo: "95001000" },
    { ciudad: "SAN JOSE DEL PALMAR", departamento: "CHOCO", codigo: "27660000" },
    { ciudad: "SAN JOSE", departamento: "CALDAS", codigo: "17665000" },
    { ciudad: "SAN JUAN DE ARAMA", departamento: "META", codigo: "50683000" },
    { ciudad: "SAN JUAN DE BETULIA", departamento: "SUCRE", codigo: "70702000" },
    { ciudad: "SAN JUAN DE RIO SECO", departamento: "CUNDINAMARCA", codigo: "25662000" },
    { ciudad: "SAN JUAN DE URABA", departamento: "ANTIOQUIA", codigo: "05659000" },
    { ciudad: "SAN JUAN DEL CESAR", departamento: "LA GUAJIRA", codigo: "44650000" },
    { ciudad: "SAN JUAN NEPOMUCENO", departamento: "BOLIVAR", codigo: "13657000" },
    { ciudad: "SAN JUAN", departamento: "IPIALES", codigo: "52356003" },
    { ciudad: "SAN JUANITO", departamento: "META", codigo: "50686000" },
    { ciudad: "SAN LORENZO", departamento: "NARIÑO", codigo: "52687000" },
    { ciudad: "SAN LUIS DE GACENO", departamento: "BOYACA", codigo: "15667000" },
    { ciudad: "SAN LUIS DE PALENQUE", departamento: "CASANARE", codigo: "85325000" },
    { ciudad: "SAN LUIS", departamento: "ANTIOQUIA", codigo: "05660000" },
    { ciudad: "SAN LUIS", departamento: "TOLIMA", codigo: "73678000" },
    { ciudad: "SAN MARCOS", departamento: "SUCRE", codigo: "70708000" },
    { ciudad: "SAN MARTIN DE LOBA", departamento: "BOLIVAR", codigo: "13667000" },
    { ciudad: "SAN MARTIN", departamento: "CESAR", codigo: "20770000" },
    { ciudad: "SAN MARTIN", departamento: "META", codigo: "50689000" },
    { ciudad: "SAN MATEO", departamento: "BOYACA", codigo: "15673000" },
    { ciudad: "SAN MIGUEL DE SEMA", departamento: "BOYACA", codigo: "15676000" },
    { ciudad: "SAN MIGUEL", departamento: "PUTUMAYO", codigo: "86757000" },
    { ciudad: "SAN MIGUEL", departamento: "SANTANDER", codigo: "68686000" },
    { ciudad: "SAN ONOFRE", departamento: "SUCRE", codigo: "70713000" },
    { ciudad: "SAN PABLO DE BORBUR", departamento: "BOYACA", codigo: "15681000" },
    { ciudad: "SAN PABLO", departamento: "BOLIVAR", codigo: "13670000" },
    { ciudad: "SAN PABLO", departamento: "NARIÑO", codigo: "52693000" },
    { ciudad: "SAN PEDRO DE CARTAGO", departamento: "NARIÑO", codigo: "52694000" },
    { ciudad: "SAN PEDRO DE URABA", departamento: "ANTIOQUIA", codigo: "05665000" },
    { ciudad: "SAN PEDRO", departamento: "ANTIOQUIA", codigo: "05664000" },
    { ciudad: "SAN PEDRO", departamento: "SUCRE", codigo: "70717000" },
    { ciudad: "SAN PEDRO", departamento: "VALLE DEL CAUCA", codigo: "76670000" },
    { ciudad: "SAN PELAYO", departamento: "CORDOBA", codigo: "23686000" },
    { ciudad: "SAN RAFAEL DE RIONEGRO", departamento: "RIONEGRO", codigo: "68615031" },
    { ciudad: "SAN RAFAEL", departamento: "ANTIOQUIA", codigo: "05667000" },
    { ciudad: "SAN ROQUE", departamento: "ANTIOQUIA", codigo: "05670000" },
    { ciudad: "SAN SEBASTIAN DE BUENAVISTA", departamento: "MAGDALENA", codigo: "47692000" },
    { ciudad: "SAN SEBASTIAN", departamento: "CAUCA", codigo: "19693000" },
    { ciudad: "SAN VICENTE DE CHUCURI", departamento: "SANTANDER", codigo: "68689000" },
    { ciudad: "SAN VICENTE DEL CAGUAN", departamento: "CAQUETA", codigo: "18753000" },
    { ciudad: "SAN VICENTE", departamento: "ANTIOQUIA", codigo: "05674000" },
    { ciudad: "SAN ZENON", departamento: "MAGDALENA", codigo: "47703000" },
    { ciudad: "SANDONA", departamento: "NARIÑO", codigo: "52683000" },
    { ciudad: "SANTA ANA", departamento: "MAGDALENA", codigo: "47707000" },
    { ciudad: "SANTA BARBARA DE PINTO", departamento: "MAGDALENA", codigo: "47720000" },
    { ciudad: "SANTA BARBARA", departamento: "ANTIOQUIA", codigo: "05679000" },
    { ciudad: "SANTA BARBARA", departamento: "NARIÑO", codigo: "52696000" },
    { ciudad: "SANTA BARBARA", departamento: "SANTANDER", codigo: "68705000" },
    { ciudad: "SANTA CATALINA", departamento: "BOLIVAR", codigo: "13673000" },
    { ciudad: "SANTA HELENA DEL OPON", departamento: "SANTANDER", codigo: "68720000" },
    { ciudad: "SANTA ISABEL", departamento: "TOLIMA", codigo: "73686000" },
    { ciudad: "SANTA LUCIA", departamento: "ATLANTICO", codigo: "08675000" },
    { ciudad: "SANTA MARIA", departamento: "BOYACA", codigo: "15690000" },
    { ciudad: "SANTA MARIA", departamento: "HUILA", codigo: "41676000" },
    { ciudad: "SANTA MARTA", departamento: "MAGDALENA", codigo: "47001000" },
    { ciudad: "SANTA RITA", departamento: "MITU", codigo: "99773015" },
    { ciudad: "SANTA ROSA DE CABAL", departamento: "RISARALDA", codigo: "66682000" },
    { ciudad: "SANTA ROSA DE LIMA", departamento: "BOLIVAR", codigo: "13683000" },
    { ciudad: "SANTA ROSA DE OSOS", departamento: "ANTIOQUIA", codigo: "05686000" },
    { ciudad: "SANTA ROSA DE VITERBO", departamento: "BOYACA", codigo: "15693000" },
    { ciudad: "SANTA ROSA DEL SUR", departamento: "BOLIVAR", codigo: "13688000" },
    { ciudad: "SANTA ROSA", departamento: "CAUCA", codigo: "19701000" },
    { ciudad: "SANTA ROSALIA", departamento: "VICHADA", codigo: "99624000" },
    { ciudad: "SANTA SOFIA", departamento: "BOYACA", codigo: "15696000" },
    { ciudad: "SANTACRUZ", departamento: "NARIÑO", codigo: "52699000" },
    { ciudad: "SANTAFE DE ANTIOQUIA", departamento: "ANTIOQUIA", codigo: "05042000" },
    { ciudad: "SANTANA", departamento: "BOYACA", codigo: "15686000" },
    { ciudad: "SANTANDER DE QUILICHAO", departamento: "CAUCA", codigo: "19698000" },
    { ciudad: "SANTANDERCITO", departamento: "SAN ANTONIO DEL TEQUENDAMA", codigo: "25645001" },
    { ciudad: "SANTIAGO DE TOLU", departamento: "SUCRE", codigo: "70820000" },
    { ciudad: "SANTIAGO PEREZ", departamento: "TOLIMA", codigo: "73067007" },
    { ciudad: "SANTIAGO", departamento: "NORTE DE SANTANDER", codigo: "54680000" },
    { ciudad: "SANTIAGO", departamento: "PUTUMAYO", codigo: "86760000" },
    { ciudad: "SANTO DOMINGO", departamento: "ANTIOQUIA", codigo: "05690000" },
    { ciudad: "SANTO TOMAS", departamento: "ATLANTICO", codigo: "08685000" },
    { ciudad: "SANTUARIO", departamento: "RISARALDA", codigo: "66687000" },
    { ciudad: "SAPUYES", departamento: "NARIÑO", codigo: "52720000" },
    { ciudad: "SARAVENA", departamento: "ARAUCA", codigo: "81736000" },
    { ciudad: "SARDINATA", departamento: "NORTE DE SANTANDER", codigo: "54720000" },
    { ciudad: "SASAIMA", departamento: "CUNDINAMARCA", codigo: "25718000" },
    { ciudad: "SATIVANORTE", departamento: "BOYACA", codigo: "15720000" },
    { ciudad: "SATIVASUR", departamento: "BOYACA", codigo: "15723000" },
    { ciudad: "SEGOVIA", departamento: "ANTIOQUIA", codigo: "05736000" },
    { ciudad: "SESQUILE", departamento: "CUNDINAMARCA", codigo: "25736000" },
    { ciudad: "SEVILLA", departamento: "VALLE DEL CAUCA", codigo: "76736000" },
    { ciudad: "SEVILLA", departamento: "ZONA BANANERA", codigo: "47980008" },
    { ciudad: "SIACHOQUE", departamento: "BOYACA", codigo: "15740000" },
    { ciudad: "SIBATE", departamento: "CUNDINAMARCA", codigo: "25740000" },
    { ciudad: "SIBUNDOY", departamento: "PUTUMAYO", codigo: "86749000" },
    { ciudad: "SILOS", departamento: "NORTE DE SANTANDER", codigo: "54743000" },
    { ciudad: "SILVANIA", departamento: "CUNDINAMARCA", codigo: "25743000" },
    { ciudad: "SILVIA", departamento: "CAUCA", codigo: "19743000" },
    { ciudad: "SIMACOTA", departamento: "SANTANDER", codigo: "68745000" },
    { ciudad: "SIMIJACA", departamento: "CUNDINAMARCA", codigo: "25745000" },
    { ciudad: "SIMITI", departamento: "BOLIVAR", codigo: "13744000" },
    { ciudad: "SINCE", departamento: "SUCRE", codigo: "70742000" },
    { ciudad: "SINCELEJO", departamento: "SUCRE", codigo: "70001000" },
    { ciudad: "SIPI", departamento: "CHOCO", codigo: "27745000" },
    { ciudad: "SITIONUEVO", departamento: "MAGDALENA", codigo: "47745000" },
    { ciudad: "SOACHA", departamento: "CUNDINAMARCA", codigo: "25754000" },
    { ciudad: "SOATA", departamento: "BOYACA", codigo: "15753000" },
    { ciudad: "SOCHA", departamento: "BOYACA", codigo: "15757000" },
    { ciudad: "SOCORRO", departamento: "SANTANDER", codigo: "68755000" },
    { ciudad: "SOCOTA", departamento: "BOYACA", codigo: "15755000" },
    { ciudad: "SOGAMOSO", departamento: "BOYACA", codigo: "15759000" },
    { ciudad: "SOLANO", departamento: "CAQUETA", codigo: "18756000" },
    { ciudad: "SOLEDAD", departamento: "ATLANTICO", codigo: "08758000" },
    { ciudad: "SOLITA", departamento: "CAQUETA", codigo: "18785000" },
    { ciudad: "SOMONDOCO", departamento: "BOYACA", codigo: "15761000" },
    { ciudad: "SONSON", departamento: "ANTIOQUIA", codigo: "05756000" },
    { ciudad: "SOPETRAN", departamento: "ANTIOQUIA", codigo: "05761000" },
    { ciudad: "SOPLAVIENTO", departamento: "BOLIVAR", codigo: "13760000" },
    { ciudad: "SOPO", departamento: "CUNDINAMARCA", codigo: "25758000" },
    { ciudad: "SORA", departamento: "BOYACA", codigo: "15762000" },
    { ciudad: "SORACA", departamento: "BOYACA", codigo: "15764000" },
    { ciudad: "SOTAQUIRA", departamento: "BOYACA", codigo: "15763000" },
    { ciudad: "SOTARA", departamento: "CAUCA", codigo: "19760000" },
    { ciudad: "SUAITA", departamento: "SANTANDER", codigo: "68770000" },
    { ciudad: "SUAN", departamento: "ATLANTICO", codigo: "08770000" },
    { ciudad: "SUAREZ", departamento: "CAUCA", codigo: "19780000" },
    { ciudad: "SUAREZ", departamento: "TOLIMA", codigo: "73770000" },
    { ciudad: "SUAZA", departamento: "HUILA", codigo: "41770000" },
    { ciudad: "SUBACHOQUE", departamento: "CUNDINAMARCA", codigo: "25769000" },
    { ciudad: "SUBIA", departamento: "SILVANIA", codigo: "25743005" },
    { ciudad: "SUCRE", departamento: "CAUCA", codigo: "19785000" },
    { ciudad: "SUCRE", departamento: "SANTANDER", codigo: "68773000" },
    { ciudad: "SUCRE", departamento: "SUCRE", codigo: "70771000" },
    { ciudad: "SUESCA", departamento: "CUNDINAMARCA", codigo: "25772000" },
    { ciudad: "SUPATA", departamento: "CUNDINAMARCA", codigo: "25777000" },
    { ciudad: "SUPIA", departamento: "CALDAS", codigo: "17777000" },
    { ciudad: "SURATA", departamento: "SANTANDER", codigo: "68780000" },
    { ciudad: "SUSA", departamento: "CUNDINAMARCA", codigo: "25779000" },
    { ciudad: "SUSACON", departamento: "BOYACA", codigo: "15774000" },
    { ciudad: "SUTAMARCHAN", departamento: "BOYACA", codigo: "15776000" },
    { ciudad: "SUTATAUSA", departamento: "CUNDINAMARCA", codigo: "25781000" },
    { ciudad: "SUTATENZA", departamento: "BOYACA", codigo: "15778000" },
    { ciudad: "TABIO", departamento: "CUNDINAMARCA", codigo: "25785000" },
    { ciudad: "TADO", departamento: "CHOCO", codigo: "27787000" },
    { ciudad: "TALAIGUA NUEVO", departamento: "BOLIVAR", codigo: "13780000" },
    { ciudad: "TAMALAMEQUE", departamento: "CESAR", codigo: "20787000" },
    { ciudad: "TAMARA", departamento: "CASANARE", codigo: "85400000" },
    { ciudad: "TAME", departamento: "ARAUCA", codigo: "81794000" },
    { ciudad: "TAMESIS", departamento: "ANTIOQUIA", codigo: "05789000" },
    { ciudad: "TAMINANGO", departamento: "NARIÑO", codigo: "52786000" },
    { ciudad: "TANGUA", departamento: "NARIÑO", codigo: "52788000" },
    { ciudad: "TARAIRA", departamento: "VAUPES", codigo: "97666000" },
    { ciudad: "TARAPACA", departamento: "LETICIA", codigo: "91798000" },
    { ciudad: "TARAZA", departamento: "ANTIOQUIA", codigo: "05790000" },
    { ciudad: "TARQUI", departamento: "HUILA", codigo: "41791000" },
    { ciudad: "TARSO", departamento: "ANTIOQUIA", codigo: "05792000" },
    { ciudad: "TASCO", departamento: "BOYACA", codigo: "15790000" },
    { ciudad: "TAURAMENA", departamento: "CASANARE", codigo: "85410000" },
    { ciudad: "TAUSA", departamento: "CUNDINAMARCA", codigo: "25793000" },
    { ciudad: "TELLO", departamento: "HUILA", codigo: "41799000" },
    { ciudad: "TENA", departamento: "CUNDINAMARCA", codigo: "25797000" },
    { ciudad: "TENERIFE", departamento: "MAGDALENA", codigo: "47798000" },
    { ciudad: "TENJO", departamento: "CUNDINAMARCA", codigo: "25799000" },
    { ciudad: "TENZA", departamento: "BOYACA", codigo: "15798000" },
    { ciudad: "TEORAMA", departamento: "NORTE DE SANTANDER", codigo: "54800000" },
    { ciudad: "TERUEL", departamento: "HUILA", codigo: "41801000" },
    { ciudad: "TESALIA", departamento: "HUILA", codigo: "41797000" },
    { ciudad: "TIBACUY", departamento: "CUNDINAMARCA", codigo: "25805000" },
    { ciudad: "TIBANA", departamento: "BOYACA", codigo: "15804000" },
    { ciudad: "TIBASOSA", departamento: "BOYACA", codigo: "15806000" },
    { ciudad: "TIBIRITA", departamento: "CUNDINAMARCA", codigo: "25807000" },
    { ciudad: "TIBU", departamento: "NORTE DE SANTANDER", codigo: "54810000" },
    { ciudad: "TIERRA BOMBA", departamento: "CARTAGENA", codigo: "13001013" },
    { ciudad: "TIERRALTA", departamento: "CORDOBA", codigo: "23807000" },
    { ciudad: "TIMANA", departamento: "HUILA", codigo: "41807000" },
    { ciudad: "TIMBIO", departamento: "CAUCA", codigo: "19807000" },
    { ciudad: "TIMBIQUI", departamento: "CAUCA", codigo: "19809000" },
    { ciudad: "TINJACA", departamento: "BOYACA", codigo: "15808000" },
    { ciudad: "TIPACOQUE", departamento: "BOYACA", codigo: "15810000" },
    { ciudad: "TIQUISIO NUEVO", departamento: "TIQUISIO", codigo: "13810011" },
    { ciudad: "TIQUISIO", departamento: "BOLIVAR", codigo: "13810000" },
    { ciudad: "TITIRIBI", departamento: "ANTIOQUIA", codigo: "05809000" },
    { ciudad: "TOBIA", departamento: "NIMAIMA", codigo: "25489001" },
    { ciudad: "TOCA", departamento: "BOYACA", codigo: "15814000" },
    { ciudad: "TOCAIMA", departamento: "CUNDINAMARCA", codigo: "25815000" },
    { ciudad: "TOCANCIPA", departamento: "CUNDINAMARCA", codigo: "25817000" },
    { ciudad: "TOGUI", departamento: "BOYACA", codigo: "15816000" },
    { ciudad: "TOLEDO", departamento: "ANTIOQUIA", codigo: "05819000" },
    { ciudad: "TOLEDO", departamento: "NORTE DE SANTANDER", codigo: "54820000" },
    { ciudad: "TOLEMAIDA", departamento: "TOLIMA", codigo: "73449016" },
    { ciudad: "TOLU VIEJO", departamento: "SUCRE", codigo: "70823000" },
    { ciudad: "TONA", departamento: "SANTANDER", codigo: "68820000" },
    { ciudad: "TOPAGA", departamento: "BOYACA", codigo: "15820000" },
    { ciudad: "TOPAIPI", departamento: "CUNDINAMARCA", codigo: "25823000" },
    { ciudad: "TORIBIO", departamento: "CAUCA", codigo: "19821000" },
    { ciudad: "TORO", departamento: "VALLE DEL CAUCA", codigo: "76823000" },
    { ciudad: "TOTA", departamento: "BOYACA", codigo: "15822000" },
    { ciudad: "TOTORO", departamento: "CAUCA", codigo: "19824000" },
    { ciudad: "TRES ESQUINAS", departamento: "IBAGUE", codigo: "73001059" },
    { ciudad: "TRINIDAD", departamento: "CASANARE", codigo: "85430000" },
    { ciudad: "TRUJILLO", departamento: "VALLE DEL CAUCA", codigo: "76828000" },
    { ciudad: "TUBARA", departamento: "ATLANTICO", codigo: "08832000" },
    { ciudad: "TUCHIN", departamento: "CORDOBA", codigo: "23815000" },
    { ciudad: "TULUA", departamento: "VALLE DEL CAUCA", codigo: "76834000" },
    { ciudad: "TUMACO", departamento: "NARIÑO", codigo: "52835000" },
    { ciudad: "TUNJA", departamento: "BOYACA", codigo: "15001000" },
    { ciudad: "TUNUNGUA", departamento: "BOYACA", codigo: "15832000" },
    { ciudad: "TUQUERRES", departamento: "NARIÑO", codigo: "52838000" },
    { ciudad: "TURBACO", departamento: "BOLIVAR", codigo: "13836000" },
    { ciudad: "TURBANA", departamento: "BOLIVAR", codigo: "13838000" },
    { ciudad: "TURBO", departamento: "ANTIOQUIA", codigo: "05837000" },
    { ciudad: "TURMEQUE", departamento: "BOYACA", codigo: "15835000" },
    { ciudad: "TUTA", departamento: "BOYACA", codigo: "15837000" },
    { ciudad: "TUTAZA", departamento: "BOYACA", codigo: "15839000" },
    { ciudad: "UBALA", departamento: "CUNDINAMARCA", codigo: "25839000" },
    { ciudad: "UBAQUE", departamento: "CUNDINAMARCA", codigo: "25841000" },
    { ciudad: "UBATE", departamento: "CUNDINAMARCA", codigo: "25843000" },
    { ciudad: "ULLOA", departamento: "VALLE DEL CAUCA", codigo: "76845000" },
    { ciudad: "UMBITA", departamento: "BOYACA", codigo: "15842000" },
    { ciudad: "UNE", departamento: "CUNDINAMARCA", codigo: "25845000" },
    { ciudad: "UNGUIA", departamento: "CHOCO", codigo: "27800000" },
    { ciudad: "UNION PANAMERICANA", departamento: "CHOCO", codigo: "27810000" },
    { ciudad: "URAMITA", departamento: "ANTIOQUIA", codigo: "05842000" },
    { ciudad: "URIBE", departamento: "META", codigo: "50370000" },
    { ciudad: "URIBIA", departamento: "LA GUAJIRA", codigo: "44847000" },
    { ciudad: "URRAO", departamento: "ANTIOQUIA", codigo: "05847000" },
    { ciudad: "URUMITA", departamento: "LA GUAJIRA", codigo: "44855000" },
    { ciudad: "USIACURI", departamento: "ATLANTICO", codigo: "08849000" },
    { ciudad: "UTICA", departamento: "CUNDINAMARCA", codigo: "25851000" },
    { ciudad: "VADO REAL", departamento: "SUAITA", codigo: "68770003" },
    { ciudad: "VALDIVIA", departamento: "ANTIOQUIA", codigo: "05854000" },
    { ciudad: "VALENCIA", departamento: "CORDOBA", codigo: "23855000" },
    { ciudad: "VALLE DE SAN JOSE", departamento: "SANTANDER", codigo: "68855000" },
    { ciudad: "VALLE DE SAN JUAN", departamento: "TOLIMA", codigo: "73854000" },
    { ciudad: "VALLE DEL GUAMUEZ", departamento: "PUTUMAYO", codigo: "86865000" },
    { ciudad: "VALLEDUPAR", departamento: "CESAR", codigo: "20001000" },
    { ciudad: "VALPARAISO", departamento: "ANTIOQUIA", codigo: "05856000" },
    { ciudad: "VALPARAISO", departamento: "CAQUETA", codigo: "18860000" },
    { ciudad: "VEGACHI", departamento: "ANTIOQUIA", codigo: "05858000" },
    { ciudad: "VEGALARGA", departamento: "NEIVA", codigo: "41001012" },
    { ciudad: "VELEZ", departamento: "SANTANDER", codigo: "68861000" },
    { ciudad: "VELU", departamento: "NATAGAIMA", codigo: "73483002" },
    { ciudad: "VENADILLO", departamento: "TOLIMA", codigo: "73861000" },
    { ciudad: "VENECIA", departamento: "ANTIOQUIA", codigo: "05861000" },
    { ciudad: "VENECIA", departamento: "CUNDINAMARCA", codigo: "25506000" },
    { ciudad: "VENTAQUEMADA", departamento: "BOYACA", codigo: "15861000" },
    { ciudad: "VERACRUZ", departamento: "SIMITI", codigo: "13744006" },
    { ciudad: "VERGARA", departamento: "CUNDINAMARCA", codigo: "25862000" },
    { ciudad: "VERSALLES", departamento: "VALLE DEL CAUCA", codigo: "76863000" },
    { ciudad: "VETAS", departamento: "SANTANDER", codigo: "68867000" },
    { ciudad: "VIANI", departamento: "CUNDINAMARCA", codigo: "25867000" },
    { ciudad: "VICTORIA", departamento: "CALDAS", codigo: "17867000" },
    { ciudad: "VIGIA DEL FUERTE", departamento: "ANTIOQUIA", codigo: "05873000" },
    { ciudad: "VIJES", departamento: "VALLE DEL CAUCA", codigo: "76869000" },
    { ciudad: "VILLA CARO", departamento: "NORTE DE SANTANDER", codigo: "54871000" },
    { ciudad: "VILLA DE LEYVA", departamento: "BOYACA", codigo: "15407000" },
    { ciudad: "VILLA DEL ROSARIO", departamento: "NORTE DE SANTANDER", codigo: "54874000" },
    { ciudad: "VILLA RICA", departamento: "CAUCA", codigo: "19845000" },
    { ciudad: "VILLAGARZON", departamento: "PUTUMAYO", codigo: "86885000" },
    { ciudad: "VILLAGOMEZ", departamento: "CUNDINAMARCA", codigo: "25871000" },
    { ciudad: "VILLAHERMOSA", departamento: "TOLIMA", codigo: "73870000" },
    { ciudad: "VILLAMARIA", departamento: "CALDAS", codigo: "17873000" },
    { ciudad: "VILLANUEVA", departamento: "BOLIVAR", codigo: "13873000" },
    { ciudad: "VILLANUEVA", departamento: "CASANARE", codigo: "85440000" },
    { ciudad: "VILLANUEVA", departamento: "LA GUAJIRA", codigo: "44874000" },
    { ciudad: "VILLANUEVA", departamento: "SANTANDER", codigo: "68872000" },
    { ciudad: "VILLAPINZON", departamento: "CUNDINAMARCA", codigo: "25873000" },
    { ciudad: "VILLARRICA", departamento: "TOLIMA", codigo: "73873000" },
    { ciudad: "VILLAVICENCIO", departamento: "META", codigo: "50001000" },
    { ciudad: "VILLAVIEJA", departamento: "HUILA", codigo: "41872000" },
    { ciudad: "VILLETA", departamento: "CUNDINAMARCA", codigo: "25875000" },
    { ciudad: "VIOTA", departamento: "CUNDINAMARCA", codigo: "25878000" },
    { ciudad: "VIRACACHA", departamento: "BOYACA", codigo: "15879000" },
    { ciudad: "VISTAHERMOSA", departamento: "META", codigo: "50711000" },
    { ciudad: "VITERBO", departamento: "CALDAS", codigo: "17877000" },
    { ciudad: "YACOPI", departamento: "CUNDINAMARCA", codigo: "25885000" },
    { ciudad: "YACUANQUER", departamento: "NARIÑO", codigo: "52885000" },
    { ciudad: "YAGUARA", departamento: "HUILA", codigo: "41885000" },
    { ciudad: "YALI", departamento: "ANTIOQUIA", codigo: "05885000" },
    { ciudad: "YARUMAL", departamento: "ANTIOQUIA", codigo: "05887000" },
    { ciudad: "YAVARATE", departamento: "MITU", codigo: "97889000" },
    { ciudad: "YOLOMBO", departamento: "ANTIOQUIA", codigo: "05890000" },
    { ciudad: "YONDO", departamento: "ANTIOQUIA", codigo: "05893000" },
    { ciudad: "YOPAL", departamento: "CASANARE", codigo: "85001000" },
    { ciudad: "YOTOCO", departamento: "VALLE DEL CAUCA", codigo: "76890000" },
    { ciudad: "YUMBO", departamento: "VALLE DEL CAUCA", codigo: "76892000" },
    { ciudad: "ZAMBRANO", departamento: "BOLIVAR", codigo: "13894000" },
    { ciudad: "ZAPATOCA", departamento: "SANTANDER", codigo: "68895000" },
    { ciudad: "ZAPAYAN", departamento: "MAGDALENA", codigo: "47960000" },
    { ciudad: "ZARAGOZA", departamento: "ANTIOQUIA", codigo: "05895000" },
    { ciudad: "ZARZAL", departamento: "VALLE DEL CAUCA", codigo: "76895000" },
    { ciudad: "ZETAQUIRA", departamento: "BOYACA", codigo: "15897000" },
    { ciudad: "ZIPACON", departamento: "CUNDINAMARCA", codigo: "25898000" },
    { ciudad: "ZIPAQUIRA", departamento: "CUNDINAMARCA", codigo: "25899000" },
    { ciudad: "ZONA BANANERA", departamento: "MAGDALENA", codigo: "47980000" },
    { ciudad: "ZULUAGA", departamento: "GARZON", codigo: "41298004" },
    { ciudad: "LA LOMA", departamento: "CESAR", codigo: "20250002" },
    { ciudad: "EL CARMELO", departamento: "CAN/VALL", codigo: "76130004" },
]

export default ciudadesInter;