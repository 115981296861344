import * as React from "react";
import { DatePickerOnly, InputOnly } from "shared/Components";
import { Alert, Confirm, alertaRemove } from "shared/Alert";
import EditarMovimiento from "./EditarMovimiento";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ControlCaja = (params) => {
    const { navigate, media_movil, funciones, handleRefresh, showLoading, setShowLoading, cleanFilters, setCleanFilters, setShowBtnClean } = params;
    const [currentQuery, setCurrentQuery] = React.useState({ task: "todos", criterio: {} });
    const [loadingLocal, setLoadingLocal] = React.useState(false);
    const [movementList, setMovementList] = React.useState([]);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);


    const FilterDate = () => {
        const [fecha, setFecha] = React.useState(currentQuery.criterio.fecha === undefined ? new Date() : new Date(currentQuery.criterio.fecha + "T05:00:00"));
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const searchRef = React.useRef();


        const valida = (e) => {
            e.preventDefault();

            document.body.style.overflow = "auto";
            setCurrentQuery(v => ({ task: "buscar", criterio: { ...v.criterio, fecha: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${format(fecha.getDate())}` } }));
            setShowLoading(true);
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 132) });
                }

                if (currentQuery.criterio.fecha !== undefined) {
                    let _fecha = new Date(currentQuery.criterio.fecha + "T05:00:00");
                    setFecha(_fecha);
                }
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={media_movil ? { ...pos, marginRight: "2rem" } : pos}>
                    {showFilter ?
                        <div className="filter_content">
                            <h4 className="filter_title">Filtrar fecha</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />

                            <DatePickerOnly
                                ref={searchRef}
                                placeholder="Fecha"
                                value={fecha}
                                onChange={(date) => { alertaRemove(searchRef.current); setFecha(date); }} />

                            <button type="button" className="btn btn_primary" onClick={valida}>Buscar</button>
                        </div>
                        :
                        <>
                            {currentQuery.criterio.fecha === undefined ?
                                <Iconos.Filter className="filter_icon" onClick={handleClic} />
                                :
                                <Iconos.FilterCheck className="filter_icon _check" onClick={handleClic} />
                            }
                            <Iconos.Renew className={showLoading ? "filter_icon _rotate" : "filter_icon"} onClick={handleRefresh} />
                        </>
                    }
                </div>
            </>
        )
    }

    const FilterCode = () => {
        const [criterio, setCriterio] = React.useState(currentQuery.criterio.codigo || "");
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const searchRef = React.useRef();


        const valida = (e) => {
            e.preventDefault();

            document.body.style.overflow = "auto";
            setCurrentQuery(v => ({ task: "buscar", criterio: { ...v.criterio, codigo: criterio.trim() } }));
            setShowLoading(true);
        }

        const handleKey = (e) => {
            e.preventDefault();
            e.stopPropagation();


            if (e.key === "Enter") {
                valida(e);
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 164) });
                }

                if (currentQuery.criterio.codigo !== undefined) {
                    setCriterio(currentQuery.criterio.codigo);
                }

                setTimeout(() => {
                    if (searchRef.current) {
                        searchRef.current.focus();
                    }
                }, 600);
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={media_movil ? { ...pos, marginRight: "2rem" } : pos}>
                    {showFilter ?
                        <div className="filter_content">
                            <h4 className="filter_title">Filtrar Código</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />

                            <InputOnly
                                ref={searchRef}
                                placeholder="Todos"
                                onKeyUp={handleKey}
                                value={criterio}
                                onChange={v => { alertaRemove(searchRef.current); setCriterio(v) }} />

                            <button type="button" className="btn btn_primary" onClick={valida}>Buscar</button>
                        </div>
                        :
                        <>
                            {currentQuery.criterio.codigo === undefined ?
                                <Iconos.Filter className="filter_icon" onClick={handleClic} />
                                :
                                <Iconos.FilterCheck className="filter_icon _check" onClick={handleClic} />
                            }
                        </>
                    }
                </div>
            </>
        )
    }

    const FilterCount = () => {
        const [criterio, setCriterio] = React.useState(currentQuery.criterio.cuenta || "");
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const searchRef = React.useRef();


        const valida = (e) => {
            e.preventDefault();

            document.body.style.overflow = "auto";
            setCurrentQuery(v => ({ task: "buscar", criterio: { ...v.criterio, cuenta: criterio.trim() } }));
            setShowLoading(true);
        }

        const handleKey = (e) => {
            e.preventDefault();
            e.stopPropagation();


            if (e.key === "Enter") {
                valida(e);
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 164) });
                }

                if (currentQuery.criterio.cuenta !== undefined) {
                    setCriterio(currentQuery.criterio.cuenta);
                }

                setTimeout(() => {
                    if (searchRef.current) {
                        searchRef.current.focus();
                    }
                }, 600);
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={media_movil ? { ...pos, marginRight: "2rem" } : pos}>
                    {showFilter ?
                        <div className="filter_content">
                            <h4 className="filter_title">Filtrar cuenta</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />

                            <InputOnly
                                ref={searchRef}
                                placeholder="Todos"
                                onKeyUp={handleKey}
                                value={criterio}
                                onChange={v => { alertaRemove(searchRef.current); setCriterio(v) }} />

                            <button type="button" className="btn btn_primary" onClick={valida}>Buscar</button>
                        </div>
                        :
                        <>
                            {currentQuery.criterio.cuenta === undefined ?
                                <Iconos.Filter className="filter_icon" onClick={handleClic} />
                                :
                                <Iconos.FilterCheck className="filter_icon _check" onClick={handleClic} />
                            }
                        </>
                    }
                </div>
            </>
        )
    }

    const Filter = () => {
        const [criterio, setCriterio] = React.useState(currentQuery.criterio.concepto ?? "");
        const [showFilter, setShowFilter] = React.useState(false);
        const [pos, setPos] = React.useState({ top: 0, right: 0 });
        const searchRef = React.useRef();


        const valida = (e) => {
            e.preventDefault();

            document.body.style.overflow = "auto";
            setCurrentQuery(v => ({ task: "buscar", criterio: { ...v.criterio, concepto: criterio.trim() } }));
            setShowLoading(true);
        }

        const handleKey = (e) => {
            e.preventDefault();
            e.stopPropagation();


            if (e.key === "Enter") {
                valida(e);
            }
        }

        const handleClic = (e) => {
            e.preventDefault();
            if (showFilter) {
                document.body.style.overflow = "auto";
                setPos({ top: 0, right: 0 });
            } else {
                document.body.style.overflow = "hidden";
                let _icono = e.target.getBoundingClientRect();

                if (media_movil) {
                    setPos({ top: (_icono.top - 8), right: 24 });
                } else {
                    setPos({ top: (_icono.top - 8), left: (_icono.left - 164) });
                }

                if (currentQuery.criterio.concepto !== undefined) {
                    setCriterio(currentQuery.criterio.concepto);
                }

                setTimeout(() => {
                    if (searchRef.current) {
                        searchRef.current.focus();
                    }
                }, 600);
            }
            setShowFilter(v => !v);
        }

        return (
            <>
                {showFilter && <div className="back_float" onClick={handleClic}></div>}
                <div className={showFilter ? "filter_floating _open" : "filter_floating"} style={media_movil ? { ...pos, marginRight: "2rem" } : pos}>
                    {showFilter ?
                        <div className="filter_content">
                            <h4 className="filter_title">Filtrar concepto</h4>
                            <Iconos.FilterClose className="filter_icon" onClick={handleClic} />

                            <InputOnly
                                ref={searchRef}
                                placeholder="Todos"
                                onKeyUp={handleKey}
                                value={criterio}
                                onChange={v => { alertaRemove(searchRef.current); setCriterio(v) }} />

                            <button type="button" className="btn btn_primary" onClick={valida}>Buscar</button>
                        </div>
                        :
                        <>
                            {currentQuery.criterio.concepto === undefined ?
                                <Iconos.Filter className="filter_icon" onClick={handleClic} />
                                :
                                <Iconos.FilterCheck className="filter_icon _check" onClick={handleClic} />
                            }
                        </>
                    }
                </div>
            </>
        )
    }

    const goDelete = (_info) => {
        Confirm(`¿Seguro de borrar esta "${_info.tipo == 0 ? "salida de caja" : "entrada de caja"}" por ${funciones.current.formatPrice(_info.monto).format}?`, async (response) => {
            if (response) {
                setLoadingLocal(true);
                let _data = await api.fetchJson({
                    url: "delete-cash-movement",
                    data: {
                        info: JSON.stringify({
                            id: _info.id,
                            tipo_name: _info.tipo == 0 ? "Salida de caja" : "Entrada de caja",
                        })
                    }
                });

                if (_data.response == 1) {
                    Alert(_data.msg, "ok", () => handleRefresh());
                } else if (_data.response == -2) {
                    Alert(_data.msg, "warning", () => navigate("/sign-in"));
                } else {
                    Alert(_data.msg, "warning");
                }

                setLoadingLocal(false);
            }
        });
    }

    const abrirEdicion = (e, _data) => {
        e.preventDefault();

        setModalView(<EditarMovimiento
            data={_data}
            parent={{ top: e.clientY, left: e.clientX, width: 300 }}
            reload={() => handleRefresh()}
            close={() => setModalView(null)} />);
    }

    const getMovementsLazy = async () => {
        setLoadingLocal(true);

        let _data = await api.fetchJson({
            url: "get-cash-movement",
            data: {
                task: currentQuery.task,
                criterio: currentQuery.criterio,
                limit: 50,
                offset: movementList.length
            }
        });

        if (_data.response == 1) {
            let _new = movementList.map(item => {
                return item;
            });

            _data.data.map(item => {
                _new.push(item);
            });

            setMovementList(_new);
            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadingLocal(false);
    }

    const getMovements = async (_task = "todos", _criterio) => {
        setShowLoading(true);
        setCurrentQuery({ task: _task, criterio: _criterio });
        let _data = await api.fetchJson({
            url: "get-cash-movement",
            data: {
                task: _task,
                criterio: _criterio,
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setMovementList(_data.data);
            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (Object.keys(currentQuery.criterio).length > 0) {
            setShowBtnClean(true);
        } else {
            setShowBtnClean(false);
        }
    }, [currentQuery]);

    React.useEffect(() => {
        if (cleanFilters) {
            setCurrentQuery({ task: "todos", criterio: {} });
            setShowLoading(true);
            setCleanFilters(false);
        }
    }, [cleanFilters]);


    React.useEffect(() => {
        if (showLoading) {
            getMovements(currentQuery.task, currentQuery.criterio);
        }
    }, [showLoading]);

    return (
        <>
            <div className="caja">

                <div className="caja_contiene_tabla _auto_height">
                    <div className="caja_tabla">
                        <table className="table">
                            <tbody>
                                {media_movil ?
                                    <tr className="table_title">
                                        <th>Movimientos<Filter /> <Iconos.Renew className={showLoading ? "caja_icon_renew _in_tr _rotate" : "caja_icon_renew _in_tr"} onClick={handleRefresh} /></th>
                                    </tr>
                                    :
                                    <tr className="table_title">
                                        <th></th>
                                        <th>Tipo</th>
                                        <th>Monto</th>
                                        <th>Código<FilterCode /></th>
                                        <th>Cuenta<FilterCount /></th>
                                        <th>Concepto<Filter /></th>
                                        <th>Responsable</th>
                                        <th>Fecha <FilterDate /></th>
                                    </tr>
                                }
                                {movementList.map((item, i) => {
                                    let _info = JSON.parse(item.info);
                                    let fecha = new Date(_info.fecha + "T05:00:00");
                                    return <tr key={item.id} className={(i % 2) === 1 ? "table_row bkg_inter_line" : "table_row"}>
                                        {media_movil ?
                                            <>
                                                <td>
                                                    <pre><span>{_info.gestor.nombre}</span> <br />
                                                        {item.tipo == 1 ? "Entrada" : "Salida"} <br />
                                                        <span>{funciones.current.formatPrice(item.monto).format} </span> <br />
                                                        {_info.bank} - {_info.bank_name} <br />
                                                        <span>{_info.nota ?? "..."}</span><br />
                                                    </pre>
                                                    <i>{format(fecha.getDate())}/{meses[fecha.getMonth()]}/{fecha.getFullYear()}</i>
                                                </td>
                                                <td className="td_menu"></td>
                                            </>
                                            :
                                            <>
                                                <td>{i + 1}</td>
                                                <td>{item.tipo == 1 ? "Entrada" : "Salida"}</td>
                                                <td style={{ textAlign: "right" }}>{funciones.current.formatPrice(item.monto).format}</td>
                                                <td>{_info.codigo == 0 ? "" : format(_info.codigo)}</td>
                                                <td>{_info.bank ? _info.bank + " - " + _info.bank_name : ""}</td>
                                                <td>{_info.nota ?? "..."}</td>
                                                <td>{_info.gestor.nombre}</td>
                                                <td className="colum_icons _text_left">{format(fecha.getDate())}/{meses[fecha.getMonth()]}/{fecha.getFullYear()}
                                                    {funciones.current.validaPermisos("contable_caja_movement_edita") && <Iconos.Edit onClick={e => abrirEdicion(e, item)} />}

                                                    {funciones.current.validaPermisos("contable_caja_movement_borra") && <>
                                                        {loadingLocal ?
                                                            <Iconos.Renew className="_rotate" onClick={handleRefresh} />
                                                            :
                                                            <Iconos.Delete onClick={() => goDelete(item)} />
                                                        }
                                                    </>}
                                                </td>
                                                <td className="td_menu"></td>
                                            </>
                                        }
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                {loadingLocal ?
                    <button className="btn btn_lazy_load"><Iconos.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn btn_lazy_load" onClick={() => getMovementsLazy()}>Cargar más</button>
                            :
                            <>
                                {(currentQuery.task === "buscar") ?
                                    <h4 className="msg_lazy_load">No hay más movimientos con
                                        {currentQuery.criterio.codigo ? " código '" + currentQuery.criterio.codigo + "'," : ""}
                                        {currentQuery.criterio.cuenta ? " cuenta '" + currentQuery.criterio.cuenta + "'," : ""}
                                        {currentQuery.criterio.concepto ? " concepto '" + currentQuery.criterio.concepto + "'," : ""}
                                        {currentQuery.criterio.fecha ? " fecha '" + currentQuery.criterio.fecha + "'" : ""}
                                    </h4>
                                    :
                                    <h4 className="msg_lazy_load">¡No hay más movimientos!</h4>
                                }
                            </>
                        }
                    </>
                }
            </div>
            {modalView}
        </>
    )
}

export default ControlCaja;