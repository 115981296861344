import * as React from "react";
import { useBarcode } from "next-barcode";
import { useQRCode } from "next-qrcode";

/* eslint react-hooks/exhaustive-deps: 0 */

export const QrCode = ({ content }) => {
    const { SVG } = useQRCode();

    return (
        <div className="_qr_code">
            <SVG
                text={content}
                options={{
                    margin: 0.1,
                    width: 200,
                }}
            />
        </div>
    );
}

export const BarCode = ({ code }) => {
    const { inputRef } = useBarcode({
        value: code,
        options: {
            height: 50,
            format: 'CODE128',
            fontSize: 17,
            background: '#ffffff00',
        }
    });

    return (
        <div className="_bar_code">
            <svg ref={inputRef} />
        </div>
    );
}
