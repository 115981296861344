import * as React from "react";
import { InputOnly } from "shared/Components";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
// const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const FlujoCaja = (params) => {
    const { navigate, media_movil, funciones, handleRefresh, showLoading, setShowLoading } = params;
    const [movementList, setMovementList] = React.useState([]);
    const [allMovements, setAllMovements] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");

    const [days, setDays] = React.useState([]);

    const fecha = new Date();
    const _last_day = new Date(fecha.getFullYear(), (fecha.getMonth() + 1), 0).getDate();


    const Rows = () => {
        let _categoria = "";
        let _row_categogory;

        return movementList.map((item, index) => {
            let _rows = [];
            let _rows_empty = [];
            let _total = 0;
            let _pagos = Object.entries(JSON.parse(item.pagos || "{}"));

            for (let i = 1; i <= _last_day; i++) {
                let _valor_hoy = 0;

                // eslint-disable-next-line
                _pagos.forEach(([key, value]) => {
                    let _montos = value.split(':');
                    let _fecha = new Date(_montos[0] + "T05:00:00");

                    if (_montos[1] !== undefined && _fecha.getDate() == i) {
                        _valor_hoy += Number(_montos[1]);
                        _total += Number(_montos[1]);
                    }
                });

                _rows.push(<td key={i} className="_dias">{_valor_hoy == 0 ? "" : funciones.current.formatPrice(_valor_hoy.toFixed(2).replace('.', ',')).format}</td>);
                _rows_empty.push(<td key={"emp_" + i} className="_dias">&nbsp;</td>);
            }


            if (item.caegoria && item.caegoria == _categoria) {
                _row_categogory = null;
            } else {
                _categoria = item.caegoria;

                if (_categoria) {
                    _row_categogory = <tr className={"table_row bkg_category_line"}>
                        <td className="colum_title"><h4>{_categoria}</h4></td>
                        <td className="_dias">&nbsp;</td>
                        {_rows_empty}
                        <td className="td_menu"></td>
                    </tr>;
                } else {
                    _row_categogory = null;
                }
            }

            return <React.Fragment key={item.id}>
                {_row_categogory}
                <tr className={(index % 2) === 1 ? "table_row bkg_inter_line" : "table_row"}>
                    <td className="colum_title">{format(item.id)} - {item.nombre}</td>
                    <td className="_dias">{_total == 0 ? "" : funciones.current.formatPrice(_total.toFixed(2).replace('.', ',')).format}</td>
                    {_rows}
                    <td className="td_menu"></td>
                </tr>
            </React.Fragment>;
        })
    }

    const getMovements = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-concepts",
            data: {
                task: "flujo",
                fecha_ini: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-01`,
                fecha_fin: `${fecha.getFullYear()}-${format(fecha.getMonth() + 1)}-${_last_day}`
            }
        });

        if (_data.response == 1) {
            setMovementList(_data.data);
            setAllMovements(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        let coincidencias = allMovements.filter(objeto => {
            let _nombre = format(objeto.id) + " - " + objeto.nombre;
            return _nombre.includes(criterio);
        });
        setMovementList(coincidencias);
    }, [criterio]);


    React.useEffect(() => {
        if (showLoading) {
            getMovements();
        }
    }, [showLoading]);

    React.useEffect(() => {
        let _days = [];

        for (let i = 1; i <= _last_day; i++) {
            _days.push(<th key={i} className="_dias">{format(i)}</th>);
        }

        setDays(_days);
    }, []);

    return (
        <div className="caja">
            <div className="caja_to_rigth">
                <Iconos.Renew className={showLoading ? "caja_icon_renew _to_table _rotate" : "caja_icon_renew _to_table"} onClick={handleRefresh} />
            </div>

            <div className="caja_contiene_tabla">
                <div className="caja_tabla">
                    <table className="table">
                        <thead>
                            <tr className="table_title">
                                <th className="colum_title">{!media_movil && "Códigos"} <InputOnly
                                    placeholder="Buscar código"
                                    value={criterio}
                                    onChange={v => setCriterio(v)}
                                    list={allMovements.map(item => {
                                        let _nombres = format(item.id) + " - " + item.nombre;
                                        return _nombres.trim();
                                    })} /></th>
                                <th className="_dias">Total <sub>{meses[fecha.getMonth()]}</sub></th>
                                {days}
                            </tr>
                        </thead>
                        <tbody>
                            <Rows />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default FlujoCaja;