import * as React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ItemLinkIcon = ({ title, Icono, route, state = {} }) => (
    <NavLink
        to={route}
        state={state}
        className={({ isActive }) => isActive ? "item_link-icon item_link-active" : "item_link-icon"}>
        {Icono ? <Icono className="item_group-icon" /> : ''}
        <span>{title}</span>
    </NavLink>
);

ItemLinkIcon.propTypes = {
    title: PropTypes.string.isRequired,
};

export default ItemLinkIcon;
