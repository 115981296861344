import * as React from "react";
import { InputWithIcon, SelectWithIcon } from "shared/Components";
import { RequirePermissions } from "shared/RequirePermissions";
import { Alert, alerta, alertaRemove, alertDown } from "shared/Alert";
import { uploadFile } from "services/Uploader";
import { Funciones } from "services/funciones";
import withRouter from "services/withRouter";
import { DropFile } from "shared/FileUpload";
import Loading from "shared/Loading";
import resize from "shared/Resize";
import { useMedia } from "hooks";
import * as Iconos from "images";
import log from "services/log";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const VoucherNew = (router) => {
    const { navigate } = router;
    const { setTitle } = useMedia();
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const funciones = React.useRef(new Funciones(navigate));
    const [cuentas, setCuentas] = React.useState([]);
    const [pedido, setPedido] = React.useState("");
    const [vouchers, setVouchers] = React.useState([
        {
            bank: "",
            bank_name: "",
            voucher: "",
            pedido: "",
            signature: "",
            file: []
        }
    ]);

    const vouchersRef = React.useRef([]);
    const pedidoRef = React.useRef(null);
    const btnRef = React.useRef(null);


    const checkingVoucher = async (_json) => {
        setShowLoading(true);

        let _data_checking = await api.fetchJson({
            url: "checking-voucher",
            data: {
                task: "checking",
                info: {
                    pedido: "no aplica",
                    signature: "no aplica",
                    files: _json.files
                }
            }
        });

        if (_data_checking.response == 1) {
            log.d(_data_checking.msg);
            return true;
        } else if (_data_checking.response == -2) {
            Alert(_data_checking.msg, "warning", () => navigate("/sign-in"));
            setShowLoading(false);
            return false;
        } else {
            Alert(_data_checking.msg, "warning");
            setShowLoading(false);
            return false;
        }
    }

    const saveVoucher = async (_json) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "set-voucher",
            data: {
                task: "create",
                info: {
                    pedido,
                    signature: _json.sign,
                    files: _json.files
                }
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                navigate(-1);
            });
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = async () => {
        let allVouchers = [];
        let allFiles = [];
        let _next = true;

        alertaRemove(pedidoRef.current);
        alertaRemove(btnRef.current);

        if (pedido.length < 1) {
            alerta(pedidoRef.current, "¡Debes ingresar el número del pedido!");
            pedidoRef.current.focus();
            window.scrollTo({ top: 80, behavior: 'smooth' });
            _next = false;
            return false;
        }

        if (vouchers.length < 1) {
            alertDown(btnRef.current, "¡Debes agregar al menos un comprobante!");
            btnRef.current.focus();
            _next = false;
            return false;
        }

        for (let index = 0; index < vouchers.length; index++) {
            let all = vouchers[index];

            if (all.voucher == null || all.voucher.length < 1) {
                alerta(vouchersRef.current["voucher" + index], "¡Debes ingresar el número del comprobante!");
                vouchersRef.current["voucher" + index].focus();
                _next = false;
                return false;
            }

            if (all.bank == null || all.bank.length < 1) {
                alerta(vouchersRef.current["bank" + index], "¡Debes seleccionar una cuenta bancaria!");
                vouchersRef.current["bank" + index].focus();
                _next = false;
                return false;
            }

            if (all.file == null || all.file.length < 1) {
                alerta(vouchersRef.current["file" + index], "¡Debes agregar una foto del comprobante!");
                vouchersRef.current["file" + index].focus();
                _next = false;
                return false;
            }

            let file = all.file[0];

            if (file.type.toString().split('/')[0] === "image") {
                log.i(file.type.toString().split('/')[1]);
                allFiles.push({
                    file: file,
                    type: file.type.toString().split('/')[1],
                    img_name: "voucher_" + all.voucher + file.nameinserver.toString().split('voucher')[1],
                    bank: all.bank,
                    voucher: all.voucher,
                    bank_name: all.bank_name,
                });
            } else {
                alerta(vouchersRef.current["file" + index], "¡Sólo se admiten imágenes!");
                vouchersRef.current["file" + index].focus();
                _next = false;
                return false;
            }
        }

        if (_next) {
            setShowLoading(true);

            let forChecking = allFiles.map(item => ({
                voucher: item.voucher,
                bank: item.bank,
                bank_name: item.bank_name
            }));

            let _response_checking = await checkingVoucher({ files: forChecking });

            if (_response_checking) {
                allFiles.forEach(async _obj => {
                    const data = new FormData();
                    const file = _obj.file;

                    resize(file, async image => {
                        if (image !== null) {
                            data.append('file', image);
                            data.append('nombre', _obj.img_name.trim());
                            data.append('destino', "vouchers");
                        }

                        await uploadFile(data).then(response => {

                            allVouchers.push({
                                url: response.url,
                                name: response.nameinserver,
                                voucher: _obj.voucher,
                                bank: _obj.bank,
                                bank_name: _obj.bank_name
                            });

                            log.i("archivo subido", file.name);

                            if (allVouchers.length == vouchers.length) {
                                saveVoucher({ files: allVouchers, sign: file.sign });
                            }
                        }).catch(error => {
                            alerta(vouchersRef.current["file" + 0], error);
                            setShowLoading(false);
                        });
                    });
                });
            }
        }
    }

    const getBanksList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-banks",
            data: {
                task: "todos"
            }
        });

        if (_data.response == 1) {
            setCuentas(_data.data);
        } else if (_data.response == -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitle("Crear nuevo comprobante");

        funciones.current.validaSesion("comprobantes_crear").then(response => {
            if (response) {
                getBanksList();
                setPermission(true);
            } else {
                setPermission(false);
            }
            setShowLoading(false);
        });
    }, []);

    return (
        <RequirePermissions permission={permission} loading={showLoading}>

            <div className="head_bar">
                <h2 className="head_bar-title">Crear nuevo comprobante</h2>
            </div>

            <div className="content_primary _pedido_voucher">
                <InputWithIcon
                    ref={pedidoRef}
                    type="text"
                    title="Número de pedido"
                    Icono={Iconos.Pedidos}
                    value={pedido}
                    onChange={v => { alertaRemove(pedidoRef.current); setPedido(v) }} />
            </div>
            <div className="content_primary _in_center">

                {vouchers.map((item, index) => <fieldset key={index} className="voucher">
                    <legend>Comprobante {index + 1}</legend>
                    {vouchers.length > 1 && <Iconos.CloseCircle className="close" onClick={() => setVouchers(a => a.filter((_, i) => i !== index))} />}

                    <InputWithIcon
                        ref={e => vouchersRef.current["voucher" + index] = e}
                        type="text"
                        title="Número de comprobante"
                        value={item.voucher}
                        onChange={v => {
                            alertaRemove(vouchersRef.current["voucher" + index]);
                            setVouchers(all => {
                                let _new = [...all];
                                _new[index].voucher = v;
                                return _new;
                            });
                        }} />

                    <SelectWithIcon
                        ref={e => vouchersRef.current["bank" + index] = e}
                        title="Cuenta Bancaria"
                        Icono={Iconos.Bank}
                        value={item.bank}
                        onChange={v => {
                            alertaRemove(vouchersRef.current["bank" + index]);
                            setVouchers(all => {
                                let _new = [...all];
                                let _bank = cuentas.find(count => parseInt(count.id) === parseInt(v));
                                _new[index].bank_name = _bank.nombre;
                                _new[index].bank = v;
                                return _new;
                            });
                        }} >
                        <option value="">Selecciona Cuenta</option>
                        {cuentas.map((item, i) => {
                            return <option key={i} value={format(item.id)}>{item.nombre}</option>;
                        })}

                    </SelectWithIcon>

                    <div className="form_group">
                        <span className="form_group-label">Foto del comprobante</span>
                        <DropFile
                            ref={e => vouchersRef.current["file" + index] = e}
                            voucher={item.voucher}
                            attach={item.file}
                            onChange={v => {
                                alertaRemove(vouchersRef.current["file" + index]);
                                setVouchers(all => {
                                    let _new = [...all];
                                    _new[index].file = v.length > 0 ? [v[0]] : [];
                                    return _new;
                                });
                            }} />
                    </div>

                </fieldset>)}

            </div>
            <div className="content_primary" style={{ position: "relative" }}>
                <button type="button" className="btn btn_with_icon _left" ref={btnRef}
                    onClick={() => {
                        alertaRemove(btnRef.current);
                        setVouchers(a => [...a, {
                            bank: "",
                            bank_name: "",
                            voucher: "",
                            pedido: "",
                            signature: "",
                            file: []
                        }])
                    }}><Iconos.VoucherNew /> Agregar comprobante</button>
            </div>

            <div className="content_btns _with_margin">
                <button type="button" className="btn btn_primary" onClick={validate}>Enviar</button>
            </div>

            <Loading state={showLoading} />
        </RequirePermissions>
    )
}

export default withRouter(VoucherNew);