import * as React from "react";

/* eslint eqeqeq: 0 */

const Menu = (params) => {
    const { data, interline, children, current } = params;
    const [clases, setClases] = React.useState(interline == 0 ? "table_row no_pointer" : "table_row no_pointer bkg_inter_line");

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [data.id, current]);

    return (
        <tr className={clases} >

            {children}

            <td className="td_menu">
            </td>
        </tr>
    )
}

export default Menu;